import { Box } from "@mui/material";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";
import { Tabtitle } from "components/title/Title";
import React from "react";
import useSWR from "swr";
import axiosusers from "utils/api/axiosusers";
import AddTraHang from "./AddTraHang";

const index = () => {
  Tabtitle("Tạo trả hàng chính");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: productsData } = useSWR(`/products/products-all`, fetchDataSwr);
  const { data: ngansachkhoData } = useSWR(
    `/ngansachkho/ngansachkho-all`,
    fetchDataSwr
  );

  const { data: suppliersData } = useSWR(
    `/suppliers/suppliers-all`,
    fetchDataSwr
  );
  return (
    <Box sx={{ maxWidth: 800, margin: `auto` }}>
      <TypographyAddEdit name="Tạo trả hàng chính" />
      <Box sx={{ marginTop: "1.5rem" }}>
        <AddTraHang
          productsData={productsData === undefined ? [] : productsData}
          ngansachkhoData={ngansachkhoData === undefined ? [] : ngansachkhoData}
          suppliersData={suppliersData === undefined ? [] : suppliersData}
        />
      </Box>
    </Box>
  );
};

export default index;
