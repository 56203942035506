import useLinks from "hooks/useLinks";
import { Box } from "@mui/material";
import React from "react";

const Images = (props) => {
  const linkImage = useLinks();
  return (
    <Box
      sx={{
        width: "40px",
        height: "40px",
        display: "flex",
        overflow: "hidden",
        position: "relative",
        fontSize: "1rem",
        alignItems: "center",
        lineHeight: "1",
        userSelect: "none",
        borderRadius: "50%",
        justifyContent: "center",
      }}
    >
      <img
        src={`${linkImage}${props.nameImage}`}
        crossOrigin="anonymous"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          verticalAlign: "middle",
          borderStyle: "none",
        }}
      />
    </Box>
  );
};

export default Images;
