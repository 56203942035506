import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const UploadMultiple = ({ handPhotoChange }) => {
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [avatar, setAvatar] = React.useState([]);
  const handleRemovePhoto = (photo, index) => {
    const updatedAvatar = [...avatar];
    updatedAvatar.splice(index, 1);
    setAvatar(updatedAvatar);
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const viewAvatar = (e) => {
    const filesArray = Array.from(e.target.files).map((file) =>
      URL.createObjectURL(file)
    );

    setSelectedFiles((prevImages) => prevImages.concat(filesArray));
    Array.from(e.target.files).map(
      (file) => URL.revokeObjectURL(file) // avoid memory leak
    );

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      setAvatar((prevState) => [...prevState, { file }]);
    }
  };
  const renderPhotos = (photos) => {
    return photos.map((photo, index) => (
      <div key={photo} style={{ position: "relative", display: "flex" }}>
        <img
          src={photo}
          alt=""
          style={{ width: "90px", height: "90px", margin: "4px" }}
        />
        <IconButton
          color="primary"
          onClick={() => handleRemovePhoto(photo, index)} // Truyền ID và index vào hàm handleRemovePhoto
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    ));
  };

  React.useEffect(() => {
    handPhotoChange(avatar);
  }, [avatar]);
  return (
    <Box>
      <div className="result" style={{ display: "flex" }}>
        {renderPhotos(selectedFiles)}
      </div>
      <Button
        component="label"
        role={undefined}
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Tải ảnh lên
        <VisuallyHiddenInput
          type="file"
          multiple
          accept="image/*"
          onChange={viewAvatar}
        />
      </Button>
    </Box>
  );
};

export default UploadMultiple;
