import { Avatar, Button } from "@mui/material";
import React from "react";

const UpdateFile = ({ handPhotoChange }) => {
  const [avatar, setAvatar] = React.useState(null);

  const handleFile = (e) => {
    const file = e.target.files[0];
    handPhotoChange(file);
    setAvatar({ preview: URL.createObjectURL(file) });
  };
  return (
    <>
      {avatar && (
        <Avatar
          src={avatar.preview}
          variant="square"
          sx={{
            width: (theme) => theme.spacing(25),
            height: (theme) => theme.spacing(25),
            margin: (theme) => theme.spacing(2, 0),
          }}
        />
      )}
      <input
        accept="image/*"
        style={{
          display: "none",
          color: "#3f50b5",
          margin: (theme) => theme.spacing(2, 0),
        }}
        onChange={handleFile}
        id="file"
        name="file"
        multiple
        type="file"
      />
      <label htmlFor="file">
        <Button component="span" sx={{ marginTop: `1rem` }} color="primary">
          UPLOAD PHOTOS
        </Button>
      </label>
    </>
  );
};

export default UpdateFile;
