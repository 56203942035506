import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import AutocompleteId from "hooks/useAutocomplete";
import BudgetsWallets from "../hooks/useBudgets";
import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";

const AddWarehouse = (props) => {
  const { productsData } = props;
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [inputProduct, setInputProduct] = React.useState([]);
  const [inputFields, setInputFields] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const initialValues = {
    no: "",
    name: "",
    notes: "",
  };
  const validationSchema = Yup.object().shape({
    no: Yup.string().min(0, "It's too short").required("Số thứ tự là bắt buộc"),
    name: Yup.string()
      .min(0, "It's too short")
      .required("Tên thực đơn là bắt buộc"),
  });
  const handleChangeProduct = (value) => {
    setInputProduct(value);
  };

  const onSubmit = async (values) => {
    setDung(true);
    let errorMessage = "";

    if (inputProduct?.id === undefined) {
      errorMessage = `Product is required`;
    }
    if (inputFields.length === 0) {
      errorMessage = "Ngân sách dữ liệu không được trống";
    }
    inputFields.forEach((item) => {
      if (item.name === "" || item.price === "") {
        errorMessage =
          "Ngân sách dữ liệu không được trống. Vui lòng kiểm tra lại";
      }
    });
    if (errorMessage !== "") {
      setDung(false);
      seterrorMessage(errorMessage);
      toast.error(errorMessage, {
        position: "top-right",
      });
      return;
    }
    const data = {
      no: values.no,
      name: values.name,
      productId: inputProduct.id,
      notes: values.notes,
      inputBudgets: inputFields,
    };
    try {
      const response = await axiosusers.post(`/thucdon/create-thucdon`, data);
      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/thucdon");
        }, 500);
      }
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  const handleChangeBudgets = (value) => {
    setInputFields(value);
  };
  const onChangeStatus = (value) => {
    setStatus(value);
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={dung ? null : onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              type="number"
              name="no"
              label="Số thứ tự"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.no && props.touched.no}
              helperText={<ErrorMessage name="no" />}
            />
            <FormControlProduct onChangeStatus={onChangeStatus} />
            <AutocompleteId
              data={productsData.filter((item) => item.status === status)}
              label="Sản phẩm"
              id="Sản phẩm"
              name="Sản phẩm"
              size="small"
              openError={true}
              done={errorMessage && inputProduct?.id === undefined}
              onCheckedChange={handleChangeProduct}
              optionLabel={(label) => label.name}
            />

            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="name"
              label="Tên thực đơn"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.name && props.touched.name}
              helperText={<ErrorMessage name="name" />}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                marginTop: `0.5rem`,
              }}
            >
              Ngân sách:
            </Typography>
            <BudgetsWallets onCheckedChange={handleChangeBudgets} />
            <Field
              sx={{ marginTop: `1.5rem` }}
              as={TextField}
              multiline
              minRows="4"
              name="notes"
              label="Ghi chú"
              variant="outlined"
              size="small"
              fullWidth
            />
            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddWarehouse;
