import { Box, CircularProgress } from "@mui/material";
import NavLinkAdd from "components/NavLinks/NavLinkAdd";
import React from "react";
import Discount from "./Main/Discount";
import axiosusers from "utils/api/axiosusers";

const index = () => {
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);
  const fetchDataAll = async () => {
    try {
      const response = await axiosusers.get("/discount/discount-all");
      const res = response.data;
      setProduct(res);
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, []);
  return (
    <Box>
      {loading && product.length === 0 && (
        <NavLinkAdd href="/manager/discount/add" name="Tạo giảm giá" />
      )}
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {loading && <Discount product={product} />}
    </Box>
  );
};

export default index;
