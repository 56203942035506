import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "../style.module.css";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axiosusers from "utils/api/axiosusers";
const PhotosOrders = (props) => {
  const { images, deletePhotoOrders, deletePhoto } = props;
  // eslint-disable-next-line no-undef
  const URL = process.env.REACT_APP_NOT_SECRET_CODE;
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [rotation, setRotation] = React.useState(0);
  const handleOpen = (index) => {
    setIsOpen(true);
    setCurrentIndex(index);
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
    }, 300);
  };
  const handleClose = () => {
    setIsOpen(false);
    setCurrentIndex(0);
  };
  function rotateImage() {
    setRotation(rotation + 90);
  }
  const handleNext = () => {
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
    }, 200);
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((currentIndex + images.length - 1) % images.length);
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
    }, 200);
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (isOpen) {
        if (event.key === "ArrowLeft" && currentIndex > 0) {
          handlePrev();
        } else if (
          event.key === "ArrowRight" &&
          currentIndex < images.length - 1
        ) {
          handleNext();
        } else if (event.key === "Escape") {
          handleClose();
        } else if (event.key === "Enter") {
          const element = document.documentElement;
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.mozRequestFullScreen) {
            /* Firefox */
            element.mozRequestFullScreen();
          } else if (element.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            element.webkitRequestFullscreen();
          } else if (element.msRequestFullscreen) {
            /* IE/Edge */
            element.msRequestFullscreen();
          }
        } else if (event.ctrlKey && event.key === "q") {
          rotateImage();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    isOpen,
    currentIndex,
    handlePrev,
    handleNext,
    handleClose,
    images.length,
  ]);
  const DeleteImg = async (id) => {
    if (!deletePhoto) {
      return;
    }
    const dataSend = {
      id,
    };
    try {
      const response = await axiosusers.patch(
        `/photos/delete-photos-id`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        deletePhotoOrders(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap", margin: "5px auto" }}>
        {images.map((item, index) => {
          return (
            <div key={index} style={{ position: "relative" }}>
              <div>
                <img
                  crossOrigin="anonymous"
                  src={`${URL}images/${item.image}`}
                  onClick={() => handleOpen(index)}
                  style={{
                    width: "150px",
                    height: "150px",
                    margin: "1px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <IconButton
                color="primary"
                sx={{ position: "absolute", top: 0, left: 0 }}
                onClick={() => DeleteImg(item.id)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        })}
      </div>
      {isOpen && (
        <>
          <div className={styles.lightbox}>
            <div className={styles.lightboxOverlay} onClick={handleClose} />
            <div className={styles.headers}>
              <Tooltip title="Close">
                <div className={styles.closeButton} onClick={handleClose}>
                  <IconButton>
                    <CloseIcon className={styles.icon} />
                  </IconButton>
                </div>
              </Tooltip>
              <Tooltip title="Rotabe (Ctrl+Q)">
                <Box className={styles.refreshButton} onClick={rotateImage}>
                  <IconButton>
                    <RefreshIcon className={styles.icon} />
                  </IconButton>
                </Box>
              </Tooltip>
            </div>

            {currentIndex > 0 && (
              <Tooltip title="Prev image">
                <Box className={styles.prevButton} onClick={handlePrev}>
                  <IconButton>
                    <KeyboardArrowLeftIcon className={styles.icon} />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
            {currentIndex < images.length - 1 && (
              <Tooltip title="Next image">
                <Box className={styles.nextButton} onClick={handleNext}>
                  <IconButton>
                    <KeyboardArrowRightIcon className={styles.icon} />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
            <div className={styles.lightboxContent}>
              {!isLoading && (
                <img
                  className={styles.lightboxImg}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  crossOrigin="anonymous"
                  src={
                    // eslint-disable-next-line no-undef
                    `${process.env.REACT_APP_NOT_SECRET_CODE}images/` +
                    images[currentIndex].image
                  }
                />
              )}
              {isLoading && <CircularProgress className={styles.lightboxImg} />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PhotosOrders;
