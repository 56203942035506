import React, { useEffect, useState } from "react";
import { differenceInMinutes, parseISO } from "date-fns";
import { Box, Divider, Typography } from "@mui/material";
import InputBudget from "./InputBudget";
const BudgetOrders = ({ orders, onTotal, print }) => {
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const [totalSum, setTotalSum] = useState(0);
  const budgetsmayorders = orders.budgetsmayorders.filter(
    (item) => item.danhsachban.selectProduct === "Playstation"
  );

  useEffect(() => {
    let sum = 0;
    orders.budgetsorders.forEach((item) => {
      const subtotal = Number(item.quantity) * Number(item.price);
      sum += subtotal;
    });

    orders.budgetsmayorders
      .filter((item) => item.danhsachban.selectProduct === "Playstation")
      .forEach((item) => {
        const createdDate = parseISO(item.createdAt);
        const updatedDate = parseISO(item.updatedAt);
        const minutesDifference = differenceInMinutes(updatedDate, createdDate);
        const subtotal = (Number(minutesDifference) / 60) * Number(item.price);
        sum += subtotal;
      });
    onTotal(sum);
    setTotalSum(sum);
  }, [orders]);
  const fontSize = print ? "0.88rem" : "1rem";
  const lineHeight = print ? 1.2 : false;

  let discountDatasTotal = 0;
  if (Number(orders.discount) !== 0) {
    discountDatasTotal =
      Math.ceil(Number(totalSum) / 100) * Number(orders.discount);
  }
  function roundCurrency(value) {
    const base = Math.floor(value / 1000);
    const remainder = value % 1000;

    if (remainder < 500) {
      return base * 1000;
    } else {
      return (base + 1) * 1000;
    }
  }
  const roundPrice = roundCurrency(
    Number(totalSum) -
      Number(orders.paidAmount) -
      discountDatasTotal +
      (orders.status !== "inProgress" ? discountDatasTotal : 0)
  );

  const combinedOrders = orders.budgetsorders.reduce((acc, current) => {
    let budgetId = current.budgetId;

    // Kiểm tra xem budgetId đã tồn tại trong accumulator chưa
    if (acc[budgetId]) {
      // Ưu tiên mục có delivered == '' nếu tồn tại
      if (current.delivered === "") {
        acc[budgetId] = {
          ...current,
          quantity: acc[budgetId].quantity + current.quantity,
        };
      } else {
        // Nếu delivered != '', chỉ cộng quantity
        acc[budgetId].quantity += current.quantity;
      }
    } else {
      // Nếu chưa có, thêm mới vào accumulator
      acc[budgetId] = { ...current };
    }
    return acc;
  }, {});

  const budgetsorders = Object.values(combinedOrders);
  return (
    <Box>
      {(budgetsmayorders.length > 0 || orders.budgetsorders.length > 0) && (
        <>
          <Box sx={{ display: "flex", marginTop: 1 }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                flex: 3,
                fontSize,
                lineHeight,
              }}
            >
              Đơn giá
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                flex: 1,
                fontSize,
                lineHeight,
              }}
            >
              SL
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                flex: 2,
                textAlign: "right",
                fontSize,
                lineHeight,
              }}
            >
              Thành tiền
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: "#000" }} />
          {budgetsorders.map((item, index) => {
            return (
              <Box key={index}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      flex: 1,
                      color: item.delivered !== "" ? "blue" : "",
                      fontSize,
                      lineHeight,
                    }}
                  >
                    {item.budgets.name}
                  </Typography>
                  {orders.status === "inProgress" && (
                    <Box sx={{ display: print ? "none" : "block" }}>
                      <InputBudget id={item.id} orders={orders} />
                    </Box>
                  )}
                </Box>

                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{ flex: 3, fontSize, lineHeight }}
                  >
                    {number.format(item.price)} ₫
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ flex: 1, fontSize, lineHeight }}
                  >
                    {item.quantity}
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={{ flex: 2, textAlign: "right", fontSize, lineHeight }}
                  >
                    {number.format(Number(item.quantity) * Number(item.price))}{" "}
                    ₫
                  </Typography>
                </Box>
                <Divider />
              </Box>
            );
          })}
        </>
      )}
      {budgetsmayorders.length > 0 && (
        <>
          {budgetsmayorders.map((item, index) => {
            const nullCreate = item.createdAt.split("T");
            const timeCreate = nullCreate[1].split(":");
            const timeCreateAt = timeCreate[0] + ":" + timeCreate[1];
            const nullUpdate = item.updatedAt.split("T");
            const timeUpdate = nullUpdate[1].split(":");
            const timeUpdateAt = timeUpdate[0] + ":" + timeUpdate[1];
            const createdDate = parseISO(item.createdAt);
            const updatedDate = parseISO(item.updatedAt);

            // Tính khoảng cách thời gian trong phút
            const minutesDifference = differenceInMinutes(
              updatedDate,
              createdDate
            );
            return (
              <Box key={index}>
                <Typography
                  variant="body1"
                  sx={{ flex: 1, fontSize, lineHeight }}
                >
                  Máy - {item.danhsachban.orderNumber}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{ flex: 3, fontSize, lineHeight }}
                  >
                    {timeCreateAt} - {timeUpdateAt}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ flex: 1, fontSize, lineHeight }}
                  >
                    {minutesDifference} phút
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ flex: 2, textAlign: "right", fontSize, lineHeight }}
                  >
                    {number.format(
                      (Number(minutesDifference) / 60) * Number(item.price)
                    )}{" "}
                    ₫
                  </Typography>
                </Box>
                <Divider />
              </Box>
            );
          })}
        </>
      )}
      <Box sx={{ marginTop: print ? 0.5 : 2 }} />
      <PaymentInfo
        label="Tổng tiền:"
        amount={totalSum}
        print={print}
        number={number}
        flexLabel={3}
        flexAmount={1.5}
        fontSize={fontSize}
        lineHeight={lineHeight}
      />
      <PaymentInfo
        label="Thanh toán phần:"
        amount={orders.paidAmount}
        print={print}
        number={number}
        flexLabel={3}
        flexAmount={1.5}
        fontSize={fontSize}
        lineHeight={lineHeight}
      />
      {Number(orders.discount) !== 0 && (
        <PaymentInfo
          label="Giảm giá:"
          amount={discountDatasTotal}
          print={print}
          number={number}
          flexLabel={3}
          flexAmount={1.5}
          fontSize={fontSize}
          lineHeight={lineHeight}
        />
      )}
      <PaymentInfo
        label="Tổng thanh toán:"
        amount={roundPrice}
        print={print}
        number={number}
        flexLabel={3}
        flexAmount={1.5}
        fontSize={fontSize}
        lineHeight={lineHeight}
      />
    </Box>
  );
};

export default BudgetOrders;
const PaymentInfo = ({
  label,
  amount,
  print,
  number,
  flexLabel,
  flexAmount,
  fontSize,
  lineHeight,
}) => (
  <Box sx={{ display: "flex" }}>
    {!print && (
      <Typography sx={{ fontWeight: 500, flex: { lg: 10, md: 8, xs: 0 } }} />
    )}
    <Typography
      variant="body1"
      sx={{
        fontWeight: 500,
        flex: flexLabel,
        fontSize,
        lineHeight,
      }}
    >
      {label}
    </Typography>
    <Typography
      variant="body1"
      sx={{
        fontWeight: 500,
        flex: flexAmount,
        textAlign: "right",
        fontSize,
        lineHeight,
      }}
    >
      {number.format(amount)} ₫
    </Typography>
  </Box>
);
