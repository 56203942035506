import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Grid, Paper } from "@mui/material";
import AutocompleteSearch from "components/Mui/Autocompletes/AutocompleteSearch";
import React from "react";
const SearchCheckinCheckout = (props) => {
  const { setSearchStatus, usersData, onSearchUser } = props;
  return (
    <div>
      <Grid
        container
        sx={{ width: "100%", margin: "40px auto", height: "auto" }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Trạng Thái</InputLabel>
                <Select
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                  }}
                  label="Trạng Thái"
                  name="status"
                >
                  <MenuItem value="none">Không</MenuItem>
                  <MenuItem value="inProgress">Đang làm</MenuItem>
                  <MenuItem value="completed">Làm xong</MenuItem>
                  <MenuItem value="done">Đã chôt</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <AutocompleteSearch
                data={usersData}
                size="large"
                label="Người dùng"
                onCheckedChange={(e) => onSearchUser(e)}
                optionLabel={(label) => label.name}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default SearchCheckinCheckout;
