import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MenuCheckBox from "components/Mui/MenuCheckBox/MenuCheckBox";
import MenuTable from "components/Orders/AddOrdersBan/hooks/MenuTable";
import { Form, Formik } from "formik";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import axiosusers from "utils/api/axiosusers";

const ThembanOrders = ({ danhsachbanData, orders }) => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [done, setDone] = React.useState(false);
  const [loadingMenu, setLoadingMenu] = React.useState(false);
  const [menu, setMenu] = React.useState([]);
  const [may, setMay] = React.useState([]);

  const initialValues = {
    price: "",
    notes: "",
    nameGame: "",
    bonusAmount: "",
  };
  const validationSchema = "";

  const dataProduct = ["Coffee", "Playstation", "Fastfood"];
  const handleChangeMenu = (value) => {
    setMenu(value);
    setLoadingMenu(true);
    setTimeout(() => {
      setLoadingMenu(false);
    }, 10);
  };

  const onSubmit = async () => {
    setDone(true);
    setErrorMessage("");
    let errorMessage = "";

    if (errorMessage) {
      setDone(false);
      return setErrorMessage(errorMessage);
    }

    const data = {
      may,
      orderId: orders.id,
    };

    try {
      const response = await axiosusers.post(
        `/danhsachban/create-budgetsmayorders`,
        data
      );

      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      setDone(false);
      console.log(error);
      toast.error("Update error", {
        position: "top-right",
      });
    }
  };
  return (
    <Box sx={{ marginTop: "1rem" }}>
      <Button
        onClick={handleClickOpen("paper")}
        variant="contained"
        size="small"
        color="primary"
      >
        Thêm bàn
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Đặt mua</DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          sx={{
            minWidth: { lg: "600px", md: "600px", sm: "300px", xs: "300px" },
            margin: `auto`,
          }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={done ? null : onSubmit}
              >
                {() => (
                  <Form autoComplete="off">
                    <MenuCheckBox
                      data={dataProduct}
                      name="Chọn sản phẩm"
                      handleChangeMenu={handleChangeMenu}
                    />
                    {menu.length > 0 && !loadingMenu && (
                      <MenuTable
                        menu={menu}
                        danhsachbanData={danhsachbanData}
                        onChangePlaystation={(e) => setMay(e)}
                      />
                    )}

                    {errorMessage && (
                      <Alert sx={{ marginTop: `0.5rem` }} severity="error">
                        {errorMessage}
                      </Alert>
                    )}
                    <Button
                      type="submit"
                      sx={{ marginTop: `2rem` }}
                      variant="contained"
                      size="large"
                      fullWidth
                      color="primary"
                      disabled={done}
                    >
                      Tạo thêm
                    </Button>
                  </Form>
                )}
              </Formik>
              <ToastContainer
                style={{
                  top: "64px",
                  right: "0",
                  width: "max-content",
                }}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Box>
  );
};

export default ThembanOrders;
