import {
  AvatarGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteChiphi from "./DeleteChiphi";
import Images from "components/Avatar/Images";
const TableNhapChiphi = (props) => {
  const { product, handleCurrentDelete } = props;

  const showToast = ({ message, status }) => {
    handleCurrentDelete();
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Người tạo</TableCell>
              <TableCell>Sàn phẩm</TableCell>
              <TableCell>Tên</TableCell>
              <TableCell>Giá tiền</TableCell>
              <TableCell>Số lượng</TableCell>
              <TableCell align="right">Ảnh</TableCell>
              <TableCell align="center">Ghichu</TableCell>
              <TableCell>Create Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              var nullCreate = row.createdAt.split("T");
              var dateCreate = nullCreate[0].split("-");
              var dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.user?.name}
                  </TableCell>
                  <TableCell>{row.chiphi?.status}</TableCell>
                  <TableCell>
                    <Tooltip title="Copy" placement="top" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(`${row.name}`);
                        }}
                      >
                        {row.chiphi?.name}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{number.format(row.price)} ₫</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell>
                    <AvatarGroup max={4}>
                      {row.photos.map((item) => {
                        return <Images key={row.id} nameImage={item?.image} />;
                      })}
                    </AvatarGroup>
                  </TableCell>
                  <TableCell align="center">{row.notes}</TableCell>
                  <TableCell>{dateCreateAt}</TableCell>
                  <TableCell size="small" align="center">
                    <DeleteChiphi
                      id={row.id}
                      onToast={showToast}
                      onCheckedChange={(e) => handleCurrentDelete(e)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default TableNhapChiphi;
