import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import React from "react";
import CachedIcon from "@mui/icons-material/Cached";
import FromPayment from "./Devil/FromPayment";

const DevilPayment = (props) => {
  const { row, onToast } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = ({ message, status }) => {
    setOpen(false);
    onToast({ message, status });
  };

  return (
    <>
      <Tooltip title={"Quỹ"} placement="top" arrow>
        <IconButton color="primary" onClick={handleClickOpen}>
          <CachedIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FromPayment row={row} handleClose={handleClose} />
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default DevilPayment;
