import { useJwt } from "react-jwt";

function useDecodedToken() {
  const token = localStorage.getItem("accessToken");

  // Check if the token exists before using useJwt
  if (!token) {
    return { decodedToken: null, isExpired: true };
  }

  const { decodedToken, isExpired } = useJwt(token);

  return { decodedToken, isExpired };
}

export default useDecodedToken;
