import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import NavLinkAdd from "components/NavLinks/NavLinkAdd";
import React from "react";
import { useNavigate } from "react-router-dom";

const TableDanhsachban = ({ product }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [budgetsmayorders, setBudgetsmayorders] = React.useState([]);
  const onSubmit = (duongLink, data) => {
    if (duongLink === "open") {
      setOpen(true);
      setBudgetsmayorders(data);
    } else {
      navigate(duongLink);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 3,
        justifyContent: "center",
      }}
    >
      {product.map((row) => {
        let duongLink = `/manager/danhsachban/${row.id}`;
        if (row.budgetsmayorders.length > 1) {
          duongLink = `open`;
        } else if (row.budgetsmayorders.length === 1) {
          duongLink = `/manager/donhang/${row.budgetsmayorders[0].orderId}`;
        } else {
          duongLink = `/manager/donhang/ban/${row.id}`;
        }
        return (
          <Box
            onClick={() => onSubmit(duongLink, row.budgetsmayorders)}
            key={row.id}
            sx={{
              cursor: "pointer",
              width: 100,
              height: 100,
              borderRadius: 1,
              backgroundColor: row.status ? "#007FFF" : "#bdbdbd",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: 2,
            }}
          >
            <Typography variant="h6" component="div" sx={{ fontWeight: 400 }}>
              {row.orderNumber}
            </Typography>
          </Box>
        );
      })}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {budgetsmayorders.map((item, index) => {
              return (
                <Box key={index}>
                  <NavLinkAdd
                    href={`/manager/donhang/${item.orderId}`}
                    name={`Đơn hàng số ${item.orderId}`}
                  />
                </Box>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TableDanhsachban;
