import {
  Button,
  Tooltip,
  Typography,
  tooltipClasses,
  styled,
} from "@mui/material";
import React from "react";
const styles = {
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: 1.6,
  letterSpacing: "0.00958em",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
};
const HtmlTooltips = ({ text }) => {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(12),
      boxShadow:
        "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)",
      margin: "auto",
      maxWidth: "1000px",
    },
  }));
  return (
    <HtmlTooltip
      placement="left-start"
      title={
        <React.Fragment>
          <Typography color="inherit" style={styles}>
            {text}
          </Typography>
        </React.Fragment>
      }
    >
      <Button
        variant="contained"
        onClick={() => navigator.clipboard.writeText(`${text}`)}
        color="primary"
        size="small"
      >
        Copy
      </Button>
    </HtmlTooltip>
  );
};

export default HtmlTooltips;
