import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BudgetDelivered from "./BudgetDelivered";
const Delivered = ({ orders }) => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [nameStatus, setNameStatus] = React.useState("");
  const [inputFields, setInputFields] = React.useState([]);

  const handleClickOpen = (scrollType, status) => () => {
    setOpenDialog(true);
    setNameStatus(status);
    setScroll(scrollType);
  };

  const onSubmitCoffee = async (name) => {
    setOpen(true);
    const namestatus = nameStatus === "coffee" ? "Coffee" : "Fastfood";

    let budgetsorders = orders.budgetsorders
      .filter((item) => item.status === namestatus)
      .map((item) => ({
        ...item,
        usedQuantity: item.quantity,
      }));
    if (name === "undo") {
      budgetsorders = orders.budgetsorders.map((item) => ({
        ...item,
        usedQuantity: item.quantity,
      }));
    }
    console.log(budgetsorders);
    const dataSend = {
      id: orders.id,
      orders,
      status: "coffee",
      name,
      inputFields: inputFields.length === 0 ? budgetsorders : inputFields,
    };
    try {
      const response = await axiosusers.post(
        `/orders/delivered-orders-coffee`,
        dataSend
      );
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      setOpen(false);
      console.error(error);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  const onSubmitFastfood = async (name) => {
    setOpen(true);
    const budgetsorders = orders.budgetsorders.map((item) => ({
      ...item,
      usedQuantity: item.quantity,
    }));
    const dataSend = {
      id: orders.id,
      orders,
      status: "fastfood",
      name,
      inputFields: inputFields.length === 0 ? budgetsorders : inputFields,
    };
    try {
      const response = await axiosusers.post(
        `/orders/delivered-orders-coffee`,
        dataSend
      );
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      setOpen(false);
      console.error(error);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  const coffee = orders.budgetsorders.filter(
    (item) => item.status === "Coffee" && item.delivered !== "Coffee"
  );
  const fastfood = orders.budgetsorders.filter(
    (item) => item.status === "Fastfood" && item.delivered !== "Fastfood"
  );
  const handleClose = () => {
    setOpenDialog(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const onChangeInputFields = (newInputFields) => {
    setInputFields(newInputFields);
  };
  return (
    <div>
      <Divider style={{ backgroundColor: "#000", margin: "10px 0 0 4px" }} />
      <Box sx={{ margin: "30px 0px", display: "flex", gap: 1 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={open ? null : handleClickOpen("paper", "coffee")}
          disabled={open || coffee.length === 0}
        >
          {coffee.length > 0 ? "Giao hàng Coffee" : "Đã giao hàng Coffee"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={open ? null : () => onSubmitCoffee("undo", "Coffee")}
          disabled={open || coffee.length > 0}
        >
          Quay lại Coffee
        </Button>
      </Box>
      <Box sx={{ margin: "30px 0px", display: "flex", gap: 1 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={open ? null : handleClickOpen("paper", "fastfood")}
          disabled={open || fastfood.length === 0}
        >
          {fastfood.length > 0 && orders.status == "inProgress"
            ? "Giao hàng fastfood"
            : "Đã giao hàng fastfood"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={open ? null : () => onSubmitFastfood("undo", "Fastfood")}
          disabled={open || fastfood.length > 0}
        >
          Quay lại fastfood
        </Button>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Giao hàng {nameStatus}
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          sx={{
            minWidth: { lg: "600px", md: "600px", sm: "300px", xs: "300px" },
            margin: `auto`,
          }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box>
              <BudgetDelivered
                orders={orders}
                onChangeInputFields={onChangeInputFields}
                nameStatus={nameStatus}
              />
              {nameStatus === "fastfood" && (
                <Button
                  type="submit"
                  sx={{ marginTop: `2rem` }}
                  variant="contained"
                  size="large"
                  fullWidth
                  color="primary"
                  onClick={open ? null : () => onSubmitFastfood("fastfood")}
                  disabled={open}
                >
                  Giao hàng {nameStatus}
                </Button>
              )}
              {nameStatus === "coffee" && (
                <Button
                  type="submit"
                  sx={{ marginTop: `2rem` }}
                  variant="contained"
                  size="large"
                  fullWidth
                  color="primary"
                  onClick={open ? null : () => onSubmitCoffee("coffee")}
                  disabled={open}
                >
                  Giao hàng {nameStatus}
                </Button>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default Delivered;
