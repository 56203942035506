import React from "react";
import useSWR from "swr";
import axiosusers from "utils/api/axiosusers";
import SearchReport from "./Main/SearchReport";
import TableReport from "./Main/TableReport";
import { format } from "date-fns";
import GeneralReport from "./Main/GeneralReport";
import { differenceInMinutes, parseISO } from "date-fns";
import TableTienLuongReport from "./Main/TableTienLuongReport";
const index = () => {
  const data = JSON.parse(localStorage.getItem("auth"));
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: userData } = useSWR(`/users/users-all`, fetchDataSwr);
  const [selectUser, setSelectUser] = React.useState(null);
  const [product, setProduct] = React.useState([]);
  const [tienluong, setTienluong] = React.useState([]);
  const [value, setValue] = React.useState([null, null]);
  const onSearchUser = (data) => {
    setSelectUser(data);
  };

  const onChangeData = (data) => {
    setValue(data);
  };
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const fetchData = async () => {
    Promise.all([
      axiosusers.get(`/reports/reports-staff`, {
        params: {
          from: formatDate(new Date(value[0])) + "T00:00:00.000Z",
          to: formatDate(new Date(value[1])) + "T23:59:59.000Z",
          userId: data.role != "Staff" ? selectUser?.id : data?.id,
        },
      }),
    ])
      .then((results) => {
        const [orders] = results.map((res) => res.data);
        setProduct(orders.usersstaffAll);
        setTienluong(orders.tienluongAll);
      })
      .catch((error) => console.error(error));
  };
  React.useEffect(() => {
    if (data) {
      fetchData();
    }
  }, [selectUser, value]);
  const fromDate = value[0]
    ? format(value[0], "dd/MM/yyyy")
    : format(new Date(), "dd/MM/yyyy");
  const toDate = value[1]
    ? format(value[1], "dd/MM/yyyy")
    : format(new Date(), "dd/MM/yyyy");

  const totalRemainAmount = product.reduce((total, item) => {
    return total + Number(item.remainAmount);
  }, 0);
  const totalMinutesDifference = product.reduce((total, row) => {
    const createdDate = parseISO(row.createdAt);
    const updatedDate = parseISO(row.updatedAt);
    const minutesDifference = differenceInMinutes(updatedDate, createdDate);
    return total + minutesDifference;
  }, 0);
  const totalTienluong = tienluong.reduce((acc, order) => {
    return acc + Number(order.price);
  }, 0);

  return (
    <div>
      <SearchReport
        data={data}
        userData={userData === undefined ? [] : userData}
        onSearchUser={onSearchUser}
        onChangeData={onChangeData}
      />
      <GeneralReport
        fromDate={fromDate}
        toDate={toDate}
        data={data}
        selectUser={selectUser}
        Total={totalRemainAmount}
        totalMinutesDifference={totalMinutesDifference}
        totalTienluong={totalTienluong}
      />
      {product.length > 0 && (
        <TableReport data={data} result={product} tienluong={tienluong} />
      )}
      {tienluong.length > 0 && (
        <TableTienLuongReport data={data} result={tienluong} />
      )}
    </div>
  );
};

export default index;
