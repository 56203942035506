import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import AutocompleteId from "hooks/useAutocomplete";
import UploadMultiple from "components/Mui/Uploads/UploadMultiple";
import useUserId from "hooks/useUserId";
import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";

const AddNhapKho = (props) => {
  const { ngansachkhoData, suppliersData } = props;
  const navigate = useNavigate();
  const { userId } = useUserId();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [suppliers, setSuppliers] = React.useState([]);
  const [thucDonChinh, setThucDonChinh] = React.useState([]);
  const initialValues = {
    notes: "",
  };
  const validationSchema = Yup.object().shape({});
  const [input, setInput] = React.useState([]);
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeThucDonChinh = (value) => {
    setInput(value);
    setThucDonChinh(value);
  };
  const [avatar, setAvatar] = React.useState([]);
  const handPhotoChange = (avatar) => {
    setAvatar(avatar);
  };
  const handleChangeSuppliers = (value) => {
    setSuppliers(value);
  };
  const onSubmit = async (values) => {
    setDung(true);
    let errorMessage = "";

    if (thucDonChinh?.id === undefined) {
      errorMessage = `Vui lòng chọn thực đơn chính`;
    }

    if (errorMessage !== "") {
      setDung(false);
      seterrorMessage(errorMessage);
      toast.error(errorMessage, {
        position: "top-right",
      });
      return;
    }
    const data = {
      userId: userId.id,
      notes: values.notes,
      thucdonchinhId: thucDonChinh?.id,
      supplierId: suppliers?.id,
      price: input.price,
      quantity: input.quantity,
    };
    try {
      const response = await axiosusers.post(
        `/nhapkhochinh/create-nhapkhochinh`,
        data
      );
      if (response.status === 200) {
        console.log(response);
        for (const image of avatar.sort((a, b) => a.size - b.size)) {
          const formData = new FormData();
          formData.append("file", image.file);
          formData.append("nhapkhochinhId", response.data.nhapkhochinhId);
          formData.append("status", "image");

          try {
            await axiosusers.post(
              `/nhapkhochinh/nhapkhochinh-photos`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } catch (error) {
            console.log(error);
          }
        }
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/nhapkho-chinh");
        }, 500);
      }
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };

  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  console.log(number);
  const onChangeStatus = (value) => {
    setStatus(value);
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={dung ? null : onSubmit}
      >
        {() => (
          <Form autoComplete="off">
            <FormControlProduct onChangeStatus={onChangeStatus} />

            <AutocompleteId
              data={ngansachkhoData.filter((item) => item.status === status)}
              label="Thực đơn chính"
              id="Thực đơn chính"
              name="Thực đơn chính"
              size="small"
              openError={true}
              done={errorMessage && thucDonChinh?.id === undefined}
              onCheckedChange={handleChangeThucDonChinh}
              optionLabel={(label) => label.name}
            />
            {thucDonChinh?.id !== undefined && (
              <>
                <Box
                  component="form"
                  autoComplete="off"
                  sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
                >
                  <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                    Tên sản phẩm
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                    Đơn vị tính
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                    Giá mua
                  </Typography>

                  <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                    Nhập số lượng
                  </Typography>
                </Box>
                <Box
                  component="form"
                  autoComplete="off"
                  sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
                >
                  <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                    {thucDonChinh.name}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                    {thucDonChinh.unit}
                  </Typography>
                  <TextField
                    fullWidth
                    type="number"
                    name="price"
                    onChange={handleinput}
                    value={input.price}
                    label="Giá mua"
                    variant="outlined"
                    size="small"
                    sx={{ flex: 1 }}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    name="quantity"
                    onChange={handleinput}
                    label="Nhập số lượng"
                    variant="outlined"
                    size="small"
                    sx={{ flex: 1 }}
                  />
                </Box>
                <Box>
                  {" "}
                  <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                    {`Tổng tiền: ${number.format(
                      Number(input.price) * Number(input.quantity)
                    )} VNĐ`}
                  </Typography>
                </Box>
              </>
            )}
            <AutocompleteId
              data={suppliersData}
              label="Nhà cung cấp"
              id="Nhà cung cấp"
              name="Nhà cung cấp"
              size="small"
              openError={false}
              done={false}
              onCheckedChange={handleChangeSuppliers}
              optionLabel={(label) => label.fullName}
            />
            <Field
              sx={{ marginTop: `1.5rem` }}
              as={TextField}
              multiline
              minRows="4"
              name="notes"
              label="Ghi chú"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Box sx={{ marginTop: `1rem` }}>
              <UploadMultiple handPhotoChange={handPhotoChange} />
            </Box>
            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddNhapKho;
