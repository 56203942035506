import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import React from "react";
import FromTachDon from "./FromTachDon";
import FromGhepDon from "./FromGhepDon";

const ThembanOrders = ({
  danhsachbanData,
  orders,
  ordersInprogress,
  danhsachbanDatas2,
}) => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [nameStatus, setNameStatus] = React.useState("");
  const handleClickOpen = (scrollType, status) => () => {
    setNameStatus(status);
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <Box sx={{ marginTop: "1rem" }}>
      <Box sx={{ display: "flex", gap: 1.5 }}>
        <Button
          onClick={handleClickOpen("paper", "Tách đơn")}
          variant="contained"
          size="small"
          color="primary"
        >
          Tách đơn
        </Button>
        <Button
          onClick={handleClickOpen("paper", "Ghép đơn")}
          variant="contained"
          size="small"
          color="primary"
        >
          Ghép đơn
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{nameStatus}</DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          sx={{
            minWidth: { lg: "600px", md: "600px", sm: "300px", xs: "300px" },
            margin: `auto`,
          }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box>
              {nameStatus === "" && (
                <FromTachDon
                  danhsachbanData={danhsachbanData}
                  orders={orders}
                />
              )}
              {nameStatus === "Tách đơn" && (
                <FromTachDon
                  danhsachbanData={danhsachbanData}
                  orders={orders}
                />
              )}
              {nameStatus === "Ghép đơn" && (
                <FromGhepDon
                  danhsachbanDatas2={danhsachbanDatas2}
                  orders={orders}
                  ordersInprogress={ordersInprogress}
                />
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Box>
  );
};

export default ThembanOrders;
