import Images from "components/Avatar/Images";
import {
  Avatar,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const GeneralReport = (props) => {
  const {
    data,
    selectUser,
    fromDate,
    toDate,
    totalMinutesDifference,
    Total,
    totalTienluong,
  } = props;
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });

  return (
    <div>
      <Paper variant="outlined">
        <Grid container spacing={0}>
          {data?.role === "Staff" && (
            <Grid item xs={3}>
              <ListItem>
                <ListItemAvatar>
                  {data?.avatarUrl == "" ? (
                    <Avatar alt="Remy Sharp" src="" />
                  ) : (
                    <Images nameImage={data?.avatarUrl} />
                  )}
                </ListItemAvatar>

                <ListItemText
                  primary={<Typography>{data.name}</Typography>}
                  secondary={data.roles}
                />
              </ListItem>
            </Grid>
          )}
          {data?.role !== "Staff" && selectUser && (
            <Grid item xs={3}>
              <ListItem>
                <ListItemAvatar>
                  {selectUser?.avatarUrl == "" ? (
                    <Avatar alt="Remy Sharp" src="" />
                  ) : (
                    <Images nameImage={selectUser?.avatarUrl} />
                  )}
                </ListItemAvatar>

                <ListItemText
                  primary={<Typography>{selectUser?.name}</Typography>}
                  secondary={selectUser?.roles}
                />
              </ListItem>
            </Grid>
          )}
        </Grid>
        {!selectUser && data?.role != "Staff" && (
          <Typography
            color="primary"
            variant="subtitle2"
            sx={{
              fontSize: "16px",
              margin: (theme) => theme.spacing(2, 0, 1, 2),
            }}
          >
            Báo cáo chung cho tất cả người dùng và tất cả sản phẩm
          </Typography>
        )}

        <Divider sx={{ margin: (theme) => theme.spacing(0, 2, 2, 2) }} />
        <TableContainer sx={{ width: { md: "100%", xs: "88vw" } }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Phạm vi ngày
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Tổng thời gian làm việc
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Tổng số tiền nhận được
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Tổng số tiền ứng lương
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Tổng số tiền
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >{`${fromDate} - ${toDate}`}</TableCell>

                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {totalMinutesDifference} phút
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(Total)} ₫
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(totalTienluong)} ₫
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(Total - totalTienluong)} ₫
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default GeneralReport;
