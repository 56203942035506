import { Box, CircularProgress } from "@mui/material";
import { Tabtitle } from "components/title/Title";
import React from "react";

import axiosusers from "utils/api/axiosusers";
import BasicPagination from "hooks/usePagination";
import TableCheckinCheckout from "./Main/TableCheckinCheckout";

import "react-toastify/dist/ReactToastify.css";
import useUserId from "hooks/useUserId";
import useSWR from "swr";
const index = () => {
  Tabtitle("Check in - Check out");

  const [searchStatus, setSearchStatus] = React.useState("");
  const [searchUser, setSearchUser] = React.useState("");
  const { userId } = useUserId();
  const MAX_RETRIES = 3;
  const pageSize = 20;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [retryCount, setRetryCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);
  const fetchDataAll = async () => {
    try {
      const response = await axiosusers.get("/users/usersstaff", {
        params: {
          status: searchStatus,
          pageSize,
          currentPage: currentPage === 0 ? 1 : currentPage,
          data: userId,
          userId: searchUser?.id,
        },
      });
      const res = response.data;
      setProduct(res.usersstaff);
      setPageCount(Math.ceil(res.pages / pageSize));
      setLoading(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("401 Unauthorized. Retrying...");
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          fetchDataAll();
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error(error);
      }
    }
  };

  React.useEffect(() => {
    const delayFetch = setTimeout(() => {
      if (userId) {
        fetchDataAll();
      }
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [searchStatus, pageSize, searchUser, currentPage, userId]);
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: usersData } = useSWR(`/users/users-all`, fetchDataSwr);
  return (
    <div>
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {loading && userId && (
        <>
          {loading && (
            <TableCheckinCheckout
              product={product}
              userId={userId}
              handleCurrentDelete={fetchDataAll}
              setSearchStatus={(e) => setSearchStatus(e)}
              onSearchUser={(e) => setSearchUser(e)}
              usersData={usersData}
            />
          )}

          <Box sx={{ marginTop: "20px" }}>
            <BasicPagination
              onCheckedChange={(e) => setCurrentPage(e)}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default index;
