import React from "react";
import { Tabtitle } from "components/title/Title";
import TelegramApiSearch from "./Main/TelegramApiSearch";
import NavLinkAdd from "components/NavLinks/NavLinkAdd";
import axiosusers from "utils/api/axiosusers";
import { Box, CircularProgress } from "@mui/material";
import TelegramApiTable from "./Main/TelegramApiTable";

const index = () => {
  Tabtitle("Telegram API");
  const data = JSON.parse(localStorage.getItem("auth"));

  const pageSize = 10;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [searchName, setSearchName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchDataAll = async () => {
    Promise.all([
      axiosusers.get("/telegramApi/telegramApi", {
        params: {
          searchName,
          pageSize,
          currentPage: currentPage === 0 ? 1 : currentPage,
        },
      }),
    ])
      .then(async (results) => {
        const [res] = results.map((res) => res.data);
        setProduct(res.telegramApi);
        setPageCount(Math.ceil(res.pages / pageSize));
      })
      .finally(() => setLoading(true))
      .catch((error) => console.error(error));
  };
  React.useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [pageSize, searchName, currentPage]);

  const onChange = (data) => {
    setSearchName(data);
  };
  const handleCurrentPage = (newChecked) => {
    setCurrentPage(newChecked);
  };

  return (
    <div>
      {data?.role === "Admin" && (
        <NavLinkAdd href="/manager/telegram-api/add" name="ADD telegram api" />
      )}
      <TelegramApiSearch searchName={onChange} />
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      <TelegramApiTable
        handleCurrentPage={handleCurrentPage}
        handleCurrentDelete={fetchDataAll}
        pageCount={pageCount}
        product={product}
        currentPage={currentPage}
      />
    </div>
  );
};

export default index;
