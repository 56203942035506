import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteTransaction from "./DeleteTransaction";
import CompleteTransaction from "./CompleteTransaction";
const TableTransaction = (props) => {
  const { product, handleCurrentDelete } = props;
  const showToast = ({ message, status }) => {
    handleCurrentDelete();
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Mã giao dịch</TableCell>
              <TableCell>Khách hàng</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Thanh toán</TableCell>
              <TableCell>Số tiền</TableCell>
              <TableCell>Số tiền còn lại</TableCell>
              <TableCell>Trạng thái</TableCell>
              <TableCell>Cập nhật ngày tháng</TableCell>
              <TableCell>Ngày tạo ra</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              const nullCreate = row.createdAt.split("T");
              const dateCreate = nullCreate[0].split("-");
              const dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              const nullUpdate = row.updatedAt.split("T");
              const dateUpdate = nullUpdate[0].split("-");
              const dateUpdateAt =
                dateUpdate[2] + "/" + dateUpdate[1] + "/" + dateUpdate[0];
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Tooltip title="Copy" placement="top" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(`${row.code}`);
                        }}
                      >
                        {row.code}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Copy" placement="top" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${row.client.fullName}`
                          );
                        }}
                      >
                        {row.client.fullName}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{row.client.email}</TableCell>
                  <TableCell>{row.payment.owner}</TableCell>
                  <TableCell>
                    <Tooltip title="Copy" placement="top" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${Math.round(row.remainAmount)}`
                          );
                        }}
                      >
                        {number.format(row.amount)}
                      </span>
                    </Tooltip>{" "}
                    ₫
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Copy" placement="top" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${Math.round(row.remainAmount)}`
                          );
                        }}
                      >
                        {number.format(row.remainAmount)}
                      </span>
                    </Tooltip>{" "}
                    ₫
                  </TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{dateUpdateAt}</TableCell>
                  <TableCell>{dateCreateAt}</TableCell>
                  <TableCell size="small" align="center">
                    {row.status !== "completed" && row.status !== "pending" && (
                      <CompleteTransaction
                        id={row.id}
                        onToast={showToast}
                        onCheckedChange={(e) => handleCurrentDelete(e)}
                      />
                    )}
                    <DeleteTransaction
                      id={row.id}
                      onToast={showToast}
                      onCheckedChange={(e) => handleCurrentDelete(e)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default TableTransaction;
