import { Autocomplete, Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const EditTienLuong = ({ product, usersData }) => {
  const navigate = useNavigate();
  const [input, setInput] = useState(product);
  const [errorMessage, seterrorMessage] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(product.user);
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async () => {
    if (input.price === "") {
      return seterrorMessage("Số tiền là bắt buộc");
    }
    if (selectedUsers?.id === undefined) {
      return seterrorMessage("Chọn người dùng bắt buộc");
    }
    const data = {
      id: product.id,
      price: input.price,
      notes: input.notes,
      userId: selectedUsers.id,
    };

    try {
      const response = await axiosusers.post(
        `/tienluong/update-tienluong`,
        data
      );
      console.log(response);

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/tienluong");
        }, 500);
      }
    } catch (error) {
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);
  return (
    <div>
      <Autocomplete
        sx={{ marginTop: "0.5rem" }}
        options={usersData}
        fullWidth
        value={selectedUsers}
        onChange={(event, value) => setSelectedUsers(value)}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(jsonResuls) => jsonResuls.name}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(selectedUsers === null)}
            helperText={
              selectedUsers === null ? "Chọn người dùng bắt buộc" : ""
            }
            label="Chọn người dùng"
            variant="outlined"
          />
        )}
      />
      <TextField
        sx={{ marginTop: `0.5rem` }}
        onChange={handleinput}
        type="number"
        value={input.price}
        name="price"
        error={Boolean(input.price === "")}
        helperText={input.price === "" ? "Số tiền là bắt buộc" : ""}
        label="Số tiền"
        size="small"
        variant="outlined"
        fullWidth
      />

      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        multiline
        minRows={4}
        type="text"
        value={input.notes}
        name="notes"
        label="Ghi chú"
        size="small"
        variant="outlined"
        fullWidth
      />
      {errorMessage && <FilledAlerts message={errorMessage} success="error" />}
      <Button
        type="submit"
        sx={{ margin: (theme) => theme.spacing(5, 0) }}
        variant="contained"
        size="large"
        fullWidth
        color="primary"
        onClick={onSubmit}
      >
        Update
      </Button>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default EditTienLuong;
