import axios from "axios";

const axiosusers = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_NOT_SECRET_CODE,
  // timeout: 1000,
  headers: { "X-Custom-Header": "foobar" },
});

axiosusers.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

// Add a response interceptor
axiosusers.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const response = await axiosusers.post("/auth/refreshToken", {
          refreshToken,
        });

        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${response.data.refreshToken}`;
        return axios(originalRequest);
      } catch (error) {
        console.error(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosusers;
