import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import axiosusers from "utils/api/axiosusers";
import * as Yup from "yup";
const FromPayment = ({ row, handleClose }) => {
  const [status, setStatus] = React.useState("");
  const handleClickOpen = (name) => {
    setStatus(name);
  };
  const [dung, setDung] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const initialValues = {
    withdraw: "",
  };
  const validationSchema = Yup.object().shape({
    withdraw: Yup.number()
      .min(0.00000001, "Số tiền rút không được nhỏ hơn 0")
      .max(
        status === "Thêm" ? row.balance : row.devilAmount,
        "Số tiền rút không được lớn hơn số dư hiện tại"
      )
      .required("Số tiền rút là bắt buộc"),
  });
  const onSubmit = async (values, props) => {
    setDung(true);
    if (status === "Thêm" && Number(row.balance) === 0) {
      setDung(false);
      seterrorMessage("Số dư của bạn hiện tại là 0");
      return;
    }
    const data = {
      row,
      amount: values.withdraw,
      status,
    };

    try {
      const response = await axiosusers.post(`/payments/update-devil`, data);
      console.log(response);

      if (response.status === 200) {
        setDung(false);
        handleClose({ message: response.data.message, status: true });
        props.resetForm();
      }
    } catch (error) {
      console.log(error?.response);
      setDung(false);
      seterrorMessage(error.response.data.message);
      handleClose({ message: error.response.data.message, status: false });
    }
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const numberthem = new Intl.NumberFormat("en-NZ", {
    minimumFractionDigits: 2,
  });
  const amountBalance =
    row.currency === "USD"
      ? `${numberthem
          .format(Math.round(row.balance * 100) / 100)
          .replace(".", ",")} $`
      : `${number.format(row.balance)} ₫`;
  const amountdevilAmount =
    row.currency === "USD"
      ? `${numberthem
          .format(Math.round(row.devilAmount * 100) / 100)
          .replace(".", ",")} $`
      : `${number.format(row.devilAmount)} ₫`;
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          gap: 1.5,
          margin: "1rem auto",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <Button
          onClick={() => handleClickOpen("Thêm")}
          variant={status === "Thêm" ? "contained" : "outlined"}
          size="small"
          color="primary"
        >
          Thêm quỹ
        </Button>

        <Button
          onClick={() => handleClickOpen("Rút")}
          variant={status === "Rút" ? "contained" : "outlined"}
          size="small"
          color="primary"
        >
          Rút quỹ
        </Button>
        <Button
          onClick={() => handleClickOpen("Chuyển")}
          variant={status === "Chuyển" ? "contained" : "outlined"}
          size="small"
          color="primary"
        >
          Chuyển quỹ
        </Button>
      </Box>

      <Typography
        variant="h6"
        gutterBottom
        color="primary"
        sx={{
          fontWeight: 500,
          fontSize: "16px",
          marginTop: `2rem`,
        }}
      >
        Số dư: {amountBalance}
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        color="primary"
        sx={{
          fontWeight: 500,
          fontSize: "16px",
        }}
      >
        Quỹ: {amountdevilAmount}
      </Typography>
      {status && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form>
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="withdraw"
                label={`Số tiền ${status} là bắt buộc`}
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.withdraw && props.touched.withdraw}
                helperText={<ErrorMessage name="withdraw" />}
              />

              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}
              <Button
                disabled={
                  Number(row.balance) === 0
                    ? status === "Thêm"
                      ? true
                      : ""
                    : ""
                }
                type="submit"
                sx={{ margin: (theme) => theme.spacing(2, 0) }}
                fullWidth
                variant="contained"
                size="large"
                color="primary"
              >
                {status} quỹ
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default FromPayment;
