import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";

const MenuCheckBox = (props) => {
  const { name, data, handleChangeMenu } = props;
  const [personName, setPersonName] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    handleChangeMenu(typeof value === "string" ? value.split(",") : value);
  };
  return (
    <>
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginTop: "0.5rem" }}
      >
        <InputLabel id="demo-multiple-checkbox-label">{name}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          label={name}
          renderValue={(selected) => selected.join(", ")}
        >
          {data.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default MenuCheckBox;
