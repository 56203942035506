import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import PhotosOrders from "components/Orders/DetailOrders/Main/hocks/PhotosOrders";
import React from "react";

const DetailNhapKho = ({ product }) => {
  console.log(product);
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const deletePhotoOrders = (id) => {
    console.log(id);
  };
  return (
    <div>
      <Autocomplete
        sx={{ marginTop: "0.5rem" }}
        options={product.product}
        fullWidth
        value={product.product}
        disabled
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(jsonResuls) => jsonResuls.name}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sản Phẩm kho chính"
            placeholder="Chọn một hoặc nhiều sản phẩm"
            variant="outlined"
          />
        )}
      />
      <Typography
        variant="h6"
        sx={{ fontWeight: 500, fontSize: "1rem", marginTop: "0.5rem" }}
      >
        Tên thực đơn: {product.ngansachkho?.name}
      </Typography>

      <Box
        component="form"
        autoComplete="off"
        sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0 }}
      >
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          Tên ngân sách
        </Typography>
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          Giá mua
        </Typography>
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          Số lượng đã thêm
        </Typography>
      </Box>
      <Box
        component="form"
        autoComplete="off"
        sx={{ display: "flex", gap: 1, mb: 1, marginTop: 0.5 }}
      >
        <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
          {product.ngansachkho?.name}
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
          {number.format(product.price)} đ
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
          {product.quantity}
        </Typography>
      </Box>
      <TextField
        sx={{ marginTop: `0.5rem` }}
        type="text"
        disabled
        value={product.supplier?.fullName}
        name="supplier"
        label="Nhà cung cấp"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        sx={{ marginTop: `0.5rem` }}
        multiline
        disabled
        minRows={3}
        value={product.notes}
        name="notes"
        label="Ghi chú"
        size="small"
        variant="outlined"
        fullWidth
      />
      <PhotosOrders
        images={product.photos}
        deletePhotoOrders={deletePhotoOrders}
        deletePhoto={false}
      />
    </div>
  );
};

export default DetailNhapKho;
