import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import useUserId from "hooks/useUserId";
import { useNavigate } from "react-router-dom";

const AddUsers = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const { userId } = useUserId();
  const initialValues = {
    fullName: "",
    notes: "",
    token: "",
    idToken: "",
    idTokenToal: "",
    idTokenMuave: "",
    idTokenKho: "",
  };
  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(0, "It's too short")
      .required("Name is required"),
    token: Yup.string().required("Token is required"),
    idToken: Yup.string().required("Telegram ID is required"),
  });

  const onSubmit = async (values) => {
    setDung(true);
    if (userId.role === "Admin") {
      const data = {
        fullName: values.fullName,
        notes: values.notes,
        token: values.token,
        idToken: values.idToken,
        idTokenToal: values.idTokenToal,
        idTokenMuave: values.idTokenMuave,
        idTokenKho: values.idTokenKho,
      };

      try {
        const response = await axiosusers.post(
          `/telegramApi/create-telegramApi`,
          data
        );

        if (response.status === 200) {
          console.log(response);
          toast.success(response.data.message, {
            position: "top-right",
          });
          setTimeout(function () {
            navigate("/manager/telegram-api");
          }, 500);
        }
      } catch (error) {
        setDung(false);
        console.log(error?.response);
        seterrorMessage(error.response.data.message);
        toast.error(error.response.data.message, {
          position: "top-right",
        });
      }
    }
  };

  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new Telegram API
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="fullName"
                label="Name bot api"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.fullName && props.touched.fullName}
                helperText={<ErrorMessage name="fullName" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="token"
                label="Token"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.token && props.touched.token}
                helperText={<ErrorMessage name="token" />}
              />

              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="idToken"
                label="Telegram ID"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.idToken && props.touched.idToken}
                helperText={<ErrorMessage name="idToken" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="idTokenToal"
                label="Telegram ID Group Total"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="idTokenMuave"
                label="Telegram ID Mua về"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="idTokenKho"
                label="Telegram ID Kho"
                variant="outlined"
                size="small"
                fullWidth
              />

              <Field
                sx={{ marginTop: `1rem` }}
                as={TextField}
                multiline
                minRows="4"
                name="notes"
                label="Ghi chú"
                variant="outlined"
                size="small"
                fullWidth
              />
              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddUsers;
