import React, { useEffect } from "react";
import Profile from "./Profile";
import Password from "./Password";
import { Container, Grid, Paper } from "@mui/material";
import { Tabtitle } from "components/title/Title";
import { useParams } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";

const index = () => {
  Tabtitle("Profiles");
  const { id } = useParams();
  const [users, setUsers] = React.useState([]);
  useEffect(() => {
    Promise.all([axiosusers.get(`/users/profile/${id}`)])
      .then((results) => {
        const [gameId] = results.map((res) => res.data);
        setUsers(gameId);
      })
      .catch((error) => console.error(error));
  }, [id]);

  return (
    <div>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Profile users={users} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Password users={users} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default index;
