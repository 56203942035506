import { Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
const EditDiscount = ({ product }) => {
  const navigate = useNavigate();
  const [input, setInput] = useState(product);
  const [errorMessage, seterrorMessage] = useState("");
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async () => {
    if (input.fullName === "") {
      return seterrorMessage("Name is required");
    }
    const data = {
      id: product.id,
      percent: input.percent,
      status: input.status,
    };

    try {
      const response = await axiosusers.post(`/discount/update-discount`, data);
      console.log(response);

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/discount");
        }, 500);
      }
    } catch (error) {
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);
  return (
    <div>
      <TextField
        sx={{ marginTop: `0.5rem` }}
        onChange={handleinput}
        type="text"
        value={input.percent}
        name="percent"
        error={Boolean(input.percent === "")}
        helperText={input.percent === "" ? "Phần trăm là bắt buộc" : ""}
        label="Phần trăm"
        size="small"
        variant="outlined"
        fullWidth
      />
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginTop: `0.5rem` }}
      >
        <InputLabel>Chọn trạng thái</InputLabel>
        <Select
          label="Chọn trạng thái"
          name="status"
          value={input.status}
          onChange={handleinput}
        >
          <MenuItem value="default">Mặc định</MenuItem>
          <MenuItem value="stop">Dừng lại</MenuItem>
        </Select>
      </FormControl>
      {errorMessage && <FilledAlerts message={errorMessage} success="error" />}
      <Button
        type="submit"
        sx={{ margin: (theme) => theme.spacing(5, 0) }}
        variant="contained"
        size="large"
        fullWidth
        color="primary"
        onClick={onSubmit}
      >
        Update
      </Button>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default EditDiscount;
