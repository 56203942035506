import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";

export default function BasicPagination(props) {
  const { pageCount, currentPage, onCheckedChange } = props;
  const Pagechane = (event, value) => {
    onCheckedChange(value);
  };
  let page = currentPage;
  if (page == 0) {
    page = 1;
  }
  if (currentPage > pageCount) {
    page = pageCount;
    onCheckedChange(page);
  }
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Stack spacing={2}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={Pagechane}
          color="primary"
          size="small"
        />
      </Stack>
    </Box>
  );
}
