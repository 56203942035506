import { Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";
import UpdateFile from "components/Mui/Uploads/UpdateFile";
import useLinks from "hooks/useLinks";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";

const EditProduct = ({ product }) => {
  const linkImage = useLinks();
  const navigate = useNavigate();
  const [input, setInput] = useState(product);
  const [image, setImage] = useState(null);
  const [errorMessage, seterrorMessage] = useState("");
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async () => {
    // if (image?.size > 2 * 1024 * 1024) {
    //   return seterrorMessage("size must be less than 2MB");
    // }
    if (input.name === "") {
      return seterrorMessage("Name is required");
    }
    const formData = new FormData();
    formData.append("id", product.id);
    formData.append("name", input.name);
    formData.append("description", input.description);
    formData.append("status", input.status);
    formData.append("file", image);
    try {
      const response = await axiosusers.post(
        `/products/update-products`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        navigate("/manager/sanpham/");
      }
    } catch (error) {
      seterrorMessage(error.response.data.message);
    }
  };
  const onChangeStatus = (value) => {
    setInput({
      ...input,
      status: value,
    });
  };
  return (
    <div>
      <FormControlProduct
        onChangeStatus={onChangeStatus}
        value={input.status}
      />

      <TextField
        sx={{ marginTop: `0.5rem` }}
        onChange={handleinput}
        type="text"
        value={input.name}
        name="name"
        error={Boolean(input.name === "")}
        helperText={input.name === "" ? "Name is required" : ""}
        label="Name"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.description}
        name="description"
        label="Description"
        size="small"
        variant="outlined"
        fullWidth
      />
      {!image && input.image !== "" && (
        <img
          src={`${linkImage}${input.image}`}
          crossOrigin="anonymous"
          style={{
            width: "200px",
            height: "200px",
            margin: "1rem 0 0 0",
          }}
        />
      )}
      <br />
      <UpdateFile handPhotoChange={(e) => setImage(e)} />
      {errorMessage && <FilledAlerts message={errorMessage} success="error" />}
      <Button
        type="submit"
        sx={{ margin: (theme) => theme.spacing(5, 0) }}
        variant="contained"
        size="large"
        fullWidth
        color="primary"
        onClick={onSubmit}
      >
        Update
      </Button>
    </div>
  );
};

export default EditProduct;
