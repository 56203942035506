import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import axiosusers from "utils/api/axiosusers";

const DoneOrders = ({ product, onToast, onCheckedChange, currentPage }) => {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("");
  const onChange = (name) => {
    setOpen(true);
    setType(name, type, product);
  };
  const handleClose = ({ message, status }) => {
    onToast({ message, status });
  };
  const handleSubmit = async () => {
    setOpen(false);
    let status = "";
    if (type === "hoàn thành trang này") {
      status = "completed";
    } else {
      status = "completedAll";
    }
    const dataSend = { product, status };
    try {
      const response = await axiosusers.post(`/orders/done-orders`, dataSend);
      if (response.status === 200) {
        handleClose({ message: response.data.message, status: true });
        onCheckedChange(currentPage);
        setOpen(false);
      }
    } catch (error) {
      console.error(error);
      handleClose({ message: error.response.data.message, status: false });
    }
  };
  return (
    <div>
      <Box
        sx={{
          maxWidth: "600px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          gap: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => onChange("hoàn thành trang này")}
        >
          hoàn thành trang này
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onChange("hoàn thành tất cả đơn hàng")}
        >
          hoàn thành tất cả đơn hàng
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Bạn có chắc {type}!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DoneOrders;
