import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BudgetsWallets from "../hooks/useBudgets";

const EditWarehouse = ({ product, gameData }) => {
  const navigate = useNavigate();

  const [input, setInput] = useState(product);
  const [errorMessage, seterrorMessage] = useState("");
  const [selectedGame, setSelectedGame] = React.useState(product.product);
  const [inputFields, setInputFields] = React.useState(product.budgets);
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeProduct = (value) => {
    setSelectedGame(value);
  };

  const onSubmit = async () => {
    let errorMessage = "";
    if (selectedGame?.id === undefined) {
      errorMessage = "Sản phẩm là bắt buộc";
    }
    if (inputFields !== undefined) {
      inputFields.forEach((item) => {
        if (item.name === "" || item.price === "") {
          errorMessage =
            "Ngân sách dữ liệu không được trống. Vui lòng kiểm tra lại";
        }
      });
    }

    if (errorMessage !== "") {
      toast.error(errorMessage, {
        position: "top-right",
      });
      return seterrorMessage(errorMessage);
    }
    const data = {
      id: product.id,
      productId: selectedGame.id,
      no: input.no,
      name: input.name,
      notes: input.notes,
      inputBudgets: inputFields,
    };

    try {
      const response = await axiosusers.post(`/thucdon/update-thucdon`, data);
      console.log(response);

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/thucdon");
        }, 500);
      }
    } catch (error) {
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);
  const handleChangeBudgets = (value) => {
    setInputFields(value);
  };
  return (
    <div>
      <TextField
        sx={{ marginTop: `0.5rem` }}
        onChange={handleinput}
        type="text"
        value={input.no}
        name="no"
        error={Boolean(input.no === "")}
        helperText={input.no === "" ? "Số thứ tự là bắt buộc" : ""}
        label="Số thứ tự"
        size="small"
        variant="outlined"
        fullWidth
      />
      <Autocomplete
        sx={{ marginTop: "0.5rem" }}
        options={gameData}
        fullWidth
        value={selectedGame}
        onChange={(event, value) => handleChangeProduct(value)}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(jsonResuls) => jsonResuls.name}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(selectedGame === null)}
            helperText={selectedGame === null ? "Sản Phẩm bắt buộc" : ""}
            label="Sản Phẩm"
            variant="outlined"
          />
        )}
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.name}
        name="name"
        label="Tên thực đơn"
        error={Boolean(input.name === "")}
        helperText={input.name === "" ? "Tên thực đơn là bắt buộc" : ""}
        size="small"
        variant="outlined"
        fullWidth
      />
      <Typography
        variant="h6"
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          marginTop: `0.5rem`,
        }}
      >
        Ngân sách:
      </Typography>
      <BudgetsWallets
        onCheckedChange={handleChangeBudgets}
        data={input.thucdonbudgets}
      />
      <TextField
        label="Ghi chú"
        multiline
        minRows="4"
        onChange={handleinput}
        name="notes"
        value={input.notes}
        sx={{ margin: (theme) => theme.spacing(2, 0, 2, 0) }}
        variant="outlined"
        fullWidth
      />
      {errorMessage && <FilledAlerts message={errorMessage} success="error" />}
      <Button
        type="submit"
        sx={{ margin: (theme) => theme.spacing(5, 0) }}
        variant="contained"
        size="large"
        fullWidth
        color="primary"
        onClick={onSubmit}
      >
        Update
      </Button>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default EditWarehouse;
