import { Box } from "@mui/material";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";
import { Tabtitle } from "components/title/Title";
import React from "react";
import AddTrahang from "./AddTrahang";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";

const index = () => {
  Tabtitle("Tạo Trả hàng");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: productsData } = useSWR(`/products/products-all`, fetchDataSwr);
  const { data: walletsData } = useSWR(`/thucdon/thucdon-all`, fetchDataSwr);
  const { data: suppliersData } = useSWR(
    `/suppliers/suppliers-all`,
    fetchDataSwr
  );
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <TypographyAddEdit name="Tạo Trả hàng" />
      <Box sx={{ marginTop: "1.5rem" }}>
        <AddTrahang
          productsData={productsData === undefined ? [] : productsData}
          walletsData={walletsData === undefined ? [] : walletsData}
          suppliersData={suppliersData === undefined ? [] : suppliersData}
        />
      </Box>
    </Box>
  );
};

export default index;
