import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";
import { ErrorMessage, Field, Form, Formik } from "formik";

import React from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import * as Yup from "yup";
const AddBudgets = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);

  const [status, setStatus] = React.useState("");

  const initialValues = {
    name: "",
    notes: "",
    price: "",
    quantity: "",
    unit: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(0, "It's too short")
      .required("Tên sản phẩm không được để trống"),
    price: Yup.number().required("Giá mua không được để trống"),
    quantity: Yup.number().required("Số lượng không được để trống"),
    unit: Yup.string().required("Đơn vị không được để trống"),
  });
  const onSubmit = async (values) => {
    setDung(true);
    seterrorMessage("");

    if (status === "") {
      setDung(false);
      return seterrorMessage("Please select status");
    }
    const data = {
      status,
      price: values.price,
      quantity: values.quantity,
      notes: values.notes,
      name: values.name,
      unit: values.unit,
    };

    try {
      const response = await axiosusers.post(
        `/ngansachkho/create-ngansachkho`,
        data
      );
      console.log(response);
      setDung(false);

      if (response.status === 200) {
        navigate("/manager/thucdonkho-chinh/");
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(error?.response?.data?.message);
      setDung(false);
    }
  };
  const onChangeStatus = (value) => {
    setStatus(value);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <FormControlProduct onChangeStatus={onChangeStatus} />

            <Field
              sx={{ marginTop: `1rem` }}
              type="text"
              as={TextField}
              name="name"
              label="Tên"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.name && props.touched.name}
              helperText={<ErrorMessage name="name" />}
            />
            <>
              <Box
                component="form"
                autoComplete="off"
                sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
              >
                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                  Đơn vị tính
                </Typography>

                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                  Giá mua
                </Typography>

                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                  Nhập số lượng
                </Typography>
              </Box>
              <Box
                component="form"
                autoComplete="off"
                sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
              >
                <Field
                  sx={{ flex: 1 }}
                  type="text"
                  as={TextField}
                  name="unit"
                  label="Đơn vị tính"
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={props.errors.unit && props.touched.unit}
                  helperText={<ErrorMessage name="unit" />}
                />
                <Field
                  sx={{ flex: 1 }}
                  type="number"
                  as={TextField}
                  name="price"
                  label="Giá mua"
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={props.errors.price && props.touched.price}
                  helperText={<ErrorMessage name="price" />}
                />

                <Field
                  sx={{ flex: 1 }}
                  type="number"
                  as={TextField}
                  name="quantity"
                  label="Nhập số lượng"
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={props.errors.quantity && props.touched.quantity}
                  helperText={<ErrorMessage name="quantity" />}
                />
              </Box>
            </>

            <Field
              sx={{ marginTop: 1 }}
              as={TextField}
              multiline
              rows={4}
              name="notes"
              label="Ghi chú"
              variant="outlined"
              size="small"
              fullWidth
            />

            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}
            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddBudgets;
