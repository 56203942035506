import { Grid, Paper } from "@mui/material";
import SearchInput from "hooks/useSearchInput";
import React from "react";

const SreachXuatkhochinh = (props) => {
  const { searchCode } = props;
  return (
    <div>
      <Grid
        container
        sx={{ width: "100%", margin: "40px auto", height: "auto" }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <SearchInput
                onCheckedChange={(e) => searchCode(e)}
                placeholder="Tìm kiếm theo đơn hàng ID"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default SreachXuatkhochinh;
