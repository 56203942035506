import React from "react";
import SearchTaoban from "./Main/SearchTaoban";
import axiosusers from "utils/api/axiosusers";
import { Box, CircularProgress } from "@mui/material";

import { Tabtitle } from "components/title/Title";
import TableDanhsachban from "./Main/TableDanhsachban";

const index = () => {
  Tabtitle("Playstation");
  const [orderNumber, setOrderNumber] = React.useState("");

  const MAX_RETRIES = 3;
  const pageSize = 10000;

  const [retryCount, setRetryCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);

  const fetchDataAll = async () => {
    try {
      const response = await axiosusers.get("/danhsachban/danhsachban", {
        params: {
          orderNumber,
          selectProduct: "Playstation",
          pageSize,
          currentPage: 1,
        },
      });
      const res = response.data;
      setProduct(res.danhsachban);

      setLoading(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("401 Unauthorized. Retrying...");
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          fetchDataAll();
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error(error);
      }
    }
  };

  React.useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [orderNumber, pageSize]);
  return (
    <div>
      <SearchTaoban searchOrderNumber={(e) => setOrderNumber(e)} />
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {loading && <TableDanhsachban product={product} />}
    </div>
  );
};

export default index;
