import React from "react";
import AddWallets from "./AddWallets";
import { Tabtitle } from "components/title/Title";
import useUserId from "hooks/useUserId";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";

const index = () => {
  Tabtitle("Create Wallets");
  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const { data: ngansachkhoData } = useSWR(
    `/ngansachkho/ngansachkho-all`,
    fetchDataSwr
  );
  return (
    <div>
      {ngansachkhoData !== undefined && (
        <AddWallets
          ngansachkhoData={ngansachkhoData === undefined ? [] : ngansachkhoData}
        />
      )}
    </div>
  );
};

export default index;
