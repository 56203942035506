import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import AutocompleteId from "hooks/useAutocomplete";
import React from "react";
import axiosusers from "utils/api/axiosusers";
import * as Yup from "yup";
import BudgetsWallets from "../hooks/useBudgets";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";
import UpdateFile from "components/Mui/Uploads/UpdateFile";
const AddWallets = ({ ngansachkhoData }) => {
  const navigate = useNavigate();
  const [games, setGames] = React.useState([]);
  const [done, setDone] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [inputGames, setInputGames] = React.useState([]);
  const [inputFields, setInputFields] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [image, setImage] = React.useState(null);
  React.useEffect(() => {
    Promise.all([axiosusers.get(`/products/products-all`)])
      .then(async (results) => {
        const [data] = results.map((res) => res.data);
        setGames(data);
      })
      .catch((error) => console.error(error));
  }, []);
  const initialValues = {
    name: "",
    no: "",
    notes: "",
  };
  const validationSchema = Yup.object().shape({
    no: Yup.string().min(0, "It's too short").required("Số thứ tự là bắt buộc"),
    name: Yup.string()
      .min(0, "It's too short")
      .required("Tên thực đơn là bắt buộc"),
    price: Yup.string()
      .min(0, "It's too short")
      .required("Giá tiền là bắt buộc"),
  });

  const onSubmit = async (values) => {
    setDone(true);
    let errorMessage = "";
    if (inputGames?.id === undefined) {
      setDone(false);
      errorMessage = "Sản phẩm không được trống";
    }
    if (inputFields.length === 0) {
      setDone(false);
      errorMessage = "Ngân sách dữ liệu không được trống";
    }
    inputFields.forEach((item) => {
      if (item.name === "" || item.price === "") {
        errorMessage =
          "Ngân sách dữ liệu không được trống. Vui lòng kiểm tra lại";
      }
    });

    if (errorMessage !== "") {
      setDone(false);
      toast.error(errorMessage, {
        position: "top-right",
      });
      return setErrorMessage(errorMessage);
    }
    const data = {
      orderNumber: values.no,
      productId: inputGames?.id,
      name: values.name,
      price: values.price,
      notes: values.notes,
      inputBudgets: inputFields,
    };
    try {
      const response = await axiosusers.post(`/wallets/create-wallets`, data);
      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/thucdonsanpham");
        }, 500);
        const formData = new FormData();

        formData.append("walletId", response.data.walletId);
        formData.append("file", image);

        try {
          const response = await axiosusers.post(
            `/wallets/wallets-photos`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
      setDone(false);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  const handleChangeGames = (value) => {
    setInputGames(value);
  };
  const handleChangeBudgets = (value) => {
    setInputFields(value);
  };
  const onChangeStatus = (value) => {
    setStatus(value);
  };
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto`, backgroundColor: `#fafafa` }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Tạo Thực Đơn
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={done ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                as={TextField}
                type="number"
                name="no"
                label="Số thứ tự"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.no && props.touched.no}
                helperText={<ErrorMessage name="no" />}
              />
              <FormControlProduct onChangeStatus={onChangeStatus} />
              <AutocompleteId
                data={games.filter((item) => item.status === status)}
                label="Sản Phẩm"
                id="Sản Phẩm"
                name="Sản Phẩm"
                size="small"
                openError={true}
                done={errorMessage && inputGames?.id === undefined}
                onCheckedChange={handleChangeGames}
                optionLabel={(label) => label.name}
              />
              <Field
                as={TextField}
                type="text"
                name="name"
                label="Tên thực đơn"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.name && props.touched.name}
                style={{ marginTop: `0.5rem` }}
                helperText={<ErrorMessage name="name" />}
              />
              <Field
                as={TextField}
                type="text"
                name="price"
                label="Giá tiền"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.price && props.touched.price}
                style={{ marginTop: `0.5rem` }}
                helperText={<ErrorMessage name="price" />}
              />
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, fontSize: "14px", marginTop: `0.5rem` }}
              >
                Ngân sách:
              </Typography>

              <BudgetsWallets
                onCheckedChange={handleChangeBudgets}
                ngansachkhoData={ngansachkhoData}
              />
              <Field
                sx={{ marginTop: `1.5rem` }}
                as={TextField}
                multiline
                minRows="4"
                name="notes"
                label="Ghi chú"
                variant="outlined"
                size="small"
                fullWidth
              />
              <UpdateFile handPhotoChange={(e) => setImage(e)} />
              {errorMessage && (
                <Alert sx={{ marginTop: `0.5rem` }} severity="error">
                  {errorMessage}
                </Alert>
              )}
              <Button
                type="submit"
                sx={{ marginTop: `2rem` }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={done}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddWallets;
