/* eslint-disable react/prop-types */
import { Alert, Stack } from "@mui/material";
import React from "react";

const FilledAlerts = (props) => {
  const { message, success } = props;
  return (
    <Stack sx={{ width: "100%", marginTop: `0.5rem` }} spacing={2}>
      <Alert severity={success} color={success}>
        {message}
      </Alert>
    </Stack>
  );
};

export default FilledAlerts;
