import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axiosusers from "utils/api/axiosusers";

const NavLinkButton = ({ href, children }) => {
  const classes = useStyles();
  const data = JSON.parse(localStorage.getItem("auth"));
  const handleNavLinkClick = () => {
    if (data?.image === undefined) {
      Promise.all([axiosusers.get(`/users/profile/${data?.id}`)]).catch(
        (error) => console.error(error)
      );
    }
  };
  return (
    <NavLink
      to={href}
      className={href === "" ? classes.link : classes.linkActive}
      onClick={() => handleNavLinkClick()}
    >
      {children}
    </NavLink>
  );
};

export default NavLinkButton;
const useStyles = makeStyles((theme) => ({
  linkActive: {
    color: "inherit",
    textDecoration: "none",
    "&.active > div": {
      color: theme.palette.primary.main,
    },
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
}));
