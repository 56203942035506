import React, { useEffect, useState } from "react";
import { differenceInMinutes, parseISO } from "date-fns";
// import VNnum2words from "vn-num2words";

const PrintSubmit = ({ orders, onTotal, maQR, dataVietQR }) => {
  const number = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 });
  const [totalSum, setTotalSum] = useState(0);
  const budgetsmayorders = orders.budgetsmayorders.filter(
    (item) => item.danhsachban.selectProduct === "Playstation"
  );

  useEffect(() => {
    let sum = orders.budgetsorders.reduce(
      (acc, item) => acc + Number(item.quantity) * Number(item.price),
      0
    );

    orders.budgetsmayorders
      .filter((item) => item.danhsachban.selectProduct === "Playstation")
      .forEach((item) => {
        const minutesDifference = differenceInMinutes(
          parseISO(item.updatedAt),
          parseISO(item.createdAt)
        );
        sum += (minutesDifference / 60) * Number(item.price);
      });

    onTotal(sum);
    setTotalSum(sum);
  }, [orders, onTotal]);
  let discountDatasTotal = 0;
  if (Number(orders.discount) !== 0) {
    discountDatasTotal =
      Math.ceil(Number(totalSum) / 100) * Number(orders.discount);
  }
  function roundCurrency(value) {
    const base = Math.floor(value / 1000);
    const remainder = value % 1000;

    if (remainder < 500) {
      return base * 1000;
    } else {
      return (base + 1) * 1000;
    }
  }
  const totalPrice = roundCurrency(
    totalSum -
      Number(orders.paidAmount) -
      discountDatasTotal +
      (orders.status !== "inProgress" ? discountDatasTotal : 0)
  );

  const renderOrderRows = (items) =>
    items.map((item, index) => (
      <React.Fragment key={index}>
        <tr>
          <td colSpan="3" className="no-padding">
            <span className="small-font">{item.budgets.name}</span>
          </td>
        </tr>
        <tr>
          <td className="dashed-border">
            <span className="small-font">{number.format(item.price)} ₫</span>
          </td>
          <td className="dashed-border text-right">
            <span className="small-font">{item.quantity}</span>
          </td>
          <td className="dashed-border text-right">
            <span className="small-font">
              {number.format(Number(item.quantity) * Number(item.price))} ₫
            </span>
          </td>
        </tr>
      </React.Fragment>
    ));

  const renderPlaystationRows = (items) =>
    items.map((item, index) => {
      const createdDate = parseISO(item.createdAt);
      const updatedDate = parseISO(item.updatedAt);
      const minutesDifference = differenceInMinutes(updatedDate, createdDate);
      const timeCreateAt = item.createdAt.split("T")[1].slice(0, 5);
      const timeUpdateAt = item.updatedAt.split("T")[1].slice(0, 5);

      return (
        <React.Fragment key={index}>
          <tr>
            <td colSpan="3" className="no-padding">
              <span className="small-font">
                Máy - {item.danhsachban.orderNumber}
              </span>
            </td>
          </tr>
          <tr>
            <td className="dashed-border">
              <span className="small-font">
                {timeCreateAt} - {timeUpdateAt}
              </span>
            </td>
            <td className="dashed-border text-right">
              <span className="small-font">{minutesDifference} phút</span>
            </td>
            <td className="dashed-border text-right">
              <span className="small-font">
                {number.format((minutesDifference / 60) * Number(item.price))} ₫
              </span>
            </td>
          </tr>
        </React.Fragment>
      );
    });

  const combinedOrders = orders.budgetsorders.reduce((acc, current) => {
    let budgetId = current.budgetId;

    // Kiểm tra xem budgetId đã tồn tại trong accumulator chưa
    if (acc[budgetId]) {
      // Ưu tiên mục có delivered == '' nếu tồn tại
      if (current.delivered === "") {
        acc[budgetId] = {
          ...current,
          quantity: acc[budgetId].quantity + current.quantity,
        };
      } else {
        // Nếu delivered != '', chỉ cộng quantity
        acc[budgetId].quantity += current.quantity;
      }
    } else {
      // Nếu chưa có, thêm mới vào accumulator
      acc[budgetId] = { ...current };
    }
    return acc;
  }, {});

  const budgetsorders = Object.values(combinedOrders);
  return (
    <div id="kv-cke-temp">
      <style>
        {`
          .printBox {
            font-family: Arial, sans-serif;
          }

          table {
            page-break-inside: auto;
            border-collapse: collapse;
          }

          table td {
            word-wrap: break-word;
            word-break: break-all;
          }

          tr {
            page-break-inside: avoid;
            page-break-after: auto;
          }

          img {
            max-width: 100%;
            height: auto;
          }

          .no-padding {
            padding-top: 0px;
            padding-bottom: 0px;
          }
          .dashed-border-main {
            border-bottom: 1px solid black;
          }
          .dashed-border {
            border-bottom: 1px dashed black;
          }

          .small-font {
            font-size: 11px;
          }

          .text-right {
            text-align: right;
          }
          
          td {
            height: auto;
            vertical-align: bottom;
            padding-top: 0.5px;
            padding-bottom: 0.5px;
          }
          .text-center {
            text-align: center;
          }
          .italic-text {
            font-style: italic;
          }
          .thank {
            font-size: 9px;
            padding-top: 15px;
          }
          .total-chu {
            font-size: 9px;
            font-style:italic;
            padding-top: 5px;
          }
        `}
      </style>
      <div className="printBox">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td className="text-center">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="small-font text-center">
                        <strong className="small-font">
                          Chi tiết đơn hàng
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="small-font text-center">
                        Coffee - PS5 - Fastfood
                      </td>
                    </tr>
                    <tr>
                      <td className="small-font text-center">
                        Phone: 0857754444
                      </td>
                    </tr>
                    <tr>
                      <td className="small-font text-center">
                        <img
                          src="/logo_kan.png"
                          alt="Logo"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table cellPadding="3" style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td className="dashed-border-main" style={{ width: "35%" }}>
                <strong>
                  <span className="small-font">Đơn giá</span>
                </strong>
              </td>
              <td
                className="dashed-border-main text-right"
                style={{ width: "30%" }}
              >
                <strong>
                  <span className="small-font">SL</span>
                </strong>
              </td>
              <td className="dashed-border-main text-right">
                <strong>
                  <span className="small-font">Thành tiền</span>
                </strong>
              </td>
            </tr>
          </tbody>
          {renderOrderRows(budgetsorders)}
          {renderPlaystationRows(budgetsmayorders)}
        </table>
        <table
          border="0"
          cellPadding="3"
          cellSpacing="0"
          style={{
            borderCollapse: "collapse",
            marginTop: "10px",
            width: "100%",
          }}
        >
          <tfoot>
            <tr>
              <td className="small-font bold-text text-left nowrap">
                Tổng tiền:
              </td>
              <td className="small-font bold-text text-right">
                {number.format(totalSum)} ₫
              </td>
            </tr>
            <tr>
              <td className="small-font bold-text text-left nowrap">
                Thanh toán phần:
              </td>
              <td className="small-font bold-text text-right">
                {number.format(orders.paidAmount)} ₫
              </td>
            </tr>
            {discountDatasTotal !== 0 && (
              <tr>
                <td className="small-font bold-text text-left nowrap">
                  Giảm giá:
                </td>
                <td className="small-font bold-text text-right">
                  {number.format(discountDatasTotal)} ₫
                </td>
              </tr>
            )}
            <tr>
              <td className="small-font bold-text text-left nowrap">
                Tổng thanh toán:
              </td>
              <td className="small-font bold-text text-right">
                {number.format(totalPrice)} ₫
              </td>
            </tr>
            <tr>
              <td colSpan="2" className="italic-text text-left total-chu">
                {/* ({VNnum2words(totalPrice)} đồng) */}
              </td>
            </tr>
          </tfoot>
        </table>
        {maQR !== "" && (
          <table style={{ marginTop: "0px", width: "100%" }}>
            <tbody>
              <tr>
                <td className="small-font text-center">
                  <img
                    style={{ width: 200, height: 200 }}
                    src={maQR}
                    rel="preload"
                    alt="qrcode"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {dataVietQR !== "" && (
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td className="text-center">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td className="small-font text-center">
                          <strong className="small-font">
                            Số tiền: {number.format(dataVietQR.amount)} ₫
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="small-font text-center">
                          Số tài khoản: {dataVietQR.accountNumber}
                        </td>
                      </tr>
                      <tr>
                        <td className="small-font text-center">
                          Chủ tài khoản: {dataVietQR.accountName}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <table style={{ marginTop: "20px", width: "100%" }}>
          <tbody>
            <tr>
              <td className="italic-text text-center thank">
                Cảm ơn và hẹn gặp lại quý khách!
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PrintSubmit;
