import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import useUserId from "hooks/useUserId";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuCheckBox from "./MenuCheckBox";
import AutocompleteId from "hooks/useAutocomplete";

const AddUsers = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [menu, setMenu] = React.useState("");
  const [usersworkplace, setUsersworkplace] = React.useState([]);
  const [input, setInput] = React.useState({
    role: "1",
  });
  const { userId } = useUserId();
  const [age, setAge] = React.useState("");
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setAge(e.target.value);
  };

  const initialValues = {
    name: "",
    email: "",
    password: "",
    salary: "0",
    bonus: "0",
    bankName: "",
    bankNumber: "",
    bankAccount: "",
    zaloId: "",
    phoneNumber: "",
    maxAbsent: "",
    amount: "",
    fixedSalary: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(0, "It's too short").required("Name is required"),
    email: Yup.string().required("Email is required"),
    // email: Yup.string().email( "Must be a valid email").required("Email is required"),
    password: Yup.string()
      .min(1, "password must be at least 6 characters")
      .required("Password is required"),
  });
  const onSubmit = async (values) => {
    setDung(true);
    if (userId.role === "Admin") {
      if (input.role === "1") {
        input.role = "";
      }
      if (input.role === "") {
        setDung(false);
        return seterrorMessage("The role not found.");
      }
      const data = {
        name: values.name,
        email: values.email,
        roles: age,
        password: values.password,
        telegramId: values.telegramId,
        menu,
        amount: values.amount,
        fixedSalary: values.fixedSalary,
        usersworkplace,
      };
      try {
        const response = await axiosusers.post(`/users/create-users`, data);
        if (response.status === 200) {
          console.log(response);
          toast.success(response.data.message, {
            position: "top-right",
          });
          setTimeout(function () {
            navigate("/manager/taikhoan");
          }, 500);
        }
      } catch (error) {
        setDung(false);
        console.log(error?.response);
        seterrorMessage(error.response.data.message);
        toast.error(error.response.data.message, {
          position: "top-right",
        });
      }
    }
  };
  const dataProduct = [
    { id: 1, name: "Coffee", price: 0 },
    { id: 2, name: "Playstation", price: 0 },
    { id: 3, name: "Fastfood", price: 0 },
    { id: 4, name: "Cake", price: 0 },
  ];
  const handleChangeMenu = (value) => {
    setMenu(value);
  };
  const handleChangeInput = (id, event) => {
    const newInputFields = menu.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setMenu(newInputFields);
  };
  const handleChangeUsers = (value) => {
    setUsersworkplace(value);
  };
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new users
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="name"
                label="Tên"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.name && props.touched.name}
                helperText={<ErrorMessage name="name" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="email"
                label="Email Address"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.email && props.touched.email}
                helperText={<ErrorMessage name="email" />}
              />
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                style={{ marginTop: `0.5rem` }}
                error={Boolean(input.role == "")}
              >
                <InputLabel>Chọn vai trò</InputLabel>
                <Select
                  value={age}
                  onChange={handleChange}
                  label="Chọn vai trò"
                  name="role"
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Staff">Staff</MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                </Select>
                {errorMessage.length > 0 && input.role == "" && (
                  <FormHelperText>Role is required</FormHelperText>
                )}
              </FormControl>
              <AutocompleteId
                multiple={true}
                data={dataProduct.filter((item) => {
                  const usersworkplaces = usersworkplace.some((row) => {
                    return row.id === item.id;
                  });
                  return !usersworkplaces;
                })}
                placeholder="Chọn một hoặc nhiều"
                label="Chọn nơi làm việc"
                id="Chọn nơi làm việc"
                name="Chọn nơi làm việc"
                size="small"
                openError={false}
                done={false}
                onCheckedChange={handleChangeUsers}
                optionLabel={(label) => label.name}
              />
              <MenuCheckBox
                data={dataProduct}
                name="Chọn sản phẩm phần trăm lợi nhuận"
                handleChangeMenu={handleChangeMenu}
              />
              {menu && (
                <>
                  <Box sx={{ display: "flex", gap: 1, marginTop: "0.5rem" }}>
                    <Typography variant="h6" sx={{ fontWeight: 500, flex: 1 }}>
                      Tên
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      phần trăm lợi nhuận
                    </Typography>
                  </Box>
                  {menu.map((item) => (
                    <Box key={item} sx={{ display: "flex" }}>
                      <Typography variant="h6" sx={{ flex: 1 }}>
                        {item.name}
                      </Typography>
                      <TextField
                        sx={{ marginTop: "0.5rem" }}
                        label="Phần trăm"
                        name="price"
                        value={item.price}
                        variant="outlined"
                        onChange={(event) => handleChangeInput(item.id, event)}
                        size="small"
                      />
                    </Box>
                  ))}
                </>
              )}

              <Field
                sx={{ marginTop: `0.5rem` }}
                type="password"
                as={TextField}
                name="password"
                label="Mật khẩu"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.password && props.touched.password}
                helperText={<ErrorMessage name="password" />}
              />

              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="telegramId"
                label="Telegram ID"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="fixedSalary"
                label="Lương cố định"
                placeholder="Tính theo tháng"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="amount"
                label="Lương nhân viên"
                placeholder="Tính theo giờ"
                variant="outlined"
                size="small"
                fullWidth
              />
              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddUsers;
