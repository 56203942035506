import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// Components
import Login from "components/login";
import Error from "components/Error";
import Dashboard from "layouts/Dashboard";
// Users
import Users from "components/Users";
import AddUsers from "components/Users/AddUsers";
import EditUsers from "components/Users/EditUsers";
// Telegram API
import TelegramApi from "components/TelegramApi";
import AddTelegramApi from "components/TelegramApi/AddTelegramApi";
import EditTelegramApi from "components/TelegramApi/EditTelegramApi";
// Products
import Product from "components/Products";
import AddProduct from "components/Products/AddProducts";
import EditProduct from "components/Products/EditProducts";
// Wallets
import Wallets from "components/Wallets";
import AddWallets from "components/Wallets/AddWallets";
import EditWallets from "components/Wallets/EditWallets";
// Clients
import Clients from "components/Clients";
import AddClient from "components/Clients/AddClient";
import EditClient from "components/Clients/EditClient";
// Transactions
import Transactions from "components/Transactions";
import AddTransaction from "components/Transactions/AddTransaction";
// Payments
import Payments from "components/Payments";
import AddPayment from "components/Payments/AddPayment";
import EditPayment from "components/Payments/EditPayment";
import DetailPayment from "components/Payments/DetailPayment";
// Orders
import Orders from "components/Orders";
import AddOrders from "components/Orders/AddOrders";
import DetailOrders from "components/Orders/DetailOrders";
import AddOrdersBan from "components/Orders/AddOrdersBan";
// Reports
import Reports from "components/Reports";
import ReportsStaff from "components/ReportsStaff";
import ReportsAdmin from "components/ReportsAdmin";
// Profiles
import Profiles from "components/Profiles";
import ProfilesClients from "components/ProfilesClients";
// Warehouse
import Warehouse from "components/Warehouse";
import AddWarehouse from "components/Warehouse/AddWarehouse";
import EditWarehouse from "components/Warehouse/EditWarehouse";
// Nhapkho, Xuatkho
import Nhapkho from "components/Nhapkho";
import AddNhapkho from "components/Nhapkho/AddNhapkho";
import EditNhapkho from "components/Nhapkho/EditNhapkho";

import Xuatkho from "components/Xuatkho";
import AddXuatkho from "components/Xuatkho/AddXuatkho";
import DetailXuatkho from "components/Xuatkho/DetailXuatkho";
// Discount
import Discount from "components/Discount";
import AddDiscount from "components/Discount/AddDiscount";
import EditDiscount from "components/Discount/EditDiscount";
// NhapKhoChinh, XuatKhoChinh
import NhapKhoChinh from "components/KhoChinh/NhapKho";
import AddNhapKhoChinh from "components/KhoChinh/NhapKho/AddNhapKho";
import DetailNhapKhoChinh from "components/KhoChinh/NhapKho/DetailNhapKho";

import TraHangChinh from "components/KhoChinh/TraHang";
import AddTraHangChinh from "components/KhoChinh/TraHang/AddTraHang";
import DetailTraHangChinh from "components/KhoChinh/TraHang/DetailTraHang";

import XuatKhoChinh from "components/KhoChinh/Xuatkho";
import DetailXuatkhochinh from "components/KhoChinh/Xuatkho/DetailXuatkhochinh";
// BudgetsChinh
import BudgetsChinh from "components/KhoChinh/Budgets";
import AddBudgetsChinh from "components/KhoChinh/Budgets/AddBudgets";
import EditBudgetsChinh from "components/KhoChinh/Budgets/EditBudgets";
// ChiPhi
import ChiPhi from "components/ChiPhiDauRa/ChiPhi";
import NhapChiPhi from "components/ChiPhiDauRa/NhapChiPhi";
import AddChiPhi from "components/ChiPhiDauRa/ChiPhi/AddChiPhi";
import EditChiPhi from "components/ChiPhiDauRa/ChiPhi/EditChiPhi";
import AddNhapChiPhi from "components/ChiPhiDauRa/NhapChiPhi/AddNhapChiPhi";
// Vouchers
import Vouchers from "components/Vouchers";
import AddVouchers from "components/Vouchers/AddVouchers";
import EditVouchers from "components/Vouchers/EditVouchers";
// Suppliers
import Suppliers from "components/Suppliers";
import AddSuppliers from "components/Suppliers/AddSuppliers";
import EditSuppliers from "components/Suppliers/EditSuppliers";
// TienLuong
import TienLuong from "components/TienLuong";
import AddTienLuong from "components/TienLuong/AddTienLuong";
import EditTienLuong from "components/TienLuong/EditTienLuong";
// Danhsachban
import Taoban from "components/Danhsachban/Taoban";
import AddTaoban from "components/Danhsachban/Taoban/AddTaoban";
import EditTaoban from "components/Danhsachban/Taoban/EditTaoban";

import Coffee from "components/Danhsachban/Coffee";
import Playstation from "components/Danhsachban/Playstation";
import Fastfood from "components/Danhsachban/Fastfood";
// CheckinCheckout
import CheckinCheckout from "components/CheckinCheckout";
// Trahang
import Trahang from "components/Trahang";
import AddTrahang from "components/Trahang/AddTrahang";
import EditTrahang from "components/Trahang/EditTrahang";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<Login />} />

          <Route path="*" element={<Error />} />
          <Route path="/" element={<Dashboard />}>
            <Route path="/manager/reports-all/" element={<Reports />} />
            <Route path="/manager/reports-staff/" element={<ReportsStaff />} />
            <Route path="/manager/reports-admin/" element={<ReportsAdmin />} />
            <Route path="/manager/sanpham" element={<Product />} />
            <Route path="/manager/thucdonsanpham/" element={<Wallets />} />
            <Route path="/manager/khachhang/" element={<Clients />} />
            <Route path="/manager/thanhtoan/" element={<Payments />} />
            <Route path="/manager/id-giaodich/" element={<Transactions />} />
            <Route path="/manager/donhang/" element={<Orders />} />
            <Route path="/manager/taikhoan" element={<Users />} />
            <Route path="/manager/telegram-api" element={<TelegramApi />} />
            <Route path="/manager/thucdon" element={<Warehouse />} />
            <Route path="/manager/nhapkho" element={<Nhapkho />} />
            <Route path="/manager/trahang" element={<Trahang />} />
            <Route path="/manager/xuatkho" element={<Xuatkho />} />
            <Route path="/manager/discount" element={<Discount />} />
            <Route path="/manager/vouchers" element={<Vouchers />} />
            <Route path="/manager/nhacungcap" element={<Suppliers />} />
            <Route path="/manager/tienluong" element={<TienLuong />} />

            <Route
              path="/manager/thucdonkho-chinh"
              element={<BudgetsChinh />}
            />
            <Route path="/manager/chi-phi" element={<ChiPhi />} />
            <Route path="/manager/nhap-chi-phi" element={<NhapChiPhi />} />

            <Route path="/manager/nhapkho-chinh" element={<NhapKhoChinh />} />
            <Route path="/manager/trahang-chinh" element={<TraHangChinh />} />
            <Route path="/manager/xuatkho-chinh" element={<XuatKhoChinh />} />
            <Route
              path="/manager/checkin-checkout"
              element={<CheckinCheckout />}
            />

            <Route path="/manager/danhsachban/taoban" element={<Taoban />} />
            <Route
              path="/manager/danhsachban/taoban/add"
              element={<AddTaoban />}
            />
            <Route
              path="/manager/danhsachban/taoban/edit/:id"
              element={<EditTaoban />}
            />
            <Route path="/manager/danhsachban/coffee" element={<Coffee />} />
            <Route
              path="/manager/danhsachban/playstation"
              element={<Playstation />}
            />
            <Route
              path="/manager/danhsachban/fastfood"
              element={<Fastfood />}
            />
            <Route path="/manager/profiles/:id" element={<Profiles />} />
            <Route path="/app/profiles/:id" element={<ProfilesClients />} />
            <Route path="/manager/sanpham/add" element={<AddProduct />} />
            <Route path="/manager/sanpham/edit/:id" element={<EditProduct />} />
            <Route
              path="/manager/thucdonsanpham/add"
              element={<AddWallets />}
            />
            <Route
              path="/manager/thucdonsanpham/edit/:id"
              element={<EditWallets />}
            />
            <Route path="/manager/khachhang/add" element={<AddClient />} />
            <Route
              path="/manager/khachhang/edit/:id"
              element={<EditClient />}
            />
            <Route path="/manager/thanhtoan/add" element={<AddPayment />} />
            <Route
              path="/manager/thanhtoan/edit/:id"
              element={<EditPayment />}
            />
            <Route path="/manager/thanhtoan/:id" element={<DetailPayment />} />
            <Route
              path="/manager/id-giaodich/add"
              element={<AddTransaction />}
            />
            <Route path="/manager/donhang/add" element={<AddOrders />} />
            <Route path="/manager/donhang/:id" element={<DetailOrders />} />
            <Route path="/manager/donhang/ban/:id" element={<AddOrdersBan />} />

            <Route path="/manager/taikhoan/add" element={<AddUsers />} />
            <Route path="/manager/taikhoan/edit/:id" element={<EditUsers />} />
            <Route
              path="/manager/telegram-api/add"
              element={<AddTelegramApi />}
            />
            <Route
              path="/manager/telegram-api/edit/:id"
              element={<EditTelegramApi />}
            />
            <Route path="/manager/thucdon/add" element={<AddWarehouse />} />
            <Route
              path="/manager/thucdon/edit/:id"
              element={<EditWarehouse />}
            />
            <Route path="/manager/nhapkho/add" element={<AddNhapkho />} />
            <Route path="/manager/trahang/add" element={<AddTrahang />} />
            <Route path="/manager/nhapkho/:id" element={<EditNhapkho />} />
            <Route path="/manager/trahang/:id" element={<EditTrahang />} />
            <Route path="/manager/xuatkho/add" element={<AddXuatkho />} />
            <Route path="/manager/xuatkho/:id" element={<DetailXuatkho />} />
            <Route path="/manager/discount/add" element={<AddDiscount />} />
            <Route
              path="/manager/discount/edit/:id"
              element={<EditDiscount />}
            />

            <Route
              path="/manager/nhapkho-chinh/Add"
              element={<AddNhapKhoChinh />}
            />
            <Route
              path="/manager/nhapkho-chinh/:id"
              element={<DetailNhapKhoChinh />}
            />
            <Route
              path="/manager/trahang-chinh/Add"
              element={<AddTraHangChinh />}
            />
            <Route
              path="/manager/trahang-chinh/:id"
              element={<DetailTraHangChinh />}
            />
            <Route
              path="/manager/xuatkho-chinh/:id"
              element={<DetailXuatkhochinh />}
            />
            <Route path="/manager/nhacungcap/add" element={<AddSuppliers />} />
            <Route
              path="/manager/nhacungcap/edit/:id"
              element={<EditSuppliers />}
            />
            <Route
              path="/manager/thucdonkho-chinh/add"
              element={<AddBudgetsChinh />}
            />
            <Route
              path="/manager/thucdonkho-chinh/edit/:id"
              element={<EditBudgetsChinh />}
            />
            <Route path="/manager/chi-phi/add" element={<AddChiPhi />} />
            <Route path="/manager/chi-phi/edit/:id" element={<EditChiPhi />} />
            <Route
              path="/manager/nhap-chi-phi/add"
              element={<AddNhapChiPhi />}
            />
            <Route path="/manager/vouchers/add" element={<AddVouchers />} />
            <Route path="/manager/vouchers/:id" element={<EditVouchers />} />
            <Route path="/manager/tienluong/add" element={<AddTienLuong />} />
            <Route
              path="/manager/tienluong/edit/:id"
              element={<EditTienLuong />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
