import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const EditClient = ({ product }) => {
  const navigate = useNavigate();
  const [input, setInput] = useState(product);
  const [errorMessage, seterrorMessage] = useState("");
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async () => {
    if (input.fullName === "") {
      return seterrorMessage("Name is required");
    }
    const data = {
      id: product.id,
      fullName: input.fullName,
      email: input.email,
      faceBookName: input.faceBookName,
      instagramUser: input.instagramUser,
      telegramUser: input.telegramUser,
      lineUser: input.lineUser,
      phoneNumber: input.phoneNumber,
      address: input.address,
      role: input.role,
    };

    try {
      const response = await axiosusers.post(`/clients/update-clients`, data);
      console.log(response);

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/khachhang");
        }, 500);
      }
    } catch (error) {
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);
  return (
    <div>
      <TextField
        sx={{ marginTop: `0.5rem` }}
        onChange={handleinput}
        type="text"
        value={input.fullName}
        name="fullName"
        error={Boolean(input.fullName === "")}
        helperText={input.fullName === "" ? "Tên khách hàng là bắt buộc" : ""}
        label="Tên khách hàng"
        size="small"
        variant="outlined"
        fullWidth
      />
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginTop: "0.5rem" }}
      >
        <InputLabel>Chọn sản phẩm</InputLabel>
        <Select
          label="Chọn sản phẩm"
          name="role"
          value={input.role}
          onChange={handleinput}
        >
          <MenuItem value="client">client</MenuItem>
          <MenuItem value="admin">admin</MenuItem>
        </Select>
      </FormControl>
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.email}
        name="email"
        label="Email Address"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.instagramUser}
        name="instagramUser"
        label="Instagram"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.faceBookName}
        name="faceBookName"
        label="FaceBook"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.telegramUser}
        name="telegramUser"
        label="Telegram"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.lineUser}
        name="lineUser"
        label="Line"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.phoneNumber}
        name="phoneNumber"
        label="Phone Number"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.address}
        name="address"
        label="Address"
        size="small"
        variant="outlined"
        fullWidth
      />
      {errorMessage && <FilledAlerts message={errorMessage} success="error" />}
      <Button
        type="submit"
        sx={{ margin: (theme) => theme.spacing(5, 0) }}
        variant="contained"
        size="large"
        fullWidth
        color="primary"
        onClick={onSubmit}
      >
        Update
      </Button>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default EditClient;
