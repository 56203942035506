import React from "react";
import EditWallets from "./EditWallets";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";
import { Box } from "@mui/material";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Update Wallets");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const { data: gameData } = useSWR(`/products/products-all`, fetchDataSwr);
  const { data: ngansachkhoData } = useSWR(
    `/ngansachkho/ngansachkho-all`,
    fetchDataSwr
  );
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <TypographyAddEdit name="Update wallet" />
        <EditWallets
          gameData={gameData === undefined ? [] : gameData}
          ngansachkhoData={ngansachkhoData === undefined ? [] : ngansachkhoData}
        />
      </Box>
    </div>
  );
};

export default index;
