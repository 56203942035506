import { Grid, Paper } from "@mui/material";
import AutocompleteSearch from "components/Mui/Autocompletes/AutocompleteSearch";
import SearchInput from "hooks/useSearchInput";
import React from "react";

const SearchTrahang = (props) => {
  const { searchCode, productsData, setSearchProduct } = props;
  return (
    <div>
      <Grid
        container
        sx={{ width: "100%", margin: "40px auto", height: "auto" }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <AutocompleteSearch
                data={productsData}
                size="large"
                label="Sản phẩm"
                onCheckedChange={(e) => setSearchProduct(e)}
                optionLabel={(label) => label.name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <SearchInput
                onCheckedChange={(e) => searchCode(e)}
                placeholder="Tìm kiếm theo tên thực đơn"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default SearchTrahang;
