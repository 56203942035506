import {
  AvatarGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import BasicPagination from "hooks/usePagination";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteOrders from "./DeleteOrders";
import HtmlTooltips from "./HtmlTooltips";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import Images from "components/Avatar/Images";
import CompleteOrders from "./CompleteOrders";
import DialogOrders from "../DetailOrders/Update/DialogOrders";
import DoneOrders from "./DoneOrders";

const TableOrders = (props) => {
  const {
    handleCurrentPage,
    product,
    currentPage,
    pageCount,
    handleCurrentDelete,
    userId,
    gamesData,
    walletsData,
    danhsachbanData,
  } = props;

  const showToast = ({ message, status }) => {
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>khách hàng</TableCell>
              <TableCell align="right">Sản phẩm</TableCell>
              <TableCell>Giá bán</TableCell>
              <TableCell>Số tiền thưởng</TableCell>
              <TableCell>Tình trạng thanh toán</TableCell>
              <TableCell>Trạng thái</TableCell>
              <TableCell>Ngày tạo</TableCell>
              <TableCell>Bản mẫu</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              var nullCreate = row.createdAt.split("T");
              var dateCreate = nullCreate[0].split("-");
              var dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];

              let status = "Chưa hoàn thành";
              if (row.status === "completed") {
                status = "Hoàn thành";
              } else if (row.status === "pending") {
                status = "Đang chờ";
              } else if (row.status === "inProgress") {
                status = "Đang xử lý";
              } else if (row.status === "done") {
                status = "Đã xong";
              }
              let paymentStatus = "Chưa thanh toán";
              if (row.paymentStatus === "paid") {
                paymentStatus = "Đã thanh toán";
              } else if (row.paymentStatus === "pending") {
                paymentStatus = "Đang chờ";
              } else if (row.paymentStatus === "unPaid") {
                paymentStatus = "Chưa thanh toán";
              } else if (row.paymentStatus === "partPaid") {
                paymentStatus = "Thanh toán một phần";
              }

              return (
                <TableRow
                  key={row.id}
                  style={{
                    background: row.client.active ? "#cddc39" : "",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <NavLink
                      style={{ color: "blue", textDecoration: "none" }}
                      to={`/manager/donhang/${row.id}`}
                    >
                      {row.id}
                    </NavLink>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.client.fullName}
                  </TableCell>
                  <TableCell>
                    <AvatarGroup max={4}>
                      {row.ordersProducts.map((item) => {
                        return (
                          <Images
                            key={row.id}
                            nameImage={item.product?.image}
                          />
                        );
                      })}
                    </AvatarGroup>
                  </TableCell>
                  <TableCell>
                    {number.format(Number(row.salePrice))} ₫
                  </TableCell>

                  <TableCell>
                    {number.format(Number(row.bonusAmount))} ₫
                  </TableCell>
                  <TableCell>{paymentStatus}</TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>{dateCreateAt}</TableCell>
                  <TableCell>
                    <HtmlTooltips text={row.text} />
                  </TableCell>
                  <TableCell size="small" align="center">
                    {row.status === "inProgress" && (
                      <DialogOrders
                        order={true}
                        gamesData={gamesData === undefined ? [] : gamesData}
                        walletsData={
                          walletsData === undefined ? [] : walletsData
                        }
                        danhsachbanData={
                          danhsachbanData === undefined ? [] : danhsachbanData
                        }
                        id={row.id}
                      />
                    )}
                    <NavLink to={`/manager/donhang/${row.id}`}>
                      <Tooltip title={"Xem chi tiết"} placement="top" arrow>
                        <IconButton color="primary">
                          <AddToQueueIcon />
                        </IconButton>
                      </Tooltip>
                    </NavLink>
                    <CompleteOrders
                      id={row.id}
                      row={row}
                      currentPage={currentPage}
                      onToast={showToast}
                      onCheckedChange={(e) => handleCurrentDelete(e)}
                      userId={userId}
                    />
                    {userId?.roles === "Admin" && row.status !== "done" && (
                      <DeleteOrders
                        id={row.id}
                        currentPage={currentPage}
                        onToast={showToast}
                        onCheckedChange={(e) => handleCurrentDelete(e)}
                      />
                    )}
                    {userId?.roles !== "Admin" &&
                      row.status === "inProgress" && (
                        <DeleteOrders
                          id={row.id}
                          currentPage={currentPage}
                          onToast={showToast}
                          onCheckedChange={(e) => handleCurrentDelete(e)}
                        />
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {userId?.roles === "Admin" && (
          <DoneOrders
            product={product}
            currentPage={currentPage}
            onToast={showToast}
            onCheckedChange={(e) => handleCurrentDelete(e)}
          />
        )}
        <BasicPagination
          onCheckedChange={(e) => handleCurrentPage(e)}
          pageCount={pageCount}
          currentPage={currentPage}
        />
      </TableContainer>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default TableOrders;
