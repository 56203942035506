import NavLinkAdd from "components/NavLinks/NavLinkAdd";
import { Tabtitle } from "components/title/Title";
import React from "react";
import SearchBudgets from "./Main/SearchBudgets";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";
import { Box, CircularProgress } from "@mui/material";
import TableBudgets from "./Main/TableBudgets";
import BasicPagination from "hooks/usePagination";

const index = () => {
  Tabtitle("Thực đơn  Chính");
  const [searchCode, setSearchCode] = React.useState("");

  const pageSize = 27;
  const MAX_RETRIES = 3;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [retryCount, setRetryCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const fetchDataAll = async () => {
    try {
      const response = await axiosusers.get("/ngansachkho/ngansachkho", {
        params: {
          searchCode,
          status: search,
          pageSize,
          currentPage: currentPage === 0 ? 1 : currentPage,
        },
      });
      const res = response.data;
      setProduct(res.ngansachkho);
      setPageCount(Math.ceil(res.pages / pageSize));
      setLoading(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("401 Unauthorized. Retrying...");
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          fetchDataAll();
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error(error);
      }
    }
  };

  React.useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [searchCode, pageSize, search, currentPage]);
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: productsData } = useSWR(`/products/products-all`, fetchDataSwr);
  return (
    <div>
      <NavLinkAdd
        href="/manager/thucdonkho-chinh/add"
        name="Tạo thực đơn chính"
      />
      <SearchBudgets
        searchCode={(e) => setSearchCode(e)}
        onChangeStatus={(e) => setSearch(e)}
        productsData={productsData === undefined ? [] : productsData}
      />
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {loading && (
        <TableBudgets product={product} handleCurrentDelete={fetchDataAll} />
      )}
      <Box sx={{ marginTop: "20px" }}>
        <BasicPagination
          onCheckedChange={(e) => setCurrentPage(e)}
          pageCount={pageCount}
          currentPage={currentPage}
        />
      </Box>
    </div>
  );
};

export default index;
