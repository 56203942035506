import { Form, Formik } from "formik";
import React from "react";
import MenuCheckBox from "components/Mui/MenuCheckBox/MenuCheckBox";
import MenuTable from "components/Orders/AddOrdersBan/hooks/MenuTable";
import { Alert, Button } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosusers from "utils/api/axiosusers";
import BudgetTachDon from "./BudgetTachDon";

const FromTachDon = ({ danhsachbanData, orders }) => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [done, setDone] = React.useState(false);
  const [loadingMenu, setLoadingMenu] = React.useState(false);
  const [menu, setMenu] = React.useState([]);
  const [may, setMay] = React.useState([]);
  const [inputFields, setInputFields] = React.useState([]);
  const initialValues = {
    price: "",
    notes: "",
    nameGame: "",
    bonusAmount: "",
  };
  const validationSchema = "";

  const dataProduct = ["Coffee", "Fastfood"];
  const handleChangeMenu = (value) => {
    setMenu(value);
    setLoadingMenu(true);
    setTimeout(() => {
      setLoadingMenu(false);
    }, 10);
  };

  const onSubmit = async () => {
    setDone(true);
    setErrorMessage("");
    let errorMessage = "";

    if (errorMessage) {
      setDone(false);
      return setErrorMessage(errorMessage);
    }

    const data = {
      may,
      id: orders.id,
      inputFields,
    };

    try {
      const response = await axiosusers.post(`/orders/tach-orders`, data);

      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          window.location.reload();
        }, 500);
        const dataMessages = {
          message: `${response.data.text}`,
          orderId: response.data.orderId,
        };
        await axiosusers.post(`/telegramApi/api-orders`, dataMessages);
      }
    } catch (error) {
      setDone(false);
      console.log(error);
      toast.error("Update error", {
        position: "top-right",
      });
    }
  };
  const onChangeInputFields = (newInputFields) => {
    setInputFields(newInputFields);
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={done ? null : onSubmit}
      >
        {() => (
          <Form autoComplete="off">
            <MenuCheckBox
              data={dataProduct}
              name="Chọn sản phẩm"
              handleChangeMenu={handleChangeMenu}
            />
            {menu.length > 0 && !loadingMenu && (
              <MenuTable
                menu={menu}
                danhsachbanData={danhsachbanData}
                onChangePlaystation={(e) => setMay(e)}
              />
            )}
            <BudgetTachDon
              orders={orders}
              onChangeInputFields={onChangeInputFields}
            />
            {errorMessage && (
              <Alert sx={{ marginTop: `0.5rem` }} severity="error">
                {errorMessage}
              </Alert>
            )}
            <Button
              type="submit"
              sx={{ marginTop: `2rem` }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={done}
            >
              Tách đơn
            </Button>
          </Form>
        )}
      </Formik>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default FromTachDon;
