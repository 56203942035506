import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import axiosusers from "utils/api/axiosusers";
import useDecodedToken from "utils/token/useDecodedToken";

const Profile = (props) => {
  const [users, setUsers] = useState({
    fullName: "",
    address: "",
    email: "",
    phoneNumber: "",
  });
  React.useEffect(() => {
    setUsers(props.users);
  }, [props]);

  const { decodedToken } = useDecodedToken();

  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal, open, message } = state;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };
  const handleChange = (e) => {
    setUsers({
      ...users,
      [e.target.name]: e.target.value,
    });
  };
  const [avatar, setAvatar] = useState("");
  const viewAvatar = (e) => {
    const file = e.target.files[0];
    file.preview = URL.createObjectURL(file);
    setAvatar(file);
  };
  const handleClick = async () => {
    const newState = {
      vertical: "bottom",
      horizontal: "center",
      message: "Update profile successfully!",
    };

    const formData = new FormData();
    formData.append("id", users.id);
    formData.append("fullName", users.fullName);
    formData.append("email", users.email);
    formData.append("phoneNumber", users.phoneNumber);
    formData.append("address", users.address);
    formData.append("file", avatar);
    formData.append("jti", decodedToken?.jti);
    try {
      const response = await axiosusers.post(
        `/authclients/update-profiles-client`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      localStorage.setItem("accessToken", response.data.accessTokenClient);
      localStorage.setItem("refreshToken", response.data.refreshTokenClient);
      localStorage.setItem("auth", JSON.stringify(response.data.existingUser));

      window.location.reload();
      if (response.status === 200) {
        setState({ open: true, ...newState });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Box sx={{ padding: "16px" }}>
        <Typography variant="h5" style={{ fontWeight: 500, fontSize: "16px" }}>
          Profile
        </Typography>
        <Typography
          variant="h5"
          style={{
            fontWeight: 400,
            color: "#546e7a",
            fontSize: "16px",
            marginTop: "2px",
          }}
        >
          Update Profile
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{ display: "flex", margin: "20px auto", justifyContent: "center" }}
      >
        {avatar.length != "" && (
          <Avatar
            src={avatar.preview}
            sx={{
              width: (theme) => theme.spacing(25),
              height: (theme) => theme.spacing(25),
            }}
          />
        )}
        {avatar.length == "" && users.image == "" && (
          <Avatar
            sx={{
              width: (theme) => theme.spacing(25),
              height: (theme) => theme.spacing(25),
            }}
          />
        )}
        {avatar.length == "" && users.image !== "" && (
          <Box
            sx={{
              width: (theme) => theme.spacing(25),
              height: (theme) => theme.spacing(25),
              display: "flex",
              overflow: "hidden",
              position: "relative",
              fontSize: "1rem",
              alignItems: "center",
              lineHeight: "1",
              userSelect: "none",
              borderRadius: "50%",
              justifyContent: "center",
            }}
          >
            <img
              // eslint-disable-next-line no-undef
              src={`${process.env.REACT_APP_NOT_SECRET_CODE}images/${users.image}`}
              crossOrigin="anonymous"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                verticalAlign: "middle",
                borderStyle: "none",
              }}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{ display: "flex", margin: "20px auto", justifyContent: "center" }}
      >
        <input
          accept="image/*"
          style={{
            display: "none",
            color: "#3f50b5",
            margin: (theme) => theme.spacing(2, 0),
          }}
          onChange={viewAvatar}
          id="file"
          name="file"
          multiple
          type="file"
        />
        <label htmlFor="file">
          <Button component="span" color="primary">
            UPLOAD PHOTOS
          </Button>
        </label>
      </Box>
      <Box sx={{ padding: (theme) => theme.spacing(0, 2, 0, 2) }}>
        <form autoComplete="off">
          <TextField
            sx={{ margin: (theme) => theme.spacing(1, 0) }}
            value={users.fullName}
            type="text"
            name="fullName"
            label="Họ và tên"
            size="small"
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
          <TextField
            sx={{ margin: (theme) => theme.spacing(1, 0) }}
            value={users.email}
            type="text"
            name="email"
            disabled
            label="Email"
            onChange={handleChange}
            size="small"
            variant="outlined"
            fullWidth
          />
          <TextField
            sx={{ margin: (theme) => theme.spacing(1, 0) }}
            value={users.phoneNumber}
            type="text"
            name="phoneNumber"
            label="Số điện thoại"
            onChange={handleChange}
            size="small"
            variant="outlined"
            fullWidth
          />
          <TextField
            sx={{ margin: (theme) => theme.spacing(1, 0) }}
            value={users.address}
            type="text"
            name="address"
            label="Địa chỉ"
            onChange={handleChange}
            size="small"
            variant="outlined"
            fullWidth
          />
        </form>
      </Box>
      <Box
        sx={{
          textAlign: "right",
          padding: (theme) => theme.spacing(3, 2, 2, 2),
        }}
      >
        <Button
          onClick={handleClick}
          type="submit"
          variant="contained"
          color="primary"
        >
          UPDATE
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        key={vertical + horizontal}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Profile;
