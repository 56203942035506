import { Box, TextField, Typography } from "@mui/material";
import { FormAutocomplete } from "hooks/FormAutocomplete";
import React from "react";
const BudgetsWalletsOrders = (props) => {
  const { onCheckedChangeBudgets, wallets, setInputWallet } = props;
  const [fieldValueWallet, setFieldValueWallet] = React.useState([]);
  const [valueWallet, setValueWallet] = React.useState(wallets);

  const setChangeWallets = (values) => {
    setInputWallet(values);
    const valueIds = values.map((item) => item.id);

    const updatedValueWallet = values.map((item) => ({
      ...item,
      budgets:
        valueWallet.find((field) => field.id === item.id)?.budgets ||
        item.budgets,
    }));

    const walletsDataFiltered = valueWallet
      .filter((item) => !valueIds.includes(item.id))
      .map((item) => ({
        ...item,
        budgets: item.budgets.map((budget) => ({ ...budget, usedQuantity: 0 })),
      }));

    const newWalletData = [...updatedValueWallet, ...walletsDataFiltered];

    setValueWallet(newWalletData);
    onCheckedChangeBudgets(updatedValueWallet);
    setFieldValueWallet(updatedValueWallet);
  };

  const handleChangeInput = (id, event) => {
    const { name, value } = event.target;

    const newInputFields = fieldValueWallet.map((item) => ({
      ...item,
      budgets: item.budgets.map((budget) =>
        budget.id === id ? { ...budget, [name]: value } : budget
      ),
    }));

    const updatedValueWallet = valueWallet.map((item) => ({
      ...item,
      budgets:
        newInputFields.find((field) => field.id === item.id)?.budgets ||
        item.budgets,
    }));

    setValueWallet(updatedValueWallet);
    onCheckedChangeBudgets(newInputFields);
    setFieldValueWallet(newInputFields);
  };

  return (
    <div>
      {valueWallet.length > 0 && (
        <FormAutocomplete
          placeholder="Chọn một hoặc nhiều"
          onChange={(event, values) => {
            setChangeWallets(values);
          }}
          multiple={true}
          filterSelectedOptions={true}
          style={{ marginTop: `0.5rem` }}
          name="Chọn thực đơn kho"
          label="Chọn thực đơn kho"
          options={valueWallet}
        />
      )}
      {fieldValueWallet.map((inputField) => (
        <Box
          key={inputField.id}
          component="form"
          autoComplete="off"
          sx={{ marginTop: "0.5rem" }}
        >
          <Typography variant="h6" sx={{ fontWeight: 500, fontSize: "1rem" }}>
            Tên thực đơn: {inputField.name}
          </Typography>
          <Box
            component="form"
            autoComplete="off"
            sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0 }}
          >
            <Typography variant="subtitle1" sx={{ flex: 1 }}>
              Tên ngân sách
            </Typography>
            <Typography variant="subtitle1" sx={{ flex: 1 }}>
              Đơn vị tính
            </Typography>
            <Typography variant="subtitle1" sx={{ flex: 1 }}>
              Giá mua
            </Typography>
            <Typography variant="subtitle1" sx={{ flex: 1 }}>
              Nhập số lượng
            </Typography>
          </Box>
          {inputField.budgets.map((budget) => (
            <Box
              key={budget.id}
              component="form"
              autoComplete="off"
              sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
            >
              <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                {budget.name}
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                {budget.unit}
              </Typography>
              <TextField
                fullWidth
                type="number"
                name="price"
                value={budget.price}
                onChange={(event) => handleChangeInput(budget.id, event)}
                label="Giá mua"
                variant="outlined"
                size="small"
                sx={{ flex: 1 }}
              />

              <TextField
                fullWidth
                type="number"
                name="usedQuantity"
                value={budget.usedQuantity}
                onChange={(event) => handleChangeInput(budget.id, event)}
                label="Nhập số lượng"
                variant="outlined"
                size="small"
                sx={{ flex: 1 }}
              />
            </Box>
          ))}
        </Box>
      ))}
    </div>
  );
};

export default BudgetsWalletsOrders;
