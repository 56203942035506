import { FormControl, InputLabel, Select } from "@material-ui/core";
import { Grid, MenuItem, Paper } from "@mui/material";
import AutocompleteSearch from "components/Mui/Autocompletes/AutocompleteSearch";
import SearchInput from "hooks/useSearchInput";
import React from "react";
import styles from "./style.module.css";
const SearchOrders = (props) => {
  const {
    gameData,
    onSearchGame,
    onSearchId,
    setSearchStatus,
    clientData,
    onSearchClientGame,
    setSearchPaymentStatus,
  } = props;

  return (
    <div>
      <Grid
        container
        sx={{ width: "100%", margin: "40px auto", height: "auto" }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <div className={styles.container}>
            <div className={styles.item}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Trạng Thái</InputLabel>
                <Select
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                  }}
                  label="Trạng Thái"
                  name="status"
                >
                  <MenuItem value="none">Không</MenuItem>
                  <MenuItem value="muave">Mua về</MenuItem>
                  <MenuItem value="inProgress">Đang xử lý</MenuItem>
                  <MenuItem value="completed">Hoàn thành</MenuItem>
                  <MenuItem value="done">Xong</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.item}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Tình trạng thanh toán</InputLabel>
                <Select
                  onChange={(e) => {
                    setSearchPaymentStatus(e.target.value);
                  }}
                  label="Tình trạng thanh toán"
                  name="status"
                >
                  <MenuItem value="none">Không</MenuItem>
                  <MenuItem value="paid">Trả</MenuItem>
                  <MenuItem value="unPaid">Chưa thanh toán</MenuItem>
                  <MenuItem value="partPaid">Đã trả một phần</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.item}>
              <AutocompleteSearch
                data={clientData}
                size="large"
                label="Khách hàng"
                onCheckedChange={(e) => onSearchClientGame(e)}
                optionLabel={(label) => label.fullName}
              />
            </div>
            <div className={styles.item}>
              <AutocompleteSearch
                data={gameData}
                size="large"
                label="Sản phẩm"
                onCheckedChange={(e) => onSearchGame(e)}
                optionLabel={(label) => label.name}
              />
            </div>
            <div className={styles.item}>
              <SearchInput
                onCheckedChange={(e) => onSearchId(e)}
                placeholder="Search by order id"
              />
            </div>
          </div>
        </Paper>
      </Grid>
    </div>
  );
};

export default SearchOrders;
