import { Box, Grid, Paper, TextField } from "@mui/material";
import AutocompleteSearch from "components/Mui/Autocompletes/AutocompleteSearch";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";
import { InputLabel, MenuItem, Select, FormControl } from "@material-ui/core";
const SearchReport = (props) => {
  const { userData, onSearchUser, onChangeData, setStatus, setSearchStatus } =
    props;
  const [value, setValue] = React.useState([null, null]);
  const onChangeDate = (newValue) => {
    setValue(newValue);
    onChangeData(newValue);
  };
  const filteredUsers = userData.filter((user) => user.usersProfit.length > 0);
  const onChangeStatus = (value) => {
    setStatus(value);
  };
  return (
    <div>
      <Grid
        container
        sx={{ width: "100%", margin: "40px auto", height: "auto" }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Trạng Thái</InputLabel>
                <Select
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                  }}
                  label="Trạng Thái"
                  name="status"
                >
                  <MenuItem value="none">Không</MenuItem>
                  <MenuItem value="done">Xong</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControlProduct size={true} onChangeStatus={onChangeStatus} />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <AutocompleteSearch
                data={filteredUsers}
                size="large"
                label="Chọn người dùng"
                onCheckedChange={(e) => onSearchUser(e)}
                optionLabel={(label) => label.name}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  value={value}
                  inputFormat="dd/MM/yyyy"
                  onChange={onChangeDate}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField
                        {...startProps}
                        helperText="dd/mm/yyyy"
                        label="from"
                        variant="outlined"
                      />
                      <Box style={{ margin: "13px" }}> to </Box>
                      <TextField
                        {...endProps}
                        helperText="dd/mm/yyyy"
                        label="to"
                        variant="outlined"
                      />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default SearchReport;
