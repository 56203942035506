import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosusers from "utils/api/axiosusers";
// import DoneIcon from "@mui/icons-material/Done";
const InputBudget = ({ id, orders }) => {
  const [open, setOpen] = useState(false);
  const idProductRef = useRef();
  const idProductRefStatus = useRef();
  const [message, setMessage] = useState("");
  const [budgetsorders, setBudgetsorders] = useState([]);
  const [usedQuantity, setUsedQuantity] = useState({
    usedQuantity: "",
  });
  const handleinput = (e) => {
    console.log(Number(e.target.value));
    if (Number(e.target.value) > budgetsorders.quantity) {
      setMessage("Số lượng không được lớn hơn số lượng cần giao");
    } else if (Number(e.target.value) < 0) {
      setMessage("Số lượng không được nhỏ hơn 0");
    } else if (Number(e.target.value) === 0) {
      setMessage("Số lượng không được bằng 0");
    } else {
      setMessage("");
    }
    setUsedQuantity(e.target.value);
  };
  const handleClickOpen = (id, name) => {
    setMessage("");
    setBudgetsorders(orders.budgetsorders.find((item) => item.id === id));
    setOpen(true);
    idProductRef.current = id;
    idProductRefStatus.current = name;
  };
  const handleDelete = async () => {
    setOpen(false);
    if (message) {
      setOpen(true);
      return;
    }
    const dataSend = {
      id: idProductRef.current,
      orders,
      usedQuantity,
      budgetsorders,
    };
    try {
      const response = await axiosusers.post(
        `/budgets/${idProductRefStatus.current}-budgetsorders`,
        dataSend
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      {/* <Tooltip title={"Giao hàng"} placement="top" arrow>
        <IconButton
          color="primary"
          onClick={() => handleClickOpen(id, "giaohang")}
        >
          <DoneIcon />
        </IconButton>
      </Tooltip> */}
      <Tooltip title={"Hủy đơn"} placement="top" arrow>
        <IconButton
          color="primary"
          onClick={() => handleClickOpen(id, "delete")}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {idProductRefStatus.current === "delete" &&
              "Bạn có chắc là muốn hủy đơn này không?"}
            {idProductRefStatus.current === "giaohang" && (
              <>
                <Box>
                  <Typography variant="body1" sx={{ padding: "10px 0px" }}>
                    Tên: {budgetsorders?.budgets?.name}
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ padding: "10px 0px" }}>
                  Số lượng: {budgetsorders?.quantity}
                </Typography>
                <TextField
                  sx={{ marginTop: `0.5rem` }}
                  onChange={handleinput}
                  type="number"
                  name="usedQuantity"
                  error={message}
                  helperText={message}
                  label="Nhập số lượng"
                  variant="outlined"
                  fullWidth
                />
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InputBudget;
