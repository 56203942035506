import { Grid, Typography } from "@mui/material";
import React from "react";

const GridDetailPayment = (props) => {
  const { name, amount } = props;
  return (
    <Grid container spacing={0}>
      <Grid>
        <Typography
          color="primary"
          sx={{
            fontWeight: 500,
            fontSize: "16px",
            margin: (theme) => theme.spacing(1, 0),
            textAlign: "center",
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            margin: (theme) => theme.spacing(1, 3),
            textAlign: "center",
          }}
        >
          {amount}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GridDetailPayment;
