import { Box, CircularProgress } from "@mui/material";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";
import { Tabtitle } from "components/title/Title";
import React, { useEffect, useState } from "react";
import DetaiPayment from "./DetaiPayment";
import axiosusers from "utils/api/axiosusers";
import { useParams } from "react-router-dom";
import TablePayment from "./TablePayment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const MAX_RETRIES = 3;
const index = () => {
  Tabtitle("Chi tiết thanh toán");
  const userId = JSON.parse(localStorage.getItem("auth"));

  const { id } = useParams();
  const [retryCount, setRetryCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const fetchDataAll = async () => {
    Promise.all([axiosusers.get(`/payments/payments/${id}`)])
      .then(async (results) => {
        const [response] = results.map((res) => res.data);
        setProduct(response);
      })
      .finally(() => setLoading(true))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("401 Unauthorized. Retrying...");
          if (retryCount < MAX_RETRIES) {
            setRetryCount(retryCount + 1);
            fetchDataAll();
          } else {
            console.log("Max retries reached.");
          }
        } else {
          console.error(error);
        }
      });
  };

  useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);

    return () => clearTimeout(delayFetch);
  }, [id]);
  const showToast = ({ message, status }) => {
    fetchDataAll();

    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  return (
    <Box sx={{ minHeight: `80vh` }}>
      <Box sx={{ maxWidth: 300, margin: `auto`, backgroundColor: `#fafafa` }}>
        <TypographyAddEdit name="Chi tiết thanh toán" />
        {!loading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        <Box sx={{ marginTop: "1.5rem" }}>
          {loading && (
            <DetaiPayment
              product={product}
              userId={userId}
              onToast={showToast}
            />
          )}
        </Box>
      </Box>
      {loading && product && product.withdrawTransactions.length > 0 && (
        <TablePayment product={product} onToast={showToast} />
      )}
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </Box>
  );
};

export default index;
