import NavLinkAdd from "components/NavLinks/NavLinkAdd";
import { Tabtitle } from "components/title/Title";
import React from "react";
import SearchChiphi from "./Main/SearchChiphi";
import axiosusers from "utils/api/axiosusers";
import { Box } from "@mui/material";
import BasicPagination from "hooks/usePagination";
import TableNhapChiphi from "./Main/TableNhapChiphi";
import useSWR from "swr";

const index = () => {
  Tabtitle("Nhập Chi Phí");

  const MAX_RETRIES = 3;
  const pageSize = 10;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [retryCount, setRetryCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);
  const [searchChiphi, setSearchChiphi] = React.useState([]);

  const fetchDataAll = async () => {
    try {
      const response = await axiosusers.get("/nhapchiphi/nhapchiphi", {
        params: {
          searchChiphiId: searchChiphi?.id,
          pageSize,
          currentPage: currentPage === 0 ? 1 : currentPage,
        },
      });
      const res = response.data;
      setProduct(res.nhapchiphi);
      setPageCount(Math.ceil(res.pages / pageSize));
      setLoading(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("401 Unauthorized. Retrying...");
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          fetchDataAll();
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error(error);
      }
    }
  };

  React.useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [searchChiphi, pageSize, currentPage]);

  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: chiphiData } = useSWR(`/chiphi/chiphi-all`, fetchDataSwr);
  return (
    <div>
      <SearchChiphi
        setSearchChiphi={(e) => setSearchChiphi(e)}
        chiphiData={chiphiData === undefined ? [] : chiphiData}
      />
      <NavLinkAdd href="/manager/nhap-chi-phi/add" name="Tạo Nhập Chi Phí" />
      {loading && (
        <TableNhapChiphi product={product} handleCurrentDelete={fetchDataAll} />
      )}
      <Box sx={{ marginTop: "20px" }}>
        <BasicPagination
          onCheckedChange={(e) => setCurrentPage(e)}
          pageCount={pageCount}
          currentPage={currentPage}
        />
      </Box>
    </div>
  );
};

export default index;
