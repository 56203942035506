import { Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import * as Yup from "yup";
const AddChiPhi = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const initialValues = {
    name: "",
    notes: "",
    price: 1,
    unit: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(0, "It's too short")
      .required("Tên sản phẩm không được để trống"),
  });
  const onSubmit = async (values) => {
    setDung(true);
    seterrorMessage("");
    // if (image?.size > 2 * 1024 * 1024) {
    //   setDung(false);
    //   return seterrorMessage("size must be less than 2MB");
    // }
    if (status === "") {
      setDung(false);
      return seterrorMessage("Please select status");
    }
    const data = {
      name: values.name,
      notes: values.notes,
      price: values.price,
      unit: values.unit,
      status: status,
    };

    try {
      const response = await axiosusers.post(`/chiphi/create-chiphi`, data);
      console.log(response);
      setDung(false);

      if (response.status === 200) {
        navigate("/manager/chi-phi/");
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(error?.response?.data?.message);
      setDung(false);
    }
  };
  const onChangeStatus = (value) => {
    setStatus(value);
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <FormControlProduct onChangeStatus={onChangeStatus} />

            <Field
              sx={{ marginTop: 1 }}
              as={TextField}
              name="name"
              label="Tên sản phẩm"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.name && props.touched.name}
              helperText={<ErrorMessage name="name" />}
            />
            <Field
              sx={{ marginTop: 1 }}
              type="number"
              as={TextField}
              name="price"
              label="Giá mặc định"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: 1 }}
              as={TextField}
              name="unit"
              label="Đơn vị tính"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: 1 }}
              as={TextField}
              multiline
              rows={4}
              name="notes"
              label="Ghi chú"
              variant="outlined"
              size="small"
              fullWidth
            />
            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}
            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddChiPhi;
