import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const TotalPayment = ({ product }) => {
  const filteredItems = product.filter((row) => row.status !== "limit_180_day");

  const { balancep, onholdp } = filteredItems.reduce(
    (acc, item) => {
      const balanceMultiplier = item.currency === "USD" ? 22200 : 1;

      const balancep1 = Number(item.balance) * balanceMultiplier;
      const onholdp1 = Number(item.onHoldBalance) * balanceMultiplier;

      return {
        balancep: Number(acc.balancep) + balancep1,
        onholdp: Number(acc.onholdp) + onholdp1,
      };
    },
    { balancep: 0, onholdp: 0 }
  );
  const totalWithdrawAmount = product.reduce((total, item) => {
    const withdrawTotal = item.withdrawTransactions.reduce(
      (sum, transaction) => sum + Number(transaction.amount),
      0
    );
    return total + withdrawTotal;
  }, 0);
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" }, margin: "30px 0px" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(2, 2),
                  color: "rgb(63, 80, 181)",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(2, 2),
                  color: "rgb(63, 80, 181)",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                }}
              >
                Total Balance
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(2, 2),
                  color: "rgb(63, 80, 181)",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                }}
              >
                Total On Hold
              </TableCell>

              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(2, 2),
                  color: "rgb(63, 80, 181)",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                }}
              >
                Total Withdraw
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(2, 2),
                }}
              >
                {number.format(balancep + onholdp)} ₫
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(2, 2),
                }}
              >
                {number.format(balancep)} ₫
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(2, 2),
                }}
              >
                {number.format(onholdp)} ₫
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(2, 2),
                }}
              >
                {number.format(totalWithdrawAmount)} ₫
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TotalPayment;
