import NavLinkAdd from "components/NavLinks/NavLinkAdd";
import { Tabtitle } from "components/title/Title";
import React from "react";
import SearchChiphi from "./Main/SearchChiphi";
import axiosusers from "utils/api/axiosusers";
import { Box } from "@mui/material";
import BasicPagination from "hooks/usePagination";
import TableChiphi from "./Main/TableChiphi";

const index = () => {
  Tabtitle("Chi Phí");
  const [searchName, setSearchName] = React.useState("");
  const MAX_RETRIES = 3;
  const pageSize = 10;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [retryCount, setRetryCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);
  const [searchStatus, setSearchStatus] = React.useState("");

  const fetchDataAll = async () => {
    try {
      const response = await axiosusers.get("/chiphi/chiphi", {
        params: {
          searchName,
          searchStatus,
          pageSize,
          currentPage: currentPage === 0 ? 1 : currentPage,
        },
      });
      const res = response.data;
      setProduct(res.chiphi);
      setPageCount(Math.ceil(res.pages / pageSize));
      setLoading(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("401 Unauthorized. Retrying...");
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          fetchDataAll();
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error(error);
      }
    }
  };

  React.useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [searchName, searchStatus, pageSize, currentPage]);
  const onChangeStatus = (value) => {
    setSearchStatus(value);
  };
  return (
    <div>
      <SearchChiphi
        searchName={(e) => setSearchName(e)}
        onChangeStatus={onChangeStatus}
      />
      <NavLinkAdd href="/manager/chi-phi/add" name="Tạo Chi Phí" />
      {loading && (
        <TableChiphi product={product} handleCurrentDelete={fetchDataAll} />
      )}
      <Box sx={{ marginTop: "20px" }}>
        <BasicPagination
          onCheckedChange={(e) => setCurrentPage(e)}
          pageCount={pageCount}
          currentPage={currentPage}
        />
      </Box>
    </div>
  );
};

export default index;
