import { Box, TextField, Typography } from "@mui/material";
import AutocompleteId from "hooks/useAutocomplete";
import React from "react";

const MenuTable = (props) => {
  const { menu, danhsachbanData, onChangePlaystation } = props;
  const [fieldValue, setFieldValue] = React.useState([]);
  const handleChange = (value) => {
    const result = value.map((item) => ({
      ...item,
    }));
    onChangePlaystation(result);
    setFieldValue(result);
  };
  const handleChangeInput = (id, event) => {
    const { name, value } = event.target;
    const newInputFields = fieldValue.map((i) => {
      if (id === i.id) {
        i[name] = value;
      }
      return i;
    });
    onChangePlaystation(newInputFields);
    setFieldValue(newInputFields);
  };
  return (
    <>
      {menu.includes("Playstation") ? (
        <AutocompleteId
          multiple={true}
          data={danhsachbanData.filter(
            (item) => item.selectProduct === "Playstation"
          )}
          placeholder="Chọn một hoặc nhiều"
          label="Chọn máy"
          id="Chọn máy"
          name="Chọn máy"
          size="small"
          openError={false}
          done={false}
          onCheckedChange={handleChange}
          optionLabel={(label) => `Máy - ${label.orderNumber}`}
        />
      ) : (
        <AutocompleteId
          multiple={true}
          data={danhsachbanData.filter(
            (item) => item.selectProduct !== "Playstation"
          )}
          placeholder="Chọn một hoặc nhiều"
          label="Chọn bàn"
          id="Chọn bàn"
          name="Chọn bàn"
          size="small"
          openError={false}
          done={false}
          onCheckedChange={handleChange}
          optionLabel={(label) => `Bàn - ${label.orderNumber}`}
        />
      )}
      {menu.includes("Playstation") &&
        fieldValue.map((inputField) => (
          <Box
            key={inputField.id}
            component="form"
            autoComplete="off"
            sx={{ marginTop: "0.5rem" }}
          >
            <Box
              component="form"
              autoComplete="off"
              sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0 }}
            >
              <Typography variant="subtitle1" sx={{ flex: 1, fontWeight: 500 }}>
                Tên máy
              </Typography>

              <Typography variant="subtitle1" sx={{ flex: 1, fontWeight: 500 }}>
                Giá 1h=20,000
              </Typography>
            </Box>
            <Box
              component="form"
              autoComplete="off"
              sx={{ display: "flex", gap: 1, mb: 1 }}
            >
              <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                Máy {inputField.orderNumber}
              </Typography>

              <TextField
                fullWidth
                type="number"
                name="usedPrice"
                value={inputField.usedPrice}
                onChange={(event) => handleChangeInput(inputField.id, event)}
                label="Nhập giá"
                variant="outlined"
                size="small"
                sx={{ flex: 1 }}
              />
            </Box>
          </Box>
        ))}
    </>
  );
};

export default MenuTable;
