import { Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import React, { useState } from "react";
import axiosusers from "utils/api/axiosusers";

import GridDetailPayment from "./GridDetailPayment";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
const DetaiPayment = ({ product, userId, onToast }) => {
  if (userId && userId.role !== "Admin") {
    window.location.href = "/login";
  }
  const handleClose = ({ message, status }) => {
    onToast({ message, status });
  };
  const [dung, setDung] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const initialValues = {
    withdraw: "",
  };
  const validationSchema = Yup.object().shape({
    withdraw: Yup.number()
      .min(0.00000001, "Số tiền rút không được nhỏ hơn 0")
      .max(product.balance, "Số tiền rút không được lớn hơn số dư hiện tại")
      .required("Số tiền rút là bắt buộc"),
  });
  const onSubmit = async (values, props) => {
    setDung(true);
    if (Number(product.balance) === 0) {
      setDung(false);
      seterrorMessage("Số dư của bạn hiện tại là 0");
      handleClose({ message: "Số dư của bạn hiện tại là 0", status: false });
      return;
    }
    const data = {
      paymentId: product.id,
      amount: values.withdraw,
      balance: Number(product.balance) - Number(values.withdraw),
      status: "in-progress",
      userId: userId.id,
    };

    try {
      const response = await axiosusers.post(
        `/payments/create-withdraws`,
        data
      );
      console.log(response);

      if (response.status === 200) {
        setDung(false);
        handleClose({ message: response.data.message, status: true });
        props.resetForm();
      }
    } catch (error) {
      console.log(error?.response);
      setDung(false);
      seterrorMessage(error.response.data.message);
      handleClose({ message: error.response.data.message, status: false });
    }
  };

  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const numberthem = new Intl.NumberFormat("en-NZ", {
    minimumFractionDigits: 2,
  });
  const amountBalance =
    product.currency === "USD"
      ? `${numberthem
          .format(Math.round(product.balance * 100) / 100)
          .replace(".", ",")} $`
      : `${number.format(product.balance)} ₫`;
  const amountOnHold =
    product.currency === "USD"
      ? `${numberthem
          .format(Math.round(product.onHoldBalance * 100) / 100)
          .replace(".", ",")} $`
      : `${number.format(product.onHoldBalance)} ₫`;
  const balanceOnHold = Number(
    Number(product.onHoldBalance) + Number(product.balance)
  );
  const total =
    product.currency === "USD"
      ? `${numberthem
          .format(Math.round(balanceOnHold * 100) / 100)
          .replace(".", ",")} $`
      : `${number.format(balanceOnHold)} ₫`;
  return (
    <div>
      <GridDetailPayment name="Sự cân bằng:" amount={amountBalance} />
      <GridDetailPayment name="Số dư đang giữ:" amount={amountOnHold} />
      <GridDetailPayment name="Tổng số dư:" amount={total} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={dung ? null : onSubmit}
      >
        {(props) => (
          <Form>
            <Field
              sx={{ marginTop: `2rem` }}
              as={TextField}
              name="withdraw"
              label="Số tiền rút là bắt buộc"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.withdraw && props.touched.withdraw}
              helperText={<ErrorMessage name="withdraw" />}
            />

            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}
            <Button
              disabled={Number(product.balance) === 0 ? true : ""}
              type="submit"
              sx={{ margin: (theme) => theme.spacing(2, 0) }}
              variant="contained"
              size="large"
              color="primary"
            >
              Rút
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DetaiPayment;
