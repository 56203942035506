import {
  Accordion,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteTransaction from "./DeleteWarehouse";
import NavLinkButton from "components/NavLinks/NavLinkButton";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const TableBudgets = (props) => {
  const { product, handleCurrentDelete } = props;
  const showToast = ({ message, status }) => {
    handleCurrentDelete();
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <Grid container spacing={3}>
        {product.map((item) => (
          <Grid item key={item.id} lg={4} md={6} sm={6} xs={12}>
            <Accordion sx={{ justifyContent: "center", textAlign: "center" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  "&.MuiAccordionSummary-root": {
                    borderRadius: "4px 4px 4px 4px",
                    backgroundColor: "#3f50b5", // thay đổi màu sắc ở đây
                  },
                  "&.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
                    {
                      borderRadius: "4px 4px 0px 0px",
                    },
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: (theme) => theme.typography.pxToRem(16),
                    fontWeight: 500,
                    justifyContent: "center",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {item.name}
                </Typography>
              </AccordionSummary>
              <div style={{ flexBasis: "33.33%" }} />
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: "0.9375rem",
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(1),
                }}
              >
                Sản phẩm: {item?.product?.name}
              </Typography>
              <Divider />
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: (theme) => theme.typography.pxToRem(15),
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(1),
                }}
              >
                Tên: {item.name}
              </Typography>
              <Divider />
              <Box
                component="form"
                autoComplete="off"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ flex: 1, fontWeight: 500 }}
                >
                  Đơn vị tính
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ flex: 1, fontWeight: 500 }}
                >
                  Số lượng
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ flex: 1, fontWeight: 500 }}
                >
                  Giá mua
                </Typography>
              </Box>
              <Box component="form" autoComplete="off" sx={{ display: "flex" }}>
                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                  {item.unit}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                  {item.quantity}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                  {number.format(item.price)} ₫
                </Typography>
              </Box>
              <Divider />
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  textAlign: "left",
                  margin: "6px",
                }}
              >
                Notes: {item.notes}
              </pre>
              <Divider />
              <div style={{ margin: "0.5rem" }}>
                <NavLinkButton
                  href={`/manager/thucdonkho-chinh/edit/${item.id}`}
                >
                  <IconButton color="primary">
                    <EditIcon />
                  </IconButton>
                </NavLinkButton>
                <DeleteTransaction
                  id={item.id}
                  onToast={showToast}
                  onCheckedChange={(e) => handleCurrentDelete(e)}
                />
              </div>
            </Accordion>
          </Grid>
        ))}
      </Grid>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default TableBudgets;
