import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import UpdateFile from "components/Mui/Uploads/UpdateFile";

const AddVouchers = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const initialValues = {
    quantity: "",
    number: "",
  };
  const validationSchema = Yup.object().shape({
    quantity: Yup.string()
      .min(0, "It's too short")
      .required("Số lượng không được để trống"),
    number: Yup.string()
      .min(0, "It's too short")
      .required("Độ dài không được để trống"),
  });

  const onSubmit = async (values) => {
    setDung(true);
    const formData = new FormData();

    formData.append("quantity", values.quantity);
    formData.append("number", values.number);
    formData.append("file", image);

    try {
      const response = await axiosusers.post(
        `/vouchers/create-vouchers`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/vouchers");
        }, 500);
      }
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={dung ? null : onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              type="number"
              name="quantity"
              label="Số lượng"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.quantity && props.touched.quantity}
              helperText={<ErrorMessage name="quantity" />}
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              type="number"
              name="number"
              label="Độ dài random (ví dụ: 6)"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.number && props.touched.number}
              helperText={<ErrorMessage name="number" />}
            />
            <UpdateFile handPhotoChange={(e) => setImage(e)} />
            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddVouchers;
