import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const AddPayment = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [type, setType] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [devil, setDevil] = React.useState("");
  const initialValues = {
    no: "",
    owner: "",
    bank: "",
    balance: "",
    onHoldBalance: "",
    phoneNumber: "",
    notes: "",
    CLIENTID: "",
    APIKEY: "",
    CHECKSUMKEY: "",
  };
  const validationSchema = Yup.object().shape({
    no: Yup.string().required("Số thứ tự là bắt buộc"),
    owner: Yup.string().required("Chủ sở hữu là bắt buộc"),
    bank: Yup.string().required("Tên ngân hàng là bắt buộc"),
  });

  const onSubmit = async (values) => {
    setDung(true);

    const data = {
      orderNumber: values.no,
      owner: values.owner,
      bank: values.bank,
      balance: values.balance,
      onHoldBalance: values.onHoldBalance,
      phoneNumber: values.phoneNumber,
      notes: values.notes,
      type,
      status,
      currency,
      CLIENTID: values.CLIENTID,
      APIKEY: values.APIKEY,
      CHECKSUMKEY: values.CHECKSUMKEY,
      devil,
    };
    try {
      const response = await axiosusers.post(`/Payments/create-Payments`, data);
      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/thanhtoan");
        }, 500);
      }
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={dung ? null : onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="no"
              label="Số thứ tự"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.no && props.touched.no}
              helperText={<ErrorMessage name="no" />}
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="owner"
              label="Chủ sở hữu"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.owner && props.touched.owner}
              helperText={<ErrorMessage name="owner" />}
            />

            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="bank"
              label="Tên ngân hàng"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.bank && props.touched.bank}
              helperText={<ErrorMessage name="bank" />}
            />
            <FormControl
              style={{ marginTop: "0.5rem" }}
              fullWidth
              variant="outlined"
              size="small"
            >
              <InputLabel>Chọn đối tượng</InputLabel>
              <Select
                label="Chọn đối tượng"
                name="status"
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value="Paypal">Paypal</MenuItem>
                <MenuItem value="Stripe">Stripe</MenuItem>
                <MenuItem value="Momo">Momo</MenuItem>
                <MenuItem value="Airpay">Airpay</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="BTC">BTC</MenuItem>
                <MenuItem value="BankTransfer">BankTransfer</MenuItem>
                <MenuItem value="Skrill">Skrill</MenuItem>
                <MenuItem value="TransferWise">TransferWise</MenuItem>
                <MenuItem value="WebMoney">WebMoney</MenuItem>
                <MenuItem value="PayOneer">PayOneer</MenuItem>
                <MenuItem value="PingPong">PingPong</MenuItem>
                <MenuItem value="USDT">USDT</MenuItem>
                <MenuItem value="ETH">ETH</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              style={{ marginTop: "0.5rem" }}
              fullWidth
              variant="outlined"
              size="small"
            >
              <InputLabel>Chọn trạng thái</InputLabel>
              <Select
                label="Chọn trạng thái"
                name="status"
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="trustSeller">Trust seller</MenuItem>
                <MenuItem value="limit">Limit</MenuItem>
                <MenuItem value="disable">Disable</MenuItem>
                <MenuItem value="strong">Strong</MenuItem>
                <MenuItem value="weak">Weak</MenuItem>
                <MenuItem value="openCase">Open case</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              style={{ marginTop: "0.5rem" }}
              fullWidth
              variant="outlined"
              size="small"
            >
              <InputLabel>Chọn loại tiền tệ</InputLabel>
              <Select
                label="Chọn loại tiền tệ"
                name="status"
                onChange={(e) => setCurrency(e.target.value)}
              >
                <MenuItem value="VND">VND</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              style={{ marginTop: "0.5rem" }}
              fullWidth
              variant="outlined"
              size="small"
            >
              <InputLabel>Chọn tính năng</InputLabel>
              <Select
                label="Chọn tính năng"
                name="status"
                onChange={(e) => setDevil(e.target.value)}
              >
                <MenuItem value="Quỹ">Quỹ</MenuItem>
                <MenuItem value="Phương thức thanh toán">
                  Phương thức thanh toán
                </MenuItem>
              </Select>
            </FormControl>
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              type="number"
              name="balance"
              label="Số dư"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              type="number"
              name="onHoldBalance"
              label="Số dư đang giữ"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="phoneNumber"
              label="Phone Number"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="CLIENTID"
              label="CLIENT_ID"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="APIKEY"
              label="API_KEY"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="CHECKSUMKEY"
              label="CHECKSUM_KEY"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: `1.5rem` }}
              as={TextField}
              multiline
              minRows="4"
              name="notes"
              label="Ghi chú"
              variant="outlined"
              size="small"
              fullWidth
            />
            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddPayment;
