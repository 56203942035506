import { Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const NavLinkAdd = (props) => {
  const { href, name } = props;
  return (
    <div>
      <NavLink
        to={href}
        style={{
          color: "inherit",
          textDecoration: "none",
          float: "right",
          margin: "1rem 0",
        }}
      >
        <Button variant="contained" color="primary">
          {name}
        </Button>
      </NavLink>
    </div>
  );
};

export default NavLinkAdd;
