import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosusers from "utils/api/axiosusers";
import DataTimeCheckinCheckout from "./DataTimeCheckinCheckout";
const DanhsachbanDetail = ({ orders }) => {
  const [open, setOpen] = useState(false);
  const idProductRef = useRef();

  const handleClickOpen = (id) => {
    setOpen(true);
    idProductRef.current = id;
  };
  const handleDelete = async () => {
    const dataSend = { id: idProductRef.current };
    try {
      const response = await axiosusers.post(
        `/danhsachban/delete-budgetsmayorders`,
        dataSend
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Box style={{ padding: 10 }}>
      <Box sx={{ display: "flex", marginTop: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: 500, flex: 3 }}>
          Tên Bàn/Máy
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 500, flex: 1 }}>
          Thời gian vào
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, flex: 2, textAlign: "right" }}
        ></Typography>
      </Box>
      <Divider sx={{ backgroundColor: "#000" }} />
      {orders.budgetsmayorders.map((item, index) => {
        var nullCreate = item.createdAt.split("T");
        var dateCreate = nullCreate[1].split(".");
        var dateCreateAt = dateCreate[0];
        const selectProduct =
          item.danhsachban.selectProduct === "Playstation"
            ? "Máy số "
            : "Bàn số ";
        return (
          <Box key={index}>
            <Box sx={{ display: "flex" }}>
              <Typography variant="body1" sx={{ flex: 3, padding: "10px 0px" }}>
                {selectProduct + item.danhsachban.orderNumber}
              </Typography>
              <Typography variant="body1" sx={{ flex: 1, padding: "10px 0px" }}>
                {dateCreateAt}
              </Typography>
              <Box variant="body1" sx={{ flex: 2, textAlign: "right" }}>
                <DataTimeCheckinCheckout
                  id={item.id}
                  updatedDate={item.updatedAt}
                  createDate={item.createdAt}
                  row={item}
                />
                {orders.status === "inProgress" && (
                  <Tooltip title={"Hủy bàn"} placement="top" arrow>
                    <IconButton
                      color="primary"
                      onClick={() => handleClickOpen(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
            <Divider />
          </Box>
        );
      })}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bạn có chắc là muốn xóa bàn này không?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DanhsachbanDetail;
