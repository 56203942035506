import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import AutocompleteId from "hooks/useAutocomplete";
import UploadMultiple from "components/Mui/Uploads/UploadMultiple";
import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";

const AddWarehouse = ({ thucdonData, productsData }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filteredThucdon, setFilteredThucdon] = useState([]);
  const [status, setStatus] = useState("");
  const [fieldValueWallet, setFieldValueWallet] = useState([]);
  const [valueWallet, setValueWallet] = useState([]);
  const [total, setTotal] = useState(0);
  const [avatar, setAvatar] = useState([]);

  const initialValues = {
    quantity: "",
    notes: "",
  };

  useEffect(() => {
    const productIds = fieldValueWallet.map((item) => item.id);
    const walletsDataFiltered = valueWallet.filter((item) =>
      productIds.includes(item.id)
    );
    const walletsDataFilteredNot = valueWallet.filter(
      (item) => !productIds.includes(item.id)
    );

    walletsDataFilteredNot.forEach((wallet) => {
      wallet.budgets.forEach((budget) => {
        budget.usedQuantity = 0;
      });
    });

    const calculatedTotal = walletsDataFiltered.reduce((acc, wallet) => {
      return (
        acc +
        wallet.budgets.reduce((innerAcc, budget) => {
          return (
            innerAcc +
            budget.price * Number(budget.usedQuantity ? budget.usedQuantity : 0)
          );
        }, 0)
      );
    }, 0);

    setTotal(calculatedTotal);
  }, [valueWallet, fieldValueWallet]);

  const handleProductChange = (value) => {
    const productIds = value.map((item) => item.id);
    const walletsDataFiltered = thucdonData.filter((item) =>
      productIds.includes(item.productId)
    );
    setFilteredThucdon(walletsDataFiltered);
  };

  const handleClientsChange = (value) => {
    const productIds = fieldValueWallet.map((item) => item.id);
    const walletsDataFiltered = valueWallet.filter((item) =>
      productIds.includes(item.id)
    );

    const updatedValueWallet = value.map((item) => ({
      ...item,
      budgets:
        valueWallet.find((field) => field.id === item.id)?.budgets ||
        item.budgets,
    }));

    const updatedValueWalletAll = updatedValueWallet.map((item) => ({
      ...item,
      budgets:
        walletsDataFiltered.find((field) => field.id === item.id)?.budgets ||
        item.budgets,
    }));

    setFieldValueWallet(updatedValueWalletAll);
    setValueWallet(updatedValueWalletAll);
  };

  const handleChangeInput = (id, event) => {
    const { name, value } = event.target;

    const newInputFields = fieldValueWallet.map((item) => ({
      ...item,
      budgets: item.budgets.map((budget) =>
        budget.id === id ? { ...budget, [name]: value } : budget
      ),
    }));

    const updatedValueWallet = valueWallet.map((item) => ({
      ...item,
      budgets:
        newInputFields.find((field) => field.id === item.id)?.budgets ||
        item.budgets,
    }));

    setValueWallet(updatedValueWallet);
    setFieldValueWallet(newInputFields);
  };
  const validationSchema = Yup.object().shape({});

  const handlePhotoChange = (avatar) => {
    setAvatar(avatar);
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    let errorMessage = "";

    // avatar.forEach((item) => {
    //   if (item.file.size > 2 * 1024 * 1024) {
    //     errorMessage = "Size must be less than 2MB";
    //   } else if (
    //     item.file.type !== "image/png" &&
    //     item.file.type !== "image/jpg" &&
    //     item.file.type !== "image/jpeg"
    //   ) {
    //     errorMessage = "File type must be PNG, JPG, or JPEG";
    //   }
    // });

    if (errorMessage) {
      setIsSubmitting(false);
      setErrorMessage(errorMessage);
      toast.error(errorMessage, {
        position: "top-right",
      });
      return;
    }

    const data = {
      notes: values.notes,
      fieldValueWallet,
      total,
    };

    try {
      const response = await axiosusers.post(`/xuatkho/create-xuatkho`, data);
      if (response.status === 200) {
        for (const image of avatar.sort((a, b) => a.size - b.size)) {
          const formData = new FormData();
          formData.append("file", image.file);
          formData.append("xuatkhoId", response.data.xuatkhoId);
          formData.append("status", "image");

          try {
            await axiosusers.post(`/xuatkho/xuatkho-photos`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          } catch (error) {
            console.error(error);
          }
        }
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          navigate("/manager/xuatkho");
        }, 500);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error(error?.response);
      setErrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };

  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const onChangeStatus = (value) => {
    setStatus(value);
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={isSubmitting ? null : onSubmit}
      >
        {() => (
          <Form autoComplete="off">
            <FormControlProduct onChangeStatus={onChangeStatus} />
            <AutocompleteId
              multiple
              data={productsData.filter((item) => item.status === status)}
              placeholder="Chọn một hoặc nhiều"
              label="Sản phẩm"
              id="Sản phẩm"
              name="Sản phẩm"
              size="small"
              openError={false}
              done={false}
              onCheckedChange={handleProductChange}
              optionLabel={(label) => label.name}
            />
            {filteredThucdon.length > 0 && (
              <AutocompleteId
                data={filteredThucdon}
                label="Danh sách thực đơn"
                id="Danh sách thực đơn"
                name="Danh sách thực đơn"
                multiple
                placeholder="Chọn một hoặc nhiều"
                size="small"
                openError={true}
                done={false}
                onCheckedChange={handleClientsChange}
                optionLabel={(label) => label.name}
              />
            )}
            {fieldValueWallet.map((inputField) => (
              <Box
                key={inputField.id}
                component="form"
                autoComplete="off"
                sx={{ marginTop: "0.5rem" }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 500, fontSize: "1rem" }}
                >
                  Tên thực đơn: {inputField.name}
                </Typography>
                <Box
                  component="form"
                  autoComplete="off"
                  sx={{ display: "flex", alignItems: "center", gap: 0, mb: 0 }}
                >
                  <Typography variant="subtitle1" sx={{ flex: 1 }}>
                    Tên ngân sách
                  </Typography>
                  <Typography variant="subtitle1" sx={{ flex: 1 }}>
                    Đơn vị tính
                  </Typography>
                  <Typography variant="subtitle1" sx={{ flex: 1 }}>
                    Số lượng
                  </Typography>
                  <Typography variant="subtitle1" sx={{ flex: 1 }}>
                    Giá mua
                  </Typography>
                  <Typography variant="subtitle1" sx={{ flex: 1 }}>
                    Nhập số lượng
                  </Typography>
                </Box>
                {inputField.budgets.map((budget) => (
                  <Box
                    key={budget.id}
                    component="form"
                    autoComplete="off"
                    sx={{ display: "flex", gap: 1, mb: 1, marginTop: 0 }}
                  >
                    <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                      {budget.name}
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                      {budget.unit}
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                      {budget.quantity}
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                      {number.format(budget.price)} đ
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      name="usedQuantity"
                      value={budget.usedQuantity}
                      onChange={(event) => handleChangeInput(budget.id, event)}
                      label="Nhập số lượng"
                      variant="outlined"
                      size="small"
                      sx={{ flex: 1 }}
                    />
                  </Box>
                ))}
              </Box>
            ))}
            <Typography
              variant="subtitle1"
              sx={{ marginTop: "10px", fontWeight: 500 }}
            >
              Số tiền: {number.format(total) || 0} đ
            </Typography>
            <Field
              sx={{ marginTop: "0.5rem" }}
              as={TextField}
              multiline
              minRows="4"
              name="notes"
              label="Ghi chú"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Box sx={{ marginTop: "1rem" }}>
              <UploadMultiple handPhotoChange={handlePhotoChange} />
            </Box>
            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}
            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={isSubmitting}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
      <ToastContainer
        style={{ top: "64px", right: "0", width: "max-content" }}
      />
    </div>
  );
};

export default AddWarehouse;
