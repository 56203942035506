/* eslint-disable react/no-unknown-property */
import useLinks from "hooks/useLinks";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import CloseIcon from "@mui/icons-material/Close";
const ImageVouchers = (props) => {
  const { row } = props;
  const linkImage = useLinks();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const canvasRef = React.useRef(null);
  const handleOpen = (index) => {
    setIsOpen(true);
    setCurrentIndex(index);
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
    }, 300);
  };
  const handleClose = () => {
    setIsOpen(false);
    setCurrentIndex(0);
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (isOpen) {
        if (event.key === "Escape") {
          handleClose();
        } else if (event.key === "Enter") {
          const element = document.documentElement;
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.mozRequestFullScreen) {
            /* Firefox */
            element.mozRequestFullScreen();
          } else if (element.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            element.webkitRequestFullscreen();
          } else if (element.msRequestFullscreen) {
            /* IE/Edge */
            element.msRequestFullscreen();
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, currentIndex]);
  const drawImageWithText = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = `${linkImage + props.nameImage}`;

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      ctx.font = "48px Arial, sans-serif";
      ctx.crossOrigin = "Anonymous";
      if (window.innerWidth <= 600) {
        ctx.font = "5px Arial, sans-serif";
      }
      ctx.fillStyle = "#fff";
      ctx.letterSpacing = "3px";
      ctx.fontWeight = 600;
      const divCode = {
        top: "89%",
        left: "20%",
        fontWeight: 600,
      };
      const text = row.code;
      const textWidth = ctx.measureText(text).width;
      const textX = (canvas.width - textWidth) / 4.2;

      const textY = (canvas.height * parseFloat(divCode.top)) / 99.2;
      ctx.fillStyle = "#fff";
      ctx.fillText(text, textX, textY);
    };
  };
  React.useEffect(() => {
    if (!isLoading) {
      drawImageWithText();
    }
  }, [isLoading, linkImage, props.nameImage, row]);
  return (
    <div>
      {isOpen && (
        <>
          <div className={styles.lightbox}>
            <div className={styles.lightboxOverlay} onClick={handleClose} />
            <div className={styles.headers}>
              <Tooltip title="Close">
                <div className={styles.closeButton} onClick={handleClose}>
                  <IconButton>
                    <CloseIcon className={styles.icon} />
                  </IconButton>
                </div>
              </Tooltip>
            </div>

            <div className={styles.lightboxContent}>
              {!isLoading && (
                <>
                  <canvas ref={canvasRef} crossOrigin="anonymous"></canvas>
                </>
              )}
              {isLoading && <CircularProgress className={styles.lightboxImg} />}
            </div>
          </div>
        </>
      )}
      <canvas
        ref={canvasRef}
        crossOrigin="anonymous"
        style={{ display: "none" }}
      ></canvas>
      <Box
        sx={{
          width: "40px",
          height: "40px",
          display: "flex",
          overflow: "hidden",
          position: "relative",
          fontSize: "1rem",
          alignItems: "center",
          lineHeight: "1",
          userSelect: "none",
          borderRadius: "50%",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => handleOpen(linkImage + props.nameImage)}
      >
        <img
          src={`${linkImage}${props.nameImage}`}
          crossOrigin="anonymous"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            verticalAlign: "middle",
            borderStyle: "none",
          }}
        />
      </Box>
    </div>
  );
};

export default ImageVouchers;
