import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import PhotosOrders from "components/Orders/DetailOrders/Main/hocks/PhotosOrders";
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
const EditWarehouse = ({ product, gameData }) => {
  const [input, setInput] = useState(product);
  const productIds = product.nhapkhoproductsAll.map((item) => item.productId);
  const filteredGameData = gameData.filter(
    (game) => !productIds.includes(game.id)
  );
  const [gameDataSome, setGameDataSome] = React.useState(filteredGameData);
  const [selectedGame, setSelectedGame] = React.useState(
    product.nhapkhoproductsAll
  );

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const setOnChaneProduct = (value) => {
    setSelectedGame(value);
    const productIds = value.map((item) => item.productId);
    const filtered = gameData.filter((game) => !productIds.includes(game.id));
    setGameDataSome(filtered);
  };
  const deletePhotoOrders = (id) => {
    console.log(id);
  };
  return (
    <div>
      <Autocomplete
        sx={{ marginTop: "0.5rem" }}
        options={gameDataSome}
        fullWidth
        multiple
        value={selectedGame}
        disabled
        filterSelectedOptions
        onChange={(event, value) => setOnChaneProduct(value)}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(jsonResuls) => jsonResuls.name}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(selectedGame === null)}
            helperText={selectedGame === null ? "Sản Phẩm bắt buộc" : ""}
            label="Sản Phẩm"
            placeholder="Chọn một hoặc nhiều sản phẩm"
            variant="outlined"
          />
        )}
      />
      {product.nhapkhowalletsAll.map((item, key) => {
        return (
          <Box
            key={key}
            component="form"
            autoComplete="off"
            sx={{ marginTop: "1rem" }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: "1rem" }}>
              Tên thực đơn: {item.wallet.name}
            </Typography>
            <Box
              component="form"
              autoComplete="off"
              sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0 }}
            >
              <Typography variant="subtitle1" sx={{ flex: 1 }}>
                Tên ngân sách
              </Typography>
              <Typography variant="subtitle1" sx={{ flex: 1 }}>
                Số lượng còn lại
              </Typography>
              <Typography variant="subtitle1" sx={{ flex: 1 }}>
                Số lượng đã thêm
              </Typography>
            </Box>
            {item.nhapkhobudgets.map((row) => (
              <Box
                key={row.id}
                component="form"
                autoComplete="off"
                sx={{ display: "flex", gap: 1, mb: 1, marginTop: 0.5 }}
              >
                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                  {row.budget.name}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                  {row.budget.quantity}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                  {row.quantity}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      })}
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        disabled
        value={input.supplier}
        name="supplier"
        label="Nhà cung cấp"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        multiline
        disabled
        minRows={3}
        value={input.notes}
        name="notes"
        label="Ghi chú"
        size="small"
        variant="outlined"
        fullWidth
      />
      <PhotosOrders
        images={product.nhapkhophotos}
        deletePhotoOrders={deletePhotoOrders}
        deletePhoto={false}
      />
    </div>
  );
};

export default EditWarehouse;
