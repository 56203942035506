import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import NavLinkButton from "components/NavLinks/NavLinkButton";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeletePayment from "./DeletePayment";
import EditIcon from "@mui/icons-material/Edit";
import Images from "components/Avatar/Images";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import DevilPayment from "./DevilPayment";

const TablePayments = (props) => {
  const { product, handleCurrentDelete, userId } = props;

  const showToast = ({ message, status }) => {
    handleCurrentDelete();
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const numberthem = new Intl.NumberFormat("en-NZ", {
    minimumFractionDigits: 2,
  });
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Thứ tự</TableCell>
              <TableCell>Người sở hữu</TableCell>
              <TableCell>Kiểu</TableCell>
              {userId?.role == "Admin" && <TableCell>Tên ngân hàng</TableCell>}
              <TableCell>Trạng thái</TableCell>
              <TableCell>Đã nhận hằng ngày</TableCell>
              <TableCell>Quỹ</TableCell>
              <TableCell>Số dư hàng tháng</TableCell>
              <TableCell>Đang giữ</TableCell>
              {userId?.role == "Admin" && <TableCell>Rút 1</TableCell>}
              {userId?.role == "Admin" && <TableCell>Rút 2</TableCell>}
              <TableCell>Ghi chú</TableCell>
              <TableCell>Rút Quỹ</TableCell>
              {userId?.role == "Admin" && <TableCell></TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              const totalAmount = row.transactions
                .filter(
                  (item) =>
                    row.id === item.paymentId && item.status !== "pending"
                )
                .reduce((acc, item) => acc + Number(item.amount), 0);
              const widthdraw = row.withdrawTransactions;

              const formatDate = (dateString) =>
                new Date(dateString).toLocaleString("en-US", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                });

              const [firstTransaction, secondTransaction] = widthdraw;

              const formattedCreatedAt1 = firstTransaction
                ? formatDate(firstTransaction.createdAt)
                : "";
              const formattedCreatedAt2 = secondTransaction
                ? formatDate(secondTransaction.createdAt)
                : "";

              const widthdrawAmount1 = firstTransaction
                ? `${number.format(
                    firstTransaction.amount
                  )} ₫ \n${formattedCreatedAt1}`
                : "";
              const widthdrawAmount2 = secondTransaction
                ? `${number.format(
                    secondTransaction.amount
                  )} ₫ \n${formattedCreatedAt2}`
                : "";

              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.orderNumber}
                  </TableCell>
                  <TableCell
                    style={
                      row.status === "weak"
                        ? { color: "blue" }
                        : row.status === "limit"
                        ? { background: "red" }
                        : row.status === "limit_180_day"
                        ? { background: "red" }
                        : null
                    }
                  >
                    <Tooltip title="Copy" placement="top" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(`${row.owner}`);
                        }}
                      >
                        <pre style={{ whiteSpace: "pre" }}>
                          {row.owner.length > 50
                            ? row.owner
                            : row.owner.slice(0, 25) + "..."}
                        </pre>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Images nameImage={`${row.type}.png`} />
                  </TableCell>
                  {userId?.role == "Admin" && <TableCell>{row.bank}</TableCell>}
                  <TableCell
                    style={
                      row.status === "weak"
                        ? { color: "blue" }
                        : row.status === "limit"
                        ? { color: "red" }
                        : row.status === "limit_180_day"
                        ? { color: "red" }
                        : null
                    }
                  >
                    {row.status
                      .replace(/([A-Z])/g, " $1")
                      .toLowerCase()
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                  </TableCell>
                  <TableCell>
                    {row.currency == "VND"
                      ? number.format(row.balance) + ` ₫`
                      : numberthem
                          .format(Math.round(row.balance * 100) / 100)
                          .replace(".", ",") + ` $`}
                  </TableCell>
                  <TableCell>
                    {row.currency == "VND"
                      ? number.format(row.devilAmount) + ` ₫`
                      : numberthem
                          .format(Math.round(row.devilAmount * 100) / 100)
                          .replace(".", ",") + ` $`}
                  </TableCell>
                  <TableCell>
                    {row.currency == "VND"
                      ? number.format(totalAmount) + ` ₫`
                      : numberthem
                          .format(Math.round((totalAmount / 22200) * 100) / 100)
                          .replace(".", ",") + ` $`}
                  </TableCell>
                  <TableCell>
                    {row.currency == "VND"
                      ? number.format(row.onHoldBalance) + ` ₫`
                      : numberthem
                          .format(Math.round(row.onHoldBalance * 100) / 100)
                          .replace(".", ",") + ` $`}
                  </TableCell>
                  {userId?.role == "Admin" && (
                    <TableCell>{widthdrawAmount1}</TableCell>
                  )}
                  {userId?.role == "Admin" && (
                    <TableCell>{widthdrawAmount2}</TableCell>
                  )}

                  <TableCell>
                    <pre>{row.notes}</pre>
                  </TableCell>
                  <TableCell>
                    <DevilPayment row={row} onToast={showToast} />
                  </TableCell>
                  {userId?.role == "Admin" && (
                    <TableCell size="small" align="center">
                      <NavLinkButton href={`/manager/thanhtoan/${row.id}`}>
                        <Tooltip title={"Chi tiết"} placement="top" arrow>
                          <IconButton color="primary">
                            <AddToQueueIcon />
                          </IconButton>
                        </Tooltip>
                      </NavLinkButton>
                      <NavLinkButton href={`/manager/thanhtoan/edit/${row.id}`}>
                        <IconButton color="primary">
                          <EditIcon />
                        </IconButton>
                      </NavLinkButton>
                      <DeletePayment
                        id={row.id}
                        onToast={showToast}
                        onCheckedChange={(e) => handleCurrentDelete(e)}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default TablePayments;
