import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import AutocompleteId from "hooks/useAutocomplete";
import BudgetsWalletsOrders from "../hooks/useBudgetsGroups";
import UploadMultiple from "components/Mui/Uploads/UploadMultiple";
import useUserId from "hooks/useUserId";
import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";

const AddWarehouse = (props) => {
  const { productsData, walletsData, suppliersData } = props;
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const { userId } = useUserId();
  const [dung, setDung] = React.useState(false);
  const [inputProduct, setInputProduct] = React.useState([]);
  const [inputWallet, setInputWallet] = React.useState([]);
  const [wallersSome, setWalletsSome] = React.useState([]);
  const [budgetsJson, setBudgetsJson] = React.useState([]);
  const [loadingWallet, setLoadingWallet] = React.useState(false);
  const [suppliers, setSuppliers] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const initialValues = {
    supplier: "",
    notes: "",
  };

  const validationSchema = Yup.object().shape({});
  const [avatar, setAvatar] = React.useState([]);
  const handPhotoChange = (avatar) => {
    setAvatar(avatar);
  };
  const handleChangeProducts = (value) => {
    setInputProduct(value);
    const productIds = value.map((item) => item.id);
    const walletsDataFiltered = walletsData.filter((item) =>
      productIds.includes(item.productId)
    );
    setLoadingWallet(true);
    setWalletsSome(walletsDataFiltered);
    setTimeout(() => {
      setLoadingWallet(false);
    }, 10);
  };

  const onCheckedChangeBudgets = (value) => {
    setBudgetsJson(value);
  };
  const handleChangeSuppliers = (value) => {
    setSuppliers(value);
  };
  const onSubmit = async (values) => {
    setDung(true);
    let errorMessage = "";
    // avatar.map((item) => {
    //   if (item.file.size > 2 * 1024 * 1024) {
    //     errorMessage = "size must be less than 2MB";
    //   } else if (
    //     item.file.type !== "image/png" &&
    //     item.file.type !== "image/jpg" &&
    //     item.file.type !== "image/jpeg"
    //   ) {
    //     errorMessage = "file type must be PNG or JPG or JPEG";
    //   }
    // });
    if (inputProduct.length === 0) {
      errorMessage = `Sản phẩm is required`;
    }
    if (inputWallet.length === 0) {
      errorMessage = `Thực đơn is required`;
    }

    if (errorMessage !== "") {
      setDung(false);
      seterrorMessage(errorMessage);
      toast.error(errorMessage, {
        position: "top-right",
      });
      return;
    }
    const data = {
      supplier: suppliers?.id !== undefined ? suppliers?.fullName : "",
      notes: values.notes,
      inputProduct,
      inputWallet,
      budgetsJson,
      userId: userId.id,
    };
    try {
      const response = await axiosusers.post(`/nhapkho/create-nhapkho`, data);
      if (response.status === 200) {
        console.log(response);
        for (const image of avatar.sort((a, b) => a.size - b.size)) {
          const formData = new FormData();
          formData.append("file", image.file);
          formData.append("nhapkhoId", response.data.nhapkhoId);
          formData.append("status", "image");

          try {
            await axiosusers.post(`/nhapkho/nhapkho-photos`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          } catch (error) {
            console.log(error);
          }
        }
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/nhapkho");
        }, 500);
      }
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };

  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const [total, setTotal] = React.useState(0);
  React.useEffect(() => {
    const jsonBudgets = budgetsJson
      .flatMap((item) => item.budgets)
      .filter((item) => item.usedQuantity > 0);

    const calculatedTotal = jsonBudgets.reduce((acc, wallet) => {
      return (
        acc +
        wallet.price * Number(wallet.usedQuantity ? wallet.usedQuantity : 0)
      );
    }, 0);

    setTotal(calculatedTotal);
  }, [budgetsJson]);
  const onChangeStatus = (value) => {
    setStatus(value);
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={dung ? null : onSubmit}
      >
        {() => (
          <Form autoComplete="off">
            <FormControlProduct onChangeStatus={onChangeStatus} />
            <AutocompleteId
              data={productsData.filter((item) => item.status === status)}
              multiple={true}
              placeholder="Chọn một hoặc nhiều"
              label="Sản phẩm"
              id="Sản phẩm"
              name="Sản phẩm"
              size="small"
              openError={true}
              done={errorMessage && inputProduct?.id === undefined}
              onCheckedChange={handleChangeProducts}
              optionLabel={(label) => label.name}
            />

            {inputProduct.length > 0 && !loadingWallet && (
              <BudgetsWalletsOrders
                wallets={wallersSome}
                setInputWallet={(e) => setInputWallet(e)}
                onCheckedChangeBudgets={onCheckedChangeBudgets}
              />
            )}
            <Typography
              variant="subtitle1"
              sx={{ marginTop: "10px", fontWeight: 500 }}
            >
              Số tiền: {number.format(total) || 0} đ
            </Typography>
            <AutocompleteId
              data={suppliersData}
              label="Nhà cung cấp"
              id="Nhà cung cấp"
              name="Nhà cung cấp"
              size="small"
              openError={false}
              done={false}
              onCheckedChange={handleChangeSuppliers}
              optionLabel={(label) => label.fullName}
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              multiline
              minRows="4"
              name="notes"
              label="Ghi chú"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Box sx={{ marginTop: `1rem` }}>
              <UploadMultiple handPhotoChange={handPhotoChange} />
            </Box>

            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddWarehouse;
