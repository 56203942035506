import { MenuItem, FormControl, InputLabel, Select } from "@material-ui/core";
import React from "react";

const FormControlProduct = ({ onChangeStatus, value, size }) => {
  return (
    <div>
      <FormControl
        fullWidth
        variant="outlined"
        size={size ? "" : "small"}
        style={{ marginTop: size ? "" : "0.5rem" }}
      >
        <InputLabel>Chọn sản phẩm</InputLabel>
        <Select
          label="Chọn sản phẩm"
          name="status"
          value={value}
          onChange={(e) => onChangeStatus(e.target.value)}
        >
          <MenuItem value="Coffee">Coffee</MenuItem>
          <MenuItem value="Playstation">Playstation</MenuItem>
          <MenuItem value="Fastfood">Fastfood</MenuItem>
          <MenuItem value="Cake">Cake</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default FormControlProduct;
