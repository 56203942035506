import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import NavLinkButton from "components/NavLinks/NavLinkButton";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import { NavLink } from "react-router-dom";
import DeleteChiphi from "./DeleteChiphi";
const TableChiphi = (props) => {
  const { product, handleCurrentDelete } = props;

  const showToast = ({ message, status }) => {
    handleCurrentDelete();
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  return (
    <div>
      {" "}
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Sàn phẩm</TableCell>
              <TableCell>Tên</TableCell>
              <TableCell>Đơn vị tính</TableCell>
              <TableCell>Giá mặc định</TableCell>
              <TableCell>Ghichu</TableCell>
              <TableCell>Create Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              var nullCreate = row.createdAt.split("T");
              var dateCreate = nullCreate[0].split("-");
              var dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <NavLink
                      style={{ color: "blue", textDecoration: "none" }}
                      to={`/manager/chi-phi/edit/${row.id}`}
                    >
                      {row.id}
                    </NavLink>
                  </TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>
                    <Tooltip title="Copy" placement="top" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(`${row.name}`);
                        }}
                      >
                        {row.name}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{row.unit}</TableCell>
                  <TableCell>{row.price}</TableCell>
                  <TableCell>{row.notes}</TableCell>
                  <TableCell>{dateCreateAt}</TableCell>
                  <TableCell size="small" align="center">
                    <NavLinkButton href={`/manager/chi-phi/edit/${row.id}`}>
                      <IconButton color="primary">
                        <EditIcon />
                      </IconButton>
                    </NavLinkButton>
                    <DeleteChiphi
                      id={row.id}
                      onToast={showToast}
                      onCheckedChange={(e) => handleCurrentDelete(e)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default TableChiphi;
