import { Typography } from "@mui/material";
import React from "react";

const NumberVn = ({ price, fontWeight }) => {
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <Typography sx={{ fontWeight: fontWeight }}>
        {number.format(price)} ₫
      </Typography>
    </div>
  );
};

export default NumberVn;
