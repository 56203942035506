import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import axiosusers from "utils/api/axiosusers";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
const DataTimeCheckinCheckout = (props) => {
  const { id, updatedDate, createDate } = props;
  const [open, setOpen] = React.useState(false);

  const nullCreate = createDate.split("T");
  const nullChamCreate = nullCreate[1].split(".");
  const dateTimeCreate = nullChamCreate[0].split(":");
  const ngayCreate = nullCreate[0].split("-");

  const nullUpdate = updatedDate.split("T");
  const nullCham = nullUpdate[1].split(".");
  const dateTime = nullCham[0].split(":");
  const ngayUpdate = nullUpdate[0].split("-");

  const [inputCheckin, setInputCheckin] = React.useState(ngayCreate[2]);
  const [inputCheckout, setInputCheckout] = React.useState(ngayUpdate[2]);
  const [value, setValue] = React.useState(
    dayjs().hour(dateTime[0]).minute(dateTime[1]).second(dateTime[2])
  );
  const [valueCreate, setValueCreate] = React.useState(
    dayjs()
      .hour(dateTimeCreate[0])
      .minute(dateTimeCreate[1])
      .second(dateTimeCreate[2])
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDelete = async () => {
    setOpen(false);
    const valueAll =
      ngayUpdate[0] +
      "-" +
      ngayUpdate[1] +
      "-" +
      inputCheckin +
      "T" +
      value.format("HH:mm:ss") +
      ".000Z";
    const valueAllCreate =
      ngayCreate[0] +
      "-" +
      ngayCreate[1] +
      "-" +
      inputCheckout +
      "T" +
      valueCreate.format("HH:mm:ss") +
      ".000Z";
    const dataSend = { id, value: valueAll, valueAllCreate };
    try {
      const response = await axiosusers.post(
        `/orders/update-orders-time`,
        dataSend
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Tooltip title={"Sửa thời gian"} placement="top" arrow>
        <IconButton color="primary" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ padding: 1 }}>
            <Box sx={{ display: "flex", gap: 1, marginTop: "0.5rem" }}>
              <TextField
                onChange={(e) => setInputCheckin(e.target.value)}
                sx={{ marginTop: `0.5rem` }}
                type="text"
                value={inputCheckin}
                name="inputCheckin"
                label="Ngày vào"
                size="small"
                variant="outlined"
                fullWidth
              />
              <TextField
                onChange={(e) => setInputCheckout(e.target.value)}
                sx={{ marginTop: `0.5rem` }}
                type="text"
                value={inputCheckout}
                name="inputCheckout"
                label="Ngày về"
                size="small"
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    "TimePicker",
                    "MobileTimePicker",
                    "DesktopTimePicker",
                    "StaticTimePicker",
                  ]}
                >
                  <DemoItem label="Giờ vào" ampm={false}>
                    <MobileTimePicker
                      value={valueCreate}
                      ampm={false}
                      onChange={(newValue) => setValueCreate(newValue)}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    "TimePicker",
                    "MobileTimePicker",
                    "DesktopTimePicker",
                    "StaticTimePicker",
                  ]}
                >
                  <DemoItem label="Giờ về" ampm={false}>
                    <MobileTimePicker
                      value={value}
                      ampm={false}
                      onChange={(newValue) => setValue(newValue)}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DataTimeCheckinCheckout;
