import { Autocomplete, TextField, Typography } from "@mui/material";
import React from "react";
import Budgets from "./Budgets";
import PhotosOrders from "components/Orders/DetailOrders/Main/hocks/PhotosOrders";

const DetailXuatkho = ({ product }) => {
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const deletePhotoOrders = (id) => {
    console.log(id);
  };
  // const [gameDataSome] = React.useState(product.product);
  return (
    <div>
      {/* <Autocomplete
        sx={{ marginTop: "0.5rem" }}
        options={gameDataSome}
        fullWidth
        value={gameDataSome}
        disabled
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(jsonResuls) => jsonResuls.name}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sản Phẩm"
            placeholder="Chọn một hoặc nhiều sản phẩm"
            variant="outlined"
          />
        )}
      /> */}
      <Autocomplete
        style={{ marginTop: "0.5rem" }}
        fullWidth
        multiple
        size="small"
        id="combo-box-demo"
        name="id"
        disabled
        value={product.xuatkhoproducts}
        options={product.xuatkhoproducts}
        getOptionLabel={(label) => label.name}
        renderInput={(params) => <TextField {...params} label="Sản Phẩm" />}
      />
      <Typography
        variant="h5"
        sx={{ fontWeight: 500, fontSize: "1rem", marginTop: "0.5rem" }}
      >
        Tổng tiền: {number.format(product.price)} đ
      </Typography>
      <Budgets xuatkhowallets={product.wallets} />
      <TextField
        sx={{ marginTop: `1rem` }}
        multiline
        disabled
        minRows={3}
        name="notes"
        label="Ghi chú"
        size="small"
        variant="outlined"
        fullWidth
      />
      <PhotosOrders
        images={product.photos}
        deletePhotoOrders={deletePhotoOrders}
        deletePhoto={false}
      />
    </div>
  );
};

export default DetailXuatkho;
