import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import NavLinkButton from "components/NavLinks/NavLinkButton";

const TableTaoban = (props) => {
  const { product } = props;

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Số thứ tự</TableCell>
              <TableCell>Sản Phẩm</TableCell>
              <TableCell>Trạng thái</TableCell>
              <TableCell>Giá tiền</TableCell>
              <TableCell>Ngày tạo ra</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              const nullCreate = row.createdAt.split("T");
              const dateCreate = nullCreate[0].split("-");
              const dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];

              return (
                <TableRow key={row.id}>
                  <TableCell>{row.orderNumber}</TableCell>
                  <TableCell>{row.selectProduct}</TableCell>
                  <TableCell>{!row.status ? "Trống" : "Có người"}</TableCell>
                  <TableCell>{row.usedPrice}</TableCell>
                  <TableCell>{dateCreateAt}</TableCell>
                  <TableCell size="small" align="center">
                    <NavLinkButton
                      href={`/manager/danhsachban/taoban/edit/${row.id}`}
                    >
                      <IconButton color="primary">
                        <EditIcon />
                      </IconButton>
                    </NavLinkButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableTaoban;
