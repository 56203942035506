import { Box, Grid, MenuItem, Paper, TextField } from "@mui/material";
import AutocompleteSearch from "components/Mui/Autocompletes/AutocompleteSearch";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { FormControl, InputLabel, Select } from "@material-ui/core";
const SearchReport = (props) => {
  const {
    data,
    userData,
    onSearchUser,
    onChangeData,
    productData,
    onSearchProduct,
    setSearchStatus,
  } = props;
  const [value, setValue] = React.useState([null, null]);
  const onChangeDate = (newValue) => {
    setValue(newValue);
    onChangeData(newValue);
  };
  return (
    <div>
      <Grid
        container
        sx={{ width: "100%", margin: "40px auto", height: "auto" }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            {data?.role !== "Staff" && (
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AutocompleteSearch
                  data={userData}
                  size="large"
                  label="Chọn người dùng"
                  onCheckedChange={(e) => onSearchUser(e)}
                  optionLabel={(label) => label.name}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Trạng Thái</InputLabel>
                <Select
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                  }}
                  label="Trạng Thái"
                  name="status"
                >
                  <MenuItem value="none">Không</MenuItem>
                  <MenuItem value="done">Xong</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <AutocompleteSearch
                data={productData}
                size="large"
                label="Chọn sản phẩm"
                onCheckedChange={(e) => onSearchProduct(e)}
                optionLabel={(label) => label.name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  value={value}
                  inputFormat="dd/MM/yyyy"
                  onChange={onChangeDate}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField
                        {...startProps}
                        helperText="dd/mm/yyyy"
                        label="from"
                        variant="outlined"
                      />
                      <Box style={{ margin: "13px" }}> to </Box>
                      <TextField
                        {...endProps}
                        helperText="dd/mm/yyyy"
                        label="to"
                        variant="outlined"
                      />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default SearchReport;
