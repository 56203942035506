import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

const TypographyOrders = ({ print }) => {
  const InfoText = ({ text, print }) => (
    <Typography
      variant="body1"
      sx={{
        fontWeight: 500,
        fontSize: print ? "12px" : "1.2rem",
        display: "block",
        lineHeight: 1.2,
      }}
    >
      {text}
    </Typography>
  );
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Typography
        color="primary"
        variant="body1"
        sx={{
          fontWeight: 500,
          fontSize: print ? "1.5rem" : "2rem",
          display: "block",
        }}
      >
        Chi tiết đơn hàng
      </Typography>
      <InfoText text="Coffee - PS5 - Fastfood" print={print} />
      <InfoText text="Địa chỉ: 9b Nguyễn Thuật" print={print} />
      <InfoText text="Phone: 0857754444" print={print} />
      <Avatar
        alt="Logo"
        src="/logo_kan.png"
        sx={{
          width: print ? 50 : 70,
          height: print ? 50 : 70,
          mt: 1.5,
          // border: print ? "1px solid #000" : "",
        }}
      />
    </Box>
  );
};

export default TypographyOrders;
