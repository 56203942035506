import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import AutocompleteId from "hooks/useAutocomplete";
import { FormControl, InputLabel, Select } from "@material-ui/core";

const AddTransaction = (props) => {
  const { clientData, paymentData } = props;
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [inputClients, setInputClients] = React.useState([]);
  const [inputPayments, setInputPayments] = React.useState([]);
  const date = new Date();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0 nên cần cộng 1
  const year = date.getFullYear();

  const formattedDate = `${hours}:${minutes}:${seconds}-${day}/${month}/${year}`;
  const initialValues = {
    code: "",
    amount: "",
  };
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .min(0, "It's too short")
      .required("Mã giao dịch là bắt buộc"),
    amount: Yup.string()
      .min(0, "It's too short")
      .required("Số tiền là bắt buộc"),
  });
  const handleChangeClients = (value) => {
    setInputClients(value);
  };
  const handleChangePayments = (value) => {
    setInputPayments(value);
  };
  const [currency, setCurrency] = React.useState("VND");
  const [status, setStatus] = React.useState("");
  const onSubmit = async (values) => {
    setDung(true);
    let errorMessage = "";
    const amounts =
      currency == "VND" ? Number(values.amount) : Number(values.amount) * 22200;

    if (amounts > 1000000000) {
      errorMessage = `The amount is too large. The amount cannot exceed one billion. Please review it.`;
    }
    if (status === "") {
      errorMessage = "Trạng thái giao dịch không được trống";
    }
    if (errorMessage !== "") {
      setDung(false);
      seterrorMessage(errorMessage);
      toast.error(errorMessage, {
        position: "top-right",
      });
      return;
    }
    const data = {
      clientId: inputClients.id,
      paymentId: inputPayments.id,
      code: values.code.replace(/\s/g, ""),
      amount: amounts,
      status: status,
      currency,
    };
    try {
      const response = await axiosusers.post(
        `/transactions/create-transactions`,
        data
      );
      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/id-giaodich");
        }, 500);
      }
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const numberthem = new Intl.NumberFormat("en-NZ", {
    minimumFractionDigits: 2,
  });
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={dung ? null : onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <AutocompleteId
              data={clientData}
              label="Khách hàng"
              id="Khách hàng"
              name="Khách hàng"
              size="small"
              openError={true}
              done={errorMessage && inputClients?.id === undefined}
              onCheckedChange={handleChangeClients}
              optionLabel={(label) => label.fullName}
            />
            <AutocompleteId
              data={paymentData}
              label="Thanh toán"
              id="Thanh toán"
              name="Thanh toán"
              size="small"
              openError={true}
              done={errorMessage && inputPayments?.id === undefined}
              onCheckedChange={handleChangePayments}
              optionLabel={(label) => `${label.orderNumber}-${label.owner}`}
            />
            <Tooltip
              title={"Copy"}
              placement="top"
              onClick={() => {
                navigator.clipboard.writeText(`${formattedDate}`);
              }}
              arrow
            >
              <Button
                sx={{ marginTop: `0.5rem` }}
                variant="outlined"
                size="small"
              >
                {formattedDate}
              </Button>
            </Tooltip>
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="code"
              label="Mã giao dịch"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.code && props.touched.code}
              helperText={<ErrorMessage name="code" />}
            />
            <FormControl
              style={{ marginTop: "0.5rem" }}
              fullWidth
              variant="outlined"
              size="small"
            >
              <InputLabel>Chọn loại tiền tệ</InputLabel>
              <Select
                label="Chọn loại tiền tệ"
                name="status"
                onChange={(e) => setCurrency(e.target.value)}
              >
                <MenuItem value="VND">VND</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </FormControl>
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              type="number"
              name="amount"
              label="Số tiền"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.amount && props.touched.amount}
              helperText={<ErrorMessage name="amount" />}
            />
            {props.values.amount !== "" && (
              <Typography variant="subtitle1" sx={{ marginLeft: "10px" }}>
                {currency == "VND"
                  ? number.format(props.values.amount) + ` ₫`
                  : numberthem.format(
                      Math.round(props.values.amount * 100) / 100
                    ) + ` $`}
              </Typography>
            )}
            <FormControl
              style={{ marginTop: "0.5rem" }}
              fullWidth
              variant="outlined"
              size="small"
            >
              <InputLabel>Trạng thái giao dịch</InputLabel>
              <Select
                label="Chọn loại tiền tệ"
                name="status"
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="completed">Completed</MenuItem>
                <MenuItem value="on_hold">On hold</MenuItem>
                <MenuItem value="on_hold_7day">On hold 7 day</MenuItem>
              </Select>
            </FormControl>
            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddTransaction;
