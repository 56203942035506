import Images from "components/Avatar/Images";
import {
  Avatar,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const GeneralReport = ({
  status,
  selectUser,
  fromDate,
  toDate,
  salePriceTotal,
  adfeeAmountTotal,
  usersstaffAmountTotal,
  xuatkhobudgetAmountTotal,
  nhapchiphiAmountTotal,
  tienluongAmountTotal,
  trahangchinhAmountTotal,
  trahangAmountTotal,
  bonusAmountTotal,
}) => {
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });

  // Filter and format usersProfit based on status
  const usersProfit = selectUser
    ? status === "" || status === "none"
      ? selectUser.usersProfit
      : selectUser.usersProfit.filter((item) => item.status === status)
    : [];

  // Calculate totals
  const total =
    salePriceTotal -
    adfeeAmountTotal -
    usersstaffAmountTotal -
    xuatkhobudgetAmountTotal -
    nhapchiphiAmountTotal -
    bonusAmountTotal -
    tienluongAmountTotal +
    trahangchinhAmountTotal +
    trahangAmountTotal;
  return (
    <div>
      <Paper variant="outlined">
        <Grid container spacing={0}>
          {selectUser && (
            <Grid item xs={3}>
              <ListItem>
                <ListItemAvatar>
                  {selectUser.avatarUrl ? (
                    <Images nameImage={selectUser.avatarUrl} />
                  ) : (
                    <Avatar alt="User Avatar" src="" />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography>{selectUser.name}</Typography>}
                  secondary={selectUser.roles}
                />
              </ListItem>
            </Grid>
          )}
        </Grid>

        {!selectUser && (
          <Typography
            color="primary"
            variant="subtitle2"
            sx={{
              fontSize: "16px",
              margin: (theme) => theme.spacing(2, 0, 1, 2),
            }}
          >
            Báo cáo chung cho tất cả người dùng và tất cả sản phẩm
          </Typography>
        )}

        <Divider sx={{ margin: (theme) => theme.spacing(0, 2, 2, 2) }} />

        <TableContainer sx={{ width: { md: "100%", xs: "88vw" } }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Phạm vi ngày
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{
                      color: "#546e7a",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    Tổng doanh số bán hàng
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{
                      color: "#546e7a",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    Tổng chi phí đầu ra
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{
                      color: "#546e7a",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    Tổng trả hàng
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{
                      color: "#546e7a",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    Tổng lương nhân viên
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{
                      color: "#546e7a",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    Tổng ứng lương
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{
                      color: "#546e7a",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    Tổng tiền thưởng
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{
                      color: "#546e7a",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    Tổng lợi nhuận
                  </Typography>
                </TableCell>

                {status !== "" &&
                  status !== "none" &&
                  usersProfit.map((profit, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        border: 0,
                        padding: (theme) => theme.spacing(1, 2),
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#546e7a",
                          fontWeight: 500,
                          fontSize: "16px",
                        }}
                      >
                        Tổng lợi nhuận {profit.status}
                      </Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {`${fromDate} - ${toDate}`}
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(
                    status !== "" && status !== "none" && usersProfit.length > 0
                      ? salePriceTotal
                      : 0
                  )}{" "}
                  ₫
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(
                    status !== "" && status !== "none" && usersProfit.length > 0
                      ? Number(
                          adfeeAmountTotal +
                            xuatkhobudgetAmountTotal +
                            nhapchiphiAmountTotal
                        )
                      : 0
                  )}{" "}
                  ₫
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(
                    status !== "" && status !== "none" && usersProfit.length > 0
                      ? Number(trahangchinhAmountTotal) +
                          Number(trahangAmountTotal)
                      : 0
                  )}{" "}
                  ₫
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(
                    status !== "" && status !== "none" && usersProfit.length > 0
                      ? usersstaffAmountTotal
                      : 0
                  )}{" "}
                  ₫
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(
                    status !== "" && status !== "none" && usersProfit.length > 0
                      ? tienluongAmountTotal
                      : 0
                  )}{" "}
                  ₫
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(
                    status !== "" && status !== "none" && usersProfit.length > 0
                      ? bonusAmountTotal
                      : 0
                  )}{" "}
                  ₫
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(
                    status !== "" && status !== "none" && usersProfit.length > 0
                      ? total
                      : 0
                  )}{" "}
                  ₫
                </TableCell>

                {status !== "" &&
                  status !== "none" &&
                  usersProfit.map((profit, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        border: 0,
                        padding: (theme) => theme.spacing(1, 2),
                      }}
                    >
                      {profit.price} % {"=> "}
                      {number.format((total * profit.price) / 100)} ₫
                    </TableCell>
                  ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default GeneralReport;
