import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteCheckinCheckout from "./DeleteCheckinCheckout";
import { differenceInMinutes, parseISO } from "date-fns";
import ButtonCheckinCheckout from "./ButtonCheckinCheckout";
import SearchCheckinCheckout from "./SearchCheckinCheckout";
import DataTimeCheckinCheckout from "./DataTimeCheckinCheckout";
import CompleteCheckinCheckout from "./CompleteCheckinCheckout";
const TableCheckinCheckout = (props) => {
  const {
    product,
    handleCurrentDelete,
    userId,
    setSearchStatus,
    onSearchUser,
    usersData,
  } = props;

  const showToast = ({ message, status }) => {
    handleCurrentDelete();
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <ButtonCheckinCheckout
        product={product}
        data={userId}
        onToast={showToast}
      />
      <SearchCheckinCheckout
        setSearchStatus={(e) => setSearchStatus(e)}
        usersData={usersData}
        onSearchUser={(e) => onSearchUser(e)}
      />
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Nơi làm việc</TableCell>
              <TableCell>Số tiền 1h</TableCell>
              <TableCell>Số tiền nhận được</TableCell>
              <TableCell>Thời gian làm việc</TableCell>
              <TableCell>Trạng thái</TableCell>
              <TableCell>Thời gian vào</TableCell>
              <TableCell>Thời gian về</TableCell>
              <TableCell>Ngày tạo</TableCell>
              {userId.role !== "Staff" && <TableCell></TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              const nullCreate = row.createdAt.split("T");
              const dateCreate = nullCreate[0].split("-");
              const dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              const timeCreate = nullCreate[1].split(".");
              const timeCreateAt = timeCreate[0];

              const nullUpdate = row.updatedAt.split("T");
              const timeUpdate = nullUpdate[1].split(".");
              const timeUpdateAt = timeUpdate[0];
              const createdDate = parseISO(row.createdAt);
              const updatedDate = parseISO(row.updatedAt);
              const minutesDifference = differenceInMinutes(
                updatedDate,
                createdDate
              );
              // const subtotal =
              // (Number(minutesDifference) / 60) * Number(row.price);
              let status = "Đang làm việc";
              if (row.status === "completed") {
                status = "Làm xong";
              } else if (row.status === "pending") {
                status = "Đang chờ";
              } else if (row.status === "inProgress") {
                status = "Đang làm việc";
              } else if (row.status === "done") {
                status = "Đã chốt";
              }
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Tooltip title="Copy" placement="top" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(`${row?.users?.name}`);
                        }}
                      >
                        {row?.users?.name}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{row.statusProduct}</TableCell>
                  <TableCell>{number.format(row.price)} đ</TableCell>
                  <TableCell>{number.format(row.remainAmount)} đ</TableCell>
                  <TableCell>{minutesDifference} phút</TableCell>
                  <TableCell>{status}</TableCell>

                  <TableCell>{timeCreateAt}</TableCell>
                  <TableCell>{timeUpdateAt}</TableCell>
                  <TableCell>{dateCreateAt}</TableCell>
                  {userId.role !== "Staff" && (
                    <TableCell size="small" align="center">
                      {row.status !== "done" &&
                        Number(row?.users?.fixedSalary) === 0 && (
                          <DataTimeCheckinCheckout
                            id={row.id}
                            onToast={showToast}
                            onCheckedChange={(e) => handleCurrentDelete(e)}
                            updatedDate={row.updatedAt}
                            createDate={row.createdAt}
                            row={row}
                          />
                        )}
                      <CompleteCheckinCheckout
                        id={row.id}
                        row={row}
                        onToast={showToast}
                        onCheckedChange={(e) => handleCurrentDelete(e)}
                      />
                      {row.status !== "done" && (
                        <DeleteCheckinCheckout
                          id={row.id}
                          onToast={showToast}
                          onCheckedChange={(e) => handleCurrentDelete(e)}
                        />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default TableCheckinCheckout;
