import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NavLinkButton from "components/NavLinks/NavLinkButton";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import { NavLink } from "react-router-dom";
const Discount = (props) => {
  const { product } = props;

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Phần trăm</TableCell>
              <TableCell>Trạng thái</TableCell>
              <TableCell>Ngày tạo</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              var nullCreate = row.createdAt.split("T");
              var dateCreate = nullCreate[0].split("-");
              var dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              let status = "";
              if (row.status === "stop") {
                status = "Ngừng";
              } else if (row.status === "default") {
                status = "Hoạt động";
              }
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <NavLink
                      style={{ color: "blue", textDecoration: "none" }}
                      to={`/manager/discount/edit/${row.id}`}
                    >
                      {row.id}
                    </NavLink>
                  </TableCell>

                  <TableCell>{row.percent}</TableCell>
                  <TableCell>{status}</TableCell>

                  <TableCell>{dateCreateAt}</TableCell>
                  <TableCell size="small" align="center">
                    <NavLinkButton href={`/manager/discount/edit/${row.id}`}>
                      <IconButton color="primary">
                        <EditIcon />
                      </IconButton>
                    </NavLinkButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Discount;
