import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Checkbox, FormControlLabel, Grid, Paper } from "@mui/material";
import AutocompleteSearch from "components/Mui/Autocompletes/AutocompleteSearch";
import SearchInput from "hooks/useSearchInput";
import React from "react";

const SearchClients = (props) => {
  const {
    searchCode,
    setSearchStatus,
    clientData,
    setSearchClient,
    setSearchRemain,
  } = props;
  return (
    <div>
      <Grid
        container
        sx={{ width: "100%", margin: "40px auto", height: "auto" }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Trạng thái giao dịch</InputLabel>
                <Select
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                  }}
                  label="Trạng thái giao dịch"
                  name="status"
                >
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="on_hold">On hold</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="on_hold_7day">On hold 7 day</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <AutocompleteSearch
                data={clientData}
                size="large"
                label="Khách hàng"
                onCheckedChange={(e) => setSearchClient(e)}
                optionLabel={(label) => label.fullName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <SearchInput
                onCheckedChange={(e) => searchCode(e)}
                placeholder="Tìm kiếm theo mã giao dịch"
              />
            </Grid>
          </Grid>
          <br />
          <FormControlLabel
            name="remain"
            control={
              <Checkbox
                onChange={(e) => {
                  setSearchRemain(e.target.checked);
                }}
                name="remain"
                color="primary"
              />
            }
            label="Show has remain amout only"
            color="primary"
          />
        </Paper>
      </Grid>
    </div>
  );
};

export default SearchClients;
