import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
const TableTienLuongReport = (props) => {
  const { result } = props;
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <TableContainer
      component={Paper}
      sx={{ width: { md: "100%", xs: "88vw" }, marginTop: "30px" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Ngày tạo</TableCell>
            <TableCell>Người ứng lương</TableCell>
            <TableCell>Số tiền</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {result.map((row, id) => {
            const nullCreate = row.createdAt.split("T");
            const dateCreate = nullCreate[0].split("-");
            const dateCreateAt =
              dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];

            return (
              <TableRow key={id}>
                <TableCell>{dateCreateAt}</TableCell>
                <TableCell>{row.users.name}</TableCell>
                <TableCell>{number.format(row.price)} đ</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableTienLuongReport;
