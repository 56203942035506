import { Grid, Paper } from "@mui/material";
import SearchInput from "hooks/useSearchInput";
import React from "react";

const TelegramApiSearch = ({ searchName }) => {
  return (
    <div>
      <Grid
        container
        sx={{ width: "100%", margin: "40px auto", height: "auto" }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <SearchInput
                onCheckedChange={(e) => searchName(e)}
                name="name"
                placeholder="Search by name"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default TelegramApiSearch;
