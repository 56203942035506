import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import BudgetsWallets from "../hooks/useBudgets";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Formik } from "formik";
import UpdateFile from "components/Mui/Uploads/UpdateFile";
import useLinks from "hooks/useLinks";
const EditWallets = ({ gameData, ngansachkhoData }) => {
  const { id } = useParams();
  const linkImage = useLinks();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [image, setImage] = React.useState(null);
  const [input, setInput] = React.useState({
    name: "",
    no: "",
    notes: "",
    orderNumber: "",
    price: "",
  });
  const [selectedGame, setSelectedGame] = React.useState({
    name: "",
    id: "",
  });
  const [inputFields, setInputFields] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetchData = async () => {
    Promise.all([axiosusers.get(`/wallets/wallets/${id}`)])
      .then(async (results) => {
        const [data] = results.map((res) => res.data);
        const gameId = data.product;
        setInput(data);
        setInputFields(data.budgets);
        setSelectedGame({
          name: gameId?.name,
          id: gameId?.id,
        });
      })
      .finally(() => setLoading(true))
      .catch((error) => console.error(error));
  };
  React.useEffect(() => {
    fetchData();
  }, [id]);
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeBudgets = (value) => {
    setInputFields(value);
  };
  const onSubmit = async () => {
    let errorMessage = "";
    if (selectedGame?.id === undefined) {
      errorMessage = "Sản phẩm là bắt buộc";
    }
    if (inputFields !== undefined) {
      inputFields.forEach((item) => {
        if (item.name === "" || item.price === "") {
          errorMessage =
            "Ngân sách dữ liệu không được trống. Vui lòng kiểm tra lại";
        }
      });
    }

    if (errorMessage !== "") {
      toast.error(errorMessage, {
        position: "top-right",
      });
      return setErrorMessage(errorMessage);
    }
    const data = {
      id,
      name: input.name,
      price: input.price,
      productId: selectedGame.id,
      notes: input.notes,
      orderNumber: input.orderNumber,
      inputBudgets: inputFields,
    };
    try {
      const response = await axiosusers.post(`/wallets/update-wallets`, data);
      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/thucdonsanpham");
        }, 500);
        const formData = new FormData();

        formData.append("walletId", id);
        formData.append("file", image);

        try {
          const response = await axiosusers.post(
            `/wallets/wallets-photos`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({});
  return (
    <Box sx={{ marginTop: "1rem" }}>
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {loading && (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form autoComplete="off">
                <TextField
                  onChange={handleinput}
                  type="number"
                  value={input.orderNumber}
                  name="orderNumber"
                  error={Boolean(input.orderNumber === "")}
                  helperText={input.orderNumber === "" ? "No is required" : ""}
                  label="No"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
                <Autocomplete
                  sx={{ marginTop: "0.5rem" }}
                  options={gameData}
                  fullWidth
                  value={selectedGame}
                  onChange={(event, value) => setSelectedGame(value)}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(jsonResuls) => jsonResuls.name}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(selectedGame === null)}
                      helperText={
                        selectedGame === null ? "Sản Phẩm bắt buộc" : ""
                      }
                      label="Sản Phẩm"
                      variant="outlined"
                    />
                  )}
                />
                <TextField
                  sx={{ marginTop: "0.5rem" }}
                  onChange={handleinput}
                  type="text"
                  value={input.name}
                  name="name"
                  error={Boolean(input.name === "")}
                  helperText={
                    input.name === "" ? "Tên thực đơn là bắt buộc" : ""
                  }
                  label="Tên thực đơn"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  sx={{ marginTop: "0.5rem" }}
                  onChange={handleinput}
                  type="text"
                  value={input.price}
                  name="price"
                  error={Boolean(input.price === "")}
                  helperText={input.name === "" ? "Giá tiền là bắt buộc" : ""}
                  label="Giá tiền"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    marginTop: `0.5rem`,
                  }}
                >
                  Ngân sách:
                </Typography>
                <BudgetsWallets
                  onCheckedChange={handleChangeBudgets}
                  data={input.budgets}
                  ngansachkhoData={ngansachkhoData}
                  input={input}
                />
                <TextField
                  label="Ghi chú"
                  multiline
                  minRows="4"
                  onChange={handleinput}
                  name="notes"
                  value={input.notes}
                  sx={{ margin: (theme) => theme.spacing(2, 0, 2, 0) }}
                  variant="outlined"
                  fullWidth
                />
                {!image && input.image !== "" && (
                  <img
                    src={`${linkImage}${input.image}`}
                    crossOrigin="anonymous"
                    style={{
                      width: "200px",
                      height: "200px",
                      margin: "1rem 0 0 0",
                    }}
                  />
                )}
                <br />
                <UpdateFile handPhotoChange={(e) => setImage(e)} />
                {errorMessage && (
                  <Alert sx={{ marginTop: `0.5rem` }} severity="error">
                    {errorMessage}
                  </Alert>
                )}
                <Button
                  type="submit"
                  sx={{ marginTop: `2rem` }}
                  variant="contained"
                  size="large"
                  fullWidth
                  color="primary"
                >
                  Update
                </Button>
              </Form>
            )}
          </Formik>
          <ToastContainer
            style={{
              top: "64px",
              right: "0",
              width: "max-content",
            }}
          />
        </>
      )}
    </Box>
  );
};

export default EditWallets;
