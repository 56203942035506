import React from "react";
import AddBudgets from "./AddBudgets";
import { Tabtitle } from "components/title/Title";
import { Box } from "@mui/material";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";

const index = () => {
  Tabtitle("Tạo thực đơn chính");

  return (
    <div>
      <Box sx={{ maxWidth: 800, margin: `auto` }}>
        <TypographyAddEdit name="Tạo thực đơn  chính" />
        <Box sx={{ marginTop: "1.5rem" }}>
          <AddBudgets />
        </Box>
      </Box>
    </div>
  );
};

export default index;
