import NavLinkAdd from "components/NavLinks/NavLinkAdd";
import { Tabtitle } from "components/title/Title";
import React, { useEffect, useState } from "react";
import SearchProduct from "./Main/SearchProduct";
import axiosusers from "utils/api/axiosusers";
import TableProducts from "./Main/TableProducts";
import { Box, CircularProgress } from "@mui/material";
import BasicPagination from "hooks/usePagination";

const MAX_RETRIES = 3;

const Index = () => {
  Tabtitle("Sản phẩm");

  const [searchName, setSearchName] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [retryCount, setRetryCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);

  const fetchDataAll = async () => {
    try {
      const response = await axiosusers.get("/products/products", {
        params: {
          searchName,
          pageSize,
          searchStatus,
          currentPage: currentPage === 0 ? 1 : currentPage,
        },
      });
      const res = response.data;
      setProduct(res.products);
      setPageCount(Math.ceil(res.pages / pageSize));
      setLoading(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("401 Unauthorized. Retrying...");
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          fetchDataAll();
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [searchName, searchStatus, pageSize, currentPage]);

  return (
    <div>
      <NavLinkAdd href="/manager/sanpham/add" name="Tạo sản phẩm" />
      <SearchProduct
        searchName={(e) => setSearchName(e)}
        searchStatus={(e) => setSearchStatus(e)}
      />
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {loading && (
        <TableProducts product={product} handleCurrentDelete={fetchDataAll} />
      )}
      <Box sx={{ marginTop: "20px" }}>
        <BasicPagination
          onCheckedChange={(e) => setCurrentPage(e)}
          pageCount={pageCount}
          currentPage={currentPage}
        />
      </Box>
    </div>
  );
};

export default Index;
