import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CompleteOrders = ({ orders, userId, payment, paymentData }) => {
  const payments = payment ? payment : paymentData[0];

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const onSubmit = async () => {
    setOpen(true);

    const dataSend = {
      id: orders.id,
      orders,
      payments,
      budgetsmayorders: orders.budgetsmayorders,
    };
    try {
      const response = await axiosusers.post(
        `/orders/complete-orders`,
        dataSend
      );
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          navigate("/manager/donhang");
        }, 500);
      }
    } catch (error) {
      setOpen(false);
      console.error(error);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  const handleDelete = async () => {
    setOpen(true);

    const dataSend = { id: orders.id };
    try {
      const response = await axiosusers.post(`/orders/delete-orders`, dataSend);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          navigate("/manager/donhang");
        }, 500);
      }
    } catch (error) {
      console.error(error);
      setOpen(false);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  return (
    <Box sx={{ margin: "30px 0px" }}>
      {orders.status == "inProgress" && (
        <Button
          variant="contained"
          color="primary"
          onClick={open ? null : onSubmit}
          disabled={open}
        >
          completed
        </Button>
      )}
      {orders.status == "completed" && userId?.role == "Admin" && (
        <Button
          variant="contained"
          color="primary"
          onClick={open ? null : onSubmit}
          disabled={open}
        >
          mark done
        </Button>
      )}
      {orders.status == "done" && userId?.role == "Admin" && (
        <Button
          variant="contained"
          color="primary"
          onClick={open ? null : onSubmit}
          disabled={open}
        >
          undo
        </Button>
      )}
      {userId?.role !== "Admin" && orders.status === "inProgress" && (
        <Button
          variant="contained"
          color="error"
          onClick={open ? null : handleDelete}
          sx={{ marginLeft: "10px" }}
          disabled={open}
        >
          delete
        </Button>
      )}
      {userId?.role === "Admin" && orders.status !== "done" && (
        <Button
          variant="contained"
          color="error"
          onClick={open ? null : handleDelete}
          sx={{ marginLeft: "10px" }}
          disabled={open}
        >
          delete
        </Button>
      )}
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </Box>
  );
};

export default CompleteOrders;
