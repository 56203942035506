import { Grid, Paper } from "@mui/material";
import React from "react";
import SearchInput from "hooks/useSearchInput";
import AutocompleteSearch from "components/Mui/Autocompletes/AutocompleteSearch";
const Wallets = (props) => {
  const { gamesData, onCheckedChange, handleSearchName } = props;

  return (
    <div>
      <Grid
        container
        sx={{ width: "100%", margin: "40px auto", height: "auto" }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AutocompleteSearch
                data={gamesData}
                size="large"
                label="Sản phẩm"
                onCheckedChange={(e) => onCheckedChange(e)}
                optionLabel={(label) => label.name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <SearchInput
                onCheckedChange={(e) => handleSearchName(e.name)}
                name="name"
                placeholder="Tìm kiếm theo tên"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default Wallets;
