import { Box } from "@mui/material";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";
import { Tabtitle } from "components/title/Title";
import React from "react";
import AddWarehouse from "./AddWarehouse";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";

const index = () => {
  Tabtitle("Tạo Thực Đơn");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: productsData } = useSWR(`/products/products-all`, fetchDataSwr);

  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <TypographyAddEdit name="Tạo Thực Đơn" />
      <Box sx={{ marginTop: "1.5rem" }}>
        <AddWarehouse
          productsData={productsData === undefined ? [] : productsData}
        />
      </Box>
    </Box>
  );
};

export default index;
