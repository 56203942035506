import React from "react";
import AddUsers from "./AddTelegramApi";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Create Telegram API");
  return (
    <div>
      <AddUsers />
    </div>
  );
};

export default index;
