import React from "react";
import { Tabtitle } from "components/title/Title";
import SearchReport from "./SearchReport";
import useSWR from "swr";
import axiosusers from "utils/api/axiosusers";
import { format } from "date-fns";
import GeneralReport from "./GeneralReport";
import TableReport from "./TableReport";

const index = () => {
  Tabtitle("Reports");
  const data = JSON.parse(localStorage.getItem("auth"));
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const { data: userData } = useSWR(`/users/users-all`, fetchDataSwr);
  const { data: productData } = useSWR(`/products/products-all`, fetchDataSwr);
  const [selectGame, setSelectGame] = React.useState(null);
  const [selectUser, setSelectUser] = React.useState(null);
  const [product, setProduct] = React.useState([]);
  const [selectProduct, setSelectProduct] = React.useState(null);
  const [value, setValue] = React.useState([null, null]);
  const [xuatkho, setXuatkho] = React.useState([]);
  const [usersstaff, setUsersstaff] = React.useState([]);
  const [xuatkhobudget, setXuatkhobudget] = React.useState([]);
  const [nhapchiphi, setNhapchiphi] = React.useState([]);
  const [trahangchinh, setTrahangchinh] = React.useState([]);
  const [trahang, setTrahang] = React.useState([]);
  const [searchStatus, setSearchStatus] = React.useState("");
  const onSearchUser = (data) => {
    setSelectUser(data);
  };
  const onSearchProduct = (data) => {
    setSelectProduct(data);
  };
  const onSearchGame = (data) => {
    setSelectGame(data);
  };
  const onChangeData = (data) => {
    setValue(data);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const ensureDate = (createdAt) => {
    return createdAt ? createdAt.slice(0, 10) : formatDate(new Date());
  };

  const fetchData = async () => {
    Promise.all([
      axiosusers.get(`/reports/reports-orders`, {
        params: {
          from: formatDate(new Date(value[0])) + "T00:00:00.000Z",
          to: formatDate(new Date(value[1])) + "T23:59:59.000Z",
          userId: data.role !== "Staff" ? selectUser?.id : data?.id,
          productId: selectProduct?.id,
          statusOrder: searchStatus,
        },
      }),
    ])
      .then((results) => {
        const [orders] = results.map((res) => res.data);
        setXuatkho(orders.xuatkho);
        setProduct(orders.orders);
        setUsersstaff(orders.usersstaff);
        setXuatkhobudget(orders.nhapkhochinh);
        setNhapchiphi(orders.nhapchiphi);
        setTrahangchinh(orders.trahangchinh);
        setTrahang(orders.trahang);
      })
      .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    if (data) {
      fetchData();
    }
  }, [selectGame, selectUser, value, selectProduct, searchStatus]);

  const fromDate = value[0]
    ? format(value[0], "dd/MM/yyyy")
    : format(new Date(), "dd/MM/yyyy");
  const toDate = value[1]
    ? format(value[1], "dd/MM/yyyy")
    : format(new Date(), "dd/MM/yyyy");

  const productId = product.filter((order) => {
    if (data?.role === "Staff") {
      return data?.id === order.userId;
    }
    return true;
  });

  // Tạo danh sách các ngày từ productId và xuatkho
  const dates = productId.reduce((acc, cur) => {
    const date = ensureDate(cur.createdAt);
    if (!acc[date]) {
      acc[date] = true;
    }
    return acc;
  }, {});

  xuatkho.forEach((item) => {
    const date = ensureDate(item.createdAt);
    if (!dates[date]) {
      dates[date] = true;
    }
  });
  usersstaff.forEach((item) => {
    const date = ensureDate(item.createdAt);
    if (!dates[date]) {
      dates[date] = true;
    }
  });
  xuatkhobudget.forEach((item) => {
    const date = ensureDate(item.createdAt);
    if (!dates[date]) {
      dates[date] = true;
    }
  });
  nhapchiphi.forEach((item) => {
    const date = ensureDate(item.createdAt);
    if (!dates[date]) {
      dates[date] = true;
    }
  });
  trahangchinh.forEach((item) => {
    const date = ensureDate(item.createdAt);
    if (!dates[date]) {
      dates[date] = true;
    }
  });
  trahang.forEach((item) => {
    const date = ensureDate(item.createdAt);
    if (!dates[date]) {
      dates[date] = true;
    }
  });
  const sortedDates = Object.keys(dates).sort(
    (a, b) => new Date(b) - new Date(a)
  );

  const result = sortedDates.map((date) => {
    const orderCount = productId.reduce((acc, cur) => {
      if (ensureDate(cur.createdAt) === date) {
        acc += 1;
      }
      return acc;
    }, 0);

    const salePrice = productId.reduce((acc, cur) => {
      if (ensureDate(cur.createdAt) === date) {
        acc += cur.salePrice ? Number(cur.salePrice) : 0;
      }
      return acc;
    }, 0);

    const basePrice = productId.reduce((acc, cur) => {
      if (ensureDate(cur.createdAt) === date) {
        acc += cur.basePrice ? Number(cur.basePrice) : 0;
      }
      return acc;
    }, 0);

    const bonusAmount = productId.reduce((acc, cur) => {
      if (ensureDate(cur.createdAt) === date) {
        acc += cur.bonusAmount ? Number(cur.bonusAmount) : 0;
      }
      return acc;
    }, 0);

    const adFreeAmount = xuatkho.reduce((acc, cur) => {
      if (ensureDate(cur.createdAt) === date) {
        acc += cur.price ? Number(cur.price) * Number(cur.quantity) : 0;
      }
      return acc;
    }, 0);

    const usersstaffAmount = usersstaff.reduce((acc, cur) => {
      if (ensureDate(cur.createdAt) === date) {
        acc += cur.remainAmount ? Number(cur.remainAmount) : 0;
      }
      return acc;
    }, 0);
    const xuatkhobudgetAmount = xuatkhobudget.reduce((acc, cur) => {
      if (ensureDate(cur.createdAt) === date) {
        acc += cur.price ? Number(cur.price) * Number(cur.quantity) : 0;
      }
      return acc;
    }, 0);
    const nhapchiphiAmount = nhapchiphi.reduce((acc, cur) => {
      if (ensureDate(cur.createdAt) === date) {
        acc += cur.price ? Number(cur.price) * Number(cur.quantity) : 0;
      }
      return acc;
    }, 0);
    const trahangchinhAmount = trahangchinh.reduce((acc, cur) => {
      if (ensureDate(cur.createdAt) === date) {
        acc += cur.price ? Number(cur.price) * Number(cur.quantity) : 0;
      }
      return acc;
    }, 0);
    const trahangAmount = trahang.reduce((acc, cur) => {
      if (ensureDate(cur.createdAt) === date) {
        acc += cur.price ? Number(cur.price) * Number(cur.quantity) : 0;
      }
      return acc;
    }, 0);
    return {
      date,
      orderCount,
      salePrice,
      basePrice,
      bonusAmount,
      adFreeAmount,
      usersstaffAmount,
      xuatkhobudgetAmount,
      nhapchiphiAmount,
      trahangchinhAmount,
      trahangAmount,
    };
  });

  const orderTotal = product.reduce((acc) => {
    return acc + 1;
  }, 0);

  const salePriceTotal = product.reduce((acc, order) => {
    return acc + Number(order.salePrice);
  }, 0);

  const basePriceTotal = product.reduce((acc, order) => {
    return acc + Number(order.basePrice);
  }, 0);

  const bonusAmountTotal = product.reduce((acc, order) => {
    return acc + Number(order.bonusAmount);
  }, 0);

  const adfeeAmountTotal = result.reduce((acc, order) => {
    return acc + Number(order.adFreeAmount);
  }, 0);

  const usersstaffAmountTotal = result.reduce((acc, order) => {
    return acc + Number(order.usersstaffAmount);
  }, 0);
  const xuatkhobudgetAmountTotal = result.reduce((acc, order) => {
    return acc + Number(order.xuatkhobudgetAmount);
  }, 0);
  const nhapchiphiAmountTotal = result.reduce((acc, order) => {
    return acc + Number(order.nhapchiphiAmount);
  }, 0);
  const trahangchinhAmountTotal = result.reduce((acc, order) => {
    return acc + Number(order.trahangchinhAmount);
  }, 0);
  const trahangAmountTotal = result.reduce((acc, order) => {
    return acc + Number(order.trahangAmount);
  }, 0);
  return (
    <>
      <SearchReport
        data={data}
        userData={userData === undefined ? [] : userData}
        productData={productData === undefined ? [] : productData}
        onSearchUser={onSearchUser}
        onSearchGame={onSearchGame}
        onChangeData={onChangeData}
        onSearchProduct={onSearchProduct}
        setSearchStatus={(e) => setSearchStatus(e)}
      />
      <GeneralReport
        data={data}
        selectUser={selectUser}
        selectProduct={selectProduct}
        fromDate={fromDate}
        toDate={toDate}
        orderTotal={orderTotal}
        salePriceTotal={salePriceTotal}
        basePriceTotal={basePriceTotal}
        bonusAmountTotal={bonusAmountTotal}
        adfeeAmountTotal={adfeeAmountTotal}
        usersstaffAmountTotal={usersstaffAmountTotal}
        xuatkhobudgetAmountTotal={xuatkhobudgetAmountTotal}
        nhapchiphiAmountTotal={nhapchiphiAmountTotal}
        trahangchinhAmountTotal={trahangchinhAmountTotal}
        trahangAmountTotal={trahangAmountTotal}
      />
      {result.length > 0 && <TableReport data={data} result={result} />}
    </>
  );
};

export default index;
