import React, { useEffect, useState } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";

import axiosusers from "utils/api/axiosusers";
import FilledAlerts from "components/Alert/FilledAlerts";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const EditUsers = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [input, setInput] = useState({
    fullName: true,
    token: true,
    idToken: true,
    notes: true,
    idTokenToal: true,
    idTokenMuave: true,
    idTokenKho: true,
  });

  const [errorMessage, seterrorMessage] = React.useState(false);
  const getProduct = async () => {
    const data = await axiosusers.get(`/telegramApi/telegramApi/${id}`);

    setInput(data.data);
  };

  useEffect(() => {
    getProduct();
  }, [id]);
  const [state, setState] = React.useState({
    allowZalo: false,
    allowSms: false,
  });

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setState({ ...state, [e.target.name]: e.target.checked });
  };

  const onSubmit = async () => {
    const data = {
      id,
      fullName: input.fullName,
      token: input.token,
      idToken: input.idToken,
      notes: input.notes,
      idTokenToal: input.idTokenToal,
      idTokenMuave: input.idTokenMuave,
      idTokenKho: input.idTokenKho,
    };
    try {
      const res = await axiosusers.post(
        `/telegramApi/update-telegramApi`,
        data
      );
      console.log(res);
      setTimeout(function () {
        navigate("/manager/telegram-api");
      }, 500);
      toast.success(res.data.message, {
        position: "top-right",
      });
    } catch (error) {
      console.log(error);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Update Telegram API
        </Typography>
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.fullName}
          name="fullName"
          label="fullName"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.token}
          name="token"
          label="Token"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.idToken}
          name="idToken"
          label="Telegram ID"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.idTokenToal}
          name="idTokenToal"
          label="Telegram ID Group Total"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.idTokenMuave}
          name="idTokenMuave"
          label="Telegram ID Mua về"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.idTokenKho}
          name="idTokenKho"
          label="Telegram ID Kho"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `1rem` }}
          onChange={handleinput}
          multiline
          minRows={4}
          value={input.notes}
          name="notes"
          label="Ghi chú"
          fullWidth
        />
        {errorMessage && (
          <FilledAlerts message={errorMessage} success="error" />
        )}
        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </Box>
  );
};

export default EditUsers;
