import React from "react";

import PrintDetail from "./PrintDetail";
import GridsDetailOrders from "./hocks/GridsDetailOrders";
import BudgetOrders from "./hocks/BudgetOrders";
import { Box, Button, Divider } from "@mui/material";
import PhotosOrders from "./hocks/PhotosOrders";
import UploadMultiple from "components/Mui/Uploads/UploadMultiple";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CompleteOrders from "./CompleteOrders";
import TransactionsDetail from "./hocks/TransactionsDetail";
import DanhsachbanDetail from "./hocks/DanhsachbanDetail";
import ThembanOrders from "./hocks/ThembanOrders";
import TachDonDetail from "./TachDon/TachDonDetail";
import Delivered from "./Delivered/Delivered";
import VouchersDetail from "./VouchersDetail/VouchersDetail";
const DetailOrders = (props) => {
  const {
    orders,
    deletePhotoOrders,
    userId,
    paymentData,
    danhsachbanData,
    danhsachbanDatas2,
    ordersInprogress,
  } = props;
  const [payment, setPayment] = React.useState(false);
  let status = "Chưa hoàn thành";
  if (orders.status === "completed") {
    status = "Hoàn thành";
  } else if (orders.status === "pending") {
    status = "Đang chờ";
  } else if (orders.status === "inProgress") {
    status = "Đang xử lý";
  } else if (orders.status === "done") {
    status = "Đã xong";
  }
  let paymentStatus = "Chưa thanh toán";
  if (orders.paymentStatus === "paid") {
    paymentStatus = "Đã thanh toán";
  } else if (orders.paymentStatus === "pending") {
    paymentStatus = "Đang chờ";
  } else if (orders.paymentStatus === "unPaid") {
    paymentStatus = "Chưa thanh toán";
  } else if (orders.paymentStatus === "partPaid") {
    paymentStatus = "Thanh toán một phần";
  }

  const nullCreate = orders.createdAt.split("T");
  const dateCreate = nullCreate[0].split("-");
  const dateCreateAt =
    dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
  const timeCreate = nullCreate[1].split(".");

  const nullUpdate = orders.updatedAt.split("T");
  const dateUpdate = nullUpdate[0].split("-");
  const dateUpdateAt =
    dateUpdate[2] + "/" + dateUpdate[1] + "/" + dateUpdate[0];
  const timeUpdate = nullUpdate[1].split(".");
  const [avatar, setAvatar] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handPhotoChange = (photo) => {
    setAvatar(photo);
  };
  const onSubmit = async () => {
    setOpen(true);
    let errorMessage = "";
    avatar.map((item) => {
      if (item.file.size > 2 * 1024 * 1024) {
        errorMessage = "size must be less than 2MB";
      } else if (
        item.file.type !== "image/png" &&
        item.file.type !== "image/jpg" &&
        item.file.type !== "image/jpeg"
      ) {
        errorMessage = "file type must be PNG or JPG or JPEG";
      }
    });
    if (errorMessage) {
      toast.success(errorMessage, {
        position: "top-right",
      });
      return;
    }

    avatar
      .sort((a, b) => a.size - b.size)
      .map(async (image) => {
        const formData = new FormData();
        formData.append("file", image.file);
        formData.append("orderId", orders.id);
        formData.append("status", "photo");
        try {
          const response = await axiosusers.post(
            `/orders/ordersphotos`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            toast.success(response.data.message, {
              position: "top-right",
            });

            setTimeout(function () {
              deletePhotoOrders();
              window.location.reload();
            }, 500);
          }
        } catch (error) {
          setOpen(false);
          console.log(error);
        }
      });
  };
  const [total, setTotal] = React.useState(0);
  const onTotal = (e) => {
    setTotal(e);
  };
  const onChanePayment = (e) => {
    setPayment(e);
  };
  return (
    <>
      <GridsDetailOrders
        nameOne="ID đơn hàng:"
        nameTwo={orders?.id}
        gridmain={false}
      />
      <GridsDetailOrders
        nameOne="Khách hàng:"
        nameTwo={orders?.client?.fullName}
        gridmain={false}
      />
      <GridsDetailOrders
        nameOne="Trạng thái:"
        nameTwo={status}
        gridmain={false}
      />
      <GridsDetailOrders
        nameOne="Tình trạng thanh toán:"
        nameTwo={paymentStatus}
        gridmain={false}
      />
      <ThembanOrders danhsachbanData={danhsachbanData} orders={orders} />

      {orders.budgetsmayorders.length > 0 && (
        <DanhsachbanDetail orders={orders} />
      )}
      {orders.status === "inProgress" && (
        <TachDonDetail
          orders={orders}
          danhsachbanData={danhsachbanData}
          ordersInprogress={ordersInprogress}
          danhsachbanDatas2={danhsachbanDatas2}
        />
      )}
      <Box style={{ padding: 10 }}>
        <BudgetOrders orders={orders} onTotal={onTotal} print={false} />
      </Box>
      {paymentData.length > 0 && (
        <PrintDetail
          orders={orders}
          paymentData={paymentData}
          total={total}
          onChanePayment={onChanePayment}
        />
      )}

      {orders.status === "inProgress" && <Delivered orders={orders} />}
      {orders.status === "inProgress" && (
        <VouchersDetail orders={orders} total={total} />
      )}
      <Divider style={{ backgroundColor: "#000", margin: "10px 0 0 4px" }} />
      {orders.paymentStatus !== "unPaid" && (
        <TransactionsDetail
          transactionsorders={orders.transactionsorders}
          orders={orders}
        />
      )}
      <GridsDetailOrders
        nameOne="Ghi chú:"
        nameTwo={orders.notes}
        gridmain={false}
      />
      <GridsDetailOrders
        nameOne="Mua về:"
        nameTwo={orders.muave}
        gridmain={false}
      />
      <GridsDetailOrders
        nameOne="Ngày tạo:"
        nameTwo={dateCreateAt + " " + timeCreate[0]}
        gridmain={false}
      />
      <GridsDetailOrders
        nameOne="Cập nhật ngày tháng:"
        nameTwo={dateUpdateAt + " " + timeUpdate[0]}
        gridmain={false}
      />
      <GridsDetailOrders
        nameOne="Được tạo bởi:"
        nameTwo={orders.user.name}
        gridmain={false}
      />
      <PhotosOrders
        images={orders.orderphotos}
        deletePhotoOrders={deletePhotoOrders}
        deletePhoto={true}
      />
      <Box sx={{ marginTop: `1rem` }}>
        <UploadMultiple handPhotoChange={handPhotoChange} />
      </Box>
      {avatar.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          onClick={!open ? onSubmit : null}
          disabled={open}
        >
          Upload Photos
        </Button>
      )}
      {!open && (
        <CompleteOrders
          orders={orders}
          userId={userId}
          paymentData={paymentData}
          payment={payment}
        />
      )}
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </>
  );
};

export default DetailOrders;
