import React, { useState } from "react";
import printJS from "print-js";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import axiosusers from "utils/api/axiosusers";
import GetVietQR from "./hocks/GetVietQR";
import PrintSubmit from "./Print/PrintSubmit";

const PrintDetail = ({ orders, paymentData, onChanePayment }) => {
  const payments = paymentData.filter((item) => item.devil !== "Quỹ");
  const [value, setValue] = useState(payments[0].id);
  const [open, setOpen] = useState(false);
  const [onTotal, setOnTotal] = useState(0);
  const [maQR, setMaQR] = useState("");
  const [dataVietQR, setDataVietQR] = useState("");

  const handleChange = (event) => {
    onChanePayment(
      payments.find((item) => item.id === Number(event.target.value))
    );
    setValue(event.target.value);
  };

  const handlePrint = () => {
    printJS("print-section", "html");
  };

  const handleButtonClick = async () => {
    let discountDatasTotal = 0;
    if (Number(orders.discount) !== 0) {
      discountDatasTotal =
        Math.ceil(Number(onTotal) / 100) * Number(orders.discount);
    }
    const roundCurrency = (value) => {
      const base = Math.floor(value / 1000);
      const remainder = value % 1000;

      if (remainder < 500) {
        return base * 1000;
      }
      return (base + 1) * 1000;
    };
    const paidAmount = roundCurrency(
      Math.ceil(
        Number(onTotal) - Number(orders.paidAmount) - discountDatasTotal
      )
    );
    if (
      onTotal > 0 &&
      paidAmount > 1999 &&
      orders.paymentStatus !== "paid" &&
      maQR === ""
    ) {
      setOpen(true);
      try {
        const datatime = Date.now();
        const randomNumber = Math.floor(Math.random() * 9000) + 1000;
        const orderVietQR = `${Math.ceil(datatime / 1000000)}${randomNumber}`;

        const extractedFields = [
          {
            name: "Coffee",
            quantity: 1,
            price: paidAmount,
          },
        ];
        const data = {
          orderId: orders.id,
          clientId: orders.clientId,
          paymentId: Number(value),
          code: orderVietQR,
          amount: paidAmount,
          status: "pending",
          currency: "VND",
          extractedFields,
          orderVietQR,
          payments: payments.find((item) => item.id === Number(value)),
        };
        const response = await axiosusers.post(
          `/vietqr/create-payment-link`,
          data
        );
        if (response.status === 200) {
          const data = response.data.message;
          if (response.data.vietQR) {
            setDataVietQR(data);
            setMaQR(
              `https://img.vietqr.io/image/${data.bin}-${data.accountNumber}-vietqr_pro.jpg?addInfo=${data.description}&amount=${data.amount}`
            );
          }
          setTimeout(() => {
            handlePrint();
          }, 300);
          setOpen(false);
        }
      } catch (error) {
        setOpen(false);
        console.error(error);
      }
    } else {
      handlePrint();
    }
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <div id="print-section" className="printable-content">
          <style>
            {`
          @media print {
            @page {
                margin: 0;
              }
              body {
                margin: 0cm;
              }
    
            .printable-content {
              visibility: visible;
              padding: 0px 1px;
              display: block;
            }
          }
          @media screen {
            .printable-content {
              visibility:  hidden;
            }
          }
        `}
          </style>
          <PrintSubmit
            orders={orders}
            onTotal={(e) => setOnTotal(e)}
            maQR={maQR}
            dataVietQR={dataVietQR}
          />
        </div>
      </div>
      {dataVietQR !== "" && (
        <GetVietQR dataVietQR={dataVietQR} maQR={maQR} orders={orders} />
      )}

      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">
          Chọn phương thức thanh toán
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {payments.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.id}
              control={<Radio />}
              label={item.owner}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <br />
      {open && <CircularProgress />}
      <br />

      <Button
        variant="contained"
        color="primary"
        onClick={open ? null : handleButtonClick}
        className="print-button"
        disabled={open}
      >
        Print
      </Button>
    </>
  );
};

export default PrintDetail;
