import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";
import UploadMultiple from "components/Mui/Uploads/UploadMultiple";
import { Field, Form, Formik } from "formik";
import AutocompleteId from "hooks/useAutocomplete";
import useUserId from "hooks/useUserId";
import React from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import * as Yup from "yup";
const AddNhapChiPhi = ({ chiphiData }) => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [inputChiphi, setInputChiphi] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const { userId } = useUserId();
  const [input, setInput] = React.useState([]);
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeChiphi = (value) => {
    setInput(value);
    setInputChiphi(value);
  };
  const initialValues = {
    name: "",
    notes: "",
    price: 1,
  };
  const [avatar, setAvatar] = React.useState([]);
  const handPhotoChange = (avatar) => {
    setAvatar(avatar);
  };
  const validationSchema = Yup.object().shape({});
  const onSubmit = async (values) => {
    setDung(true);
    seterrorMessage("");
    // if (image?.size > 2 * 1024 * 1024) {
    //   setDung(false);
    //   return seterrorMessage("size must be less than 2MB");
    // }
    if (status === "") {
      setDung(false);
      return seterrorMessage("Please select status");
    }
    const data = {
      userId: userId.id,
      chiphiId: inputChiphi.id,
      price: input.price,
      quantity: input.usedQuantity,
      notes: values.notes,
    };

    try {
      const response = await axiosusers.post(
        `/nhapchiphi/create-nhapchiphi`,
        data
      );
      console.log(response);
      setDung(false);

      if (response.status === 200) {
        for (const image of avatar.sort((a, b) => a.size - b.size)) {
          const formData = new FormData();
          formData.append("file", image.file);
          formData.append("nhapchiphiId", response.data.nhapchiphiId);
          formData.append("status", "image");

          try {
            await axiosusers.post(`/nhapchiphi/nhapchiphi-photos`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          } catch (error) {
            console.log(error);
          }
        }
        navigate("/manager/nhap-chi-phi/");
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(error?.response?.data?.message);
      setDung(false);
    }
  };
  const onChangeStatus = (value) => {
    setStatus(value);
  };
  console.log(input);
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form autoComplete="off">
            <FormControlProduct onChangeStatus={onChangeStatus} />
            <AutocompleteId
              data={chiphiData.filter((item) => item.status === status)}
              label="Chi phí"
              id="Chi phí"
              name="Chi phí"
              size="small"
              openError={true}
              done={errorMessage && inputChiphi?.id === undefined}
              onCheckedChange={handleChangeChiphi}
              optionLabel={(label) => label.name}
            />

            {inputChiphi?.id !== undefined && (
              <>
                <Box
                  component="form"
                  autoComplete="off"
                  sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
                >
                  <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                    Tên sản phẩm
                  </Typography>

                  <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                    Tên mặc định
                  </Typography>

                  <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                    Nhập số lượng
                  </Typography>
                </Box>
                <Box
                  component="form"
                  autoComplete="off"
                  sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
                >
                  <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                    {inputChiphi.name}
                  </Typography>

                  <TextField
                    fullWidth
                    type="number"
                    name="price"
                    onChange={handleinput}
                    value={input.price}
                    label="Giá mua"
                    variant="outlined"
                    size="small"
                    sx={{ flex: 1 }}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    name="usedQuantity"
                    onChange={handleinput}
                    label="Nhập số lượng"
                    variant="outlined"
                    size="small"
                    sx={{ flex: 1 }}
                  />
                </Box>
              </>
            )}

            <Field
              sx={{ marginTop: 1 }}
              as={TextField}
              multiline
              rows={4}
              name="notes"
              label="Ghi chú"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Box sx={{ marginTop: `1rem` }}>
              <UploadMultiple handPhotoChange={handPhotoChange} />
            </Box>
            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}
            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddNhapChiPhi;
