import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import UpdateOrders from "./UpdateOrders";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { IconButton, Tooltip } from "@mui/material";
export default function DialogOrders(props) {
  const { gamesData, walletsData, danhsachbanData, id, order } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      {order && (
        <Tooltip title={"Thêm đơn hàng"} placement="top" arrow>
          <IconButton color="primary" onClick={handleClickOpen("paper")}>
            <BorderColorIcon />
          </IconButton>
        </Tooltip>
      )}
      {!order && (
        <Button
          onClick={handleClickOpen("paper")}
          variant="contained"
          size="small"
          color="primary"
        >
          Thêm đơn hàng
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Đặt mua</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <UpdateOrders
              gamesData={gamesData === undefined ? [] : gamesData}
              walletsData={walletsData === undefined ? [] : walletsData}
              danhsachbanData={
                danhsachbanData === undefined ? [] : danhsachbanData
              }
              id={id}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
