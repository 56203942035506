import { Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";
const EditChiPhi = ({ product }) => {
  const navigate = useNavigate();
  const [input, setInput] = useState(product);
  const [errorMessage, seterrorMessage] = useState("");
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async () => {
    if (input.name === "") {
      return seterrorMessage("Name is required");
    }
    const data = {
      id: product.id,
      name: input.name,
      notes: input.notes,
      price: input.price,
      status: input.status,
      unit: input.unit,
    };

    try {
      const response = await axiosusers.post(`/chiphi/update-chiphi`, data);
      console.log(response);

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/chi-phi");
        }, 500);
      }
    } catch (error) {
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);
  const onChangeStatus = (value) => {
    setInput({
      ...input,
      status: value,
    });
  };
  return (
    <div>
      <FormControlProduct
        value={input.status}
        onChangeStatus={onChangeStatus}
      />
      <TextField
        sx={{ marginTop: `0.5rem` }}
        onChange={handleinput}
        type="text"
        value={input.name}
        name="name"
        error={Boolean(input.name === "")}
        helperText={input.name === "" ? "Tên sản phẩm là bắt buộc" : ""}
        label="Tên sản phẩm"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="number"
        value={input.price}
        name="price"
        label="Giá mặc định"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        value={input.unit}
        name="unit"
        label="Đơn vị tính"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        multiline
        minRows={4}
        value={input.notes}
        name="notes"
        label="Ghi chú"
        size="small"
        variant="outlined"
        fullWidth
      />

      {errorMessage && <FilledAlerts message={errorMessage} success="error" />}
      <Button
        type="submit"
        sx={{ margin: (theme) => theme.spacing(5, 0) }}
        variant="contained"
        size="large"
        fullWidth
        color="primary"
        onClick={onSubmit}
      >
        Update
      </Button>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default EditChiPhi;
