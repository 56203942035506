import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const TableReport = (props) => {
  const { result } = props;
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <TableContainer
      component={Paper}
      sx={{ width: { md: "100%", xs: "88vw" }, marginTop: "30px" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Ngày đặt hàng</TableCell>
            <TableCell>Doanh số bán hàng</TableCell>
            <TableCell>Chi phí đầu ra</TableCell>
            <TableCell>Trả hàng</TableCell>
            <TableCell>Lương nhân viên</TableCell>
            <TableCell>Tiền ứng lương</TableCell>
            <TableCell>Tiền thưởng</TableCell>
            <TableCell>Lợi nhuận</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {result.map((row, id) => {
            var dateCreate = row.date.split("-");
            var dateCreateAt =
              dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
            return (
              <TableRow key={id}>
                <TableCell>{dateCreateAt}</TableCell>
                <TableCell>{number.format(row.salePrice)} ₫</TableCell>

                <TableCell>
                  {number.format(
                    Number(row.adFreeAmount) +
                      Number(row.xuatkhobudgetAmount) +
                      Number(row.nhapchiphiAmount)
                  )}{" "}
                  ₫
                </TableCell>
                <TableCell>
                  {number.format(
                    Number(row.trahangchinhAmount) + Number(row.trahangAmount)
                  )}{" "}
                  ₫
                </TableCell>
                <TableCell>
                  {number.format(Number(row.usersstaffAmount))} ₫
                </TableCell>
                <TableCell>{number.format(row.tienluongAmount)} ₫</TableCell>
                <TableCell>{number.format(row.bonusAmount)} ₫</TableCell>

                <TableCell>
                  {number.format(
                    Number(row.salePrice) -
                      Number(row.adFreeAmount) -
                      Number(row.bonusAmount) -
                      Number(row.usersstaffAmount) -
                      Number(row.xuatkhobudgetAmount) -
                      Number(row.nhapchiphiAmount) -
                      Number(row.tienluongAmount) +
                      Number(row.trahangchinhAmount) +
                      Number(row.trahangAmount)
                  )}{" "}
                  ₫
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableReport;
