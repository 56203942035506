import {
  AvatarGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import NavLinkButton from "components/NavLinks/NavLinkButton";
import React from "react";
import { NavLink } from "react-router-dom";
import DeleteNhapkho from "./DeleteNhapkho";
import { toast, ToastContainer } from "react-toastify";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import Images from "components/Avatar/Images";
const TableNhapkhochinh = ({ product, handleCurrentDelete }) => {
  const showToast = ({ message, status }) => {
    handleCurrentDelete();
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Người tạo</TableCell>
              <TableCell>Thực đơn</TableCell>
              <TableCell>Nhà cung cấp</TableCell>
              <TableCell>Đơn vị tính</TableCell>
              <TableCell>Giá nhập</TableCell>
              <TableCell>Số lượng nhập</TableCell>
              <TableCell>Tổng tiền</TableCell>
              <TableCell align="right">Ảnh</TableCell>
              <TableCell align="center">Ghi chú</TableCell>
              <TableCell align="center">Create Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              var nullCreate = row.createdAt.split("T");
              var dateCreate = nullCreate[0].split("-");
              var dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <NavLink
                      style={{ color: "blue", textDecoration: "none" }}
                      to={`/manager/nhapkho-chinh/${row.id}`}
                    >
                      {row.id}
                    </NavLink>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Copy" placement="top" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(`${row.user?.name}`);
                        }}
                      >
                        {row.user?.name}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{row.ngansachkho?.name} </TableCell>
                  <TableCell>{row.supplier?.fullName}</TableCell>
                  <TableCell>{row.ngansachkho?.unit} </TableCell>
                  <TableCell>{number.format(row.price)} đ</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell>
                    {number.format(Number(row.price) * Number(row.quantity))} đ
                  </TableCell>
                  <TableCell>
                    <AvatarGroup max={4}>
                      {row.photos.map((item) => {
                        return <Images key={row.id} nameImage={item.image} />;
                      })}
                    </AvatarGroup>
                  </TableCell>
                  <TableCell>{row.notes}</TableCell>
                  <TableCell align="center">{dateCreateAt}</TableCell>
                  <TableCell size="small" align="center">
                    <NavLinkButton href={`/manager/nhapkho-chinh/${row.id}`}>
                      <IconButton color="primary">
                        <AddToQueueIcon />
                      </IconButton>
                    </NavLinkButton>
                    <DeleteNhapkho
                      id={row.id}
                      onToast={showToast}
                      onCheckedChange={(e) => handleCurrentDelete(e)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default TableNhapkhochinh;
