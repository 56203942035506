import { Box, Typography } from "@mui/material";
import React from "react";

const Budgets = ({ xuatkhowallets }) => {
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });

  return (
    <div style={{ marginTop: "0.5rem" }}>
      {xuatkhowallets.map((row, key) => {
        return (
          <Box key={key} sx={{ justifyContent: "left", textAlign: "left" }}>
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, fontSize: "1rem", flex: 1 }}
              >
                Tên Thực đơn: {row.wallet.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                Tên ngân sách
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                Số lượng
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "1rem", flex: 0.6 }}>
                Giá mua
              </Typography>
            </Box>
            {row.budgetsAll.map((budget) => (
              <Box key={budget.id} sx={{ display: "flex" }}>
                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                  {budget.name}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                  {budget.xuatkhobudgets.quantity}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "1rem", flex: 0.6 }}>
                  {number.format(budget.xuatkhobudgets.price)} đ
                </Typography>
              </Box>
            ))}
          </Box>
        );
      })}
    </div>
  );
};

export default Budgets;
