import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";

import { Button, TextField, useTheme } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

export default function ElevateAppBar(props) {
  Tabtitle("Login");
  const theme = useTheme();
  const history = useNavigate();
  const [password, setPassword] = React.useState("");
  const [dung, setDung] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [touchedFields, setTouchedFields] = React.useState({
    email: false,
    password: false,
  });
  const [errors, setErrors] = React.useState({
    email: "The email not found.",
    password: "The password incorrect!",
  });

  const validateForm = () => {
    return Object.values(errors).every((error) => error === "");
  };

  const handleFieldBlur = (field) => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [field]: true,
    }));
  };

  const handleFieldChange = (field, value) => {
    if (value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "Must not be empty",
      }));
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value ? "" : prevErrors[field],
    }));
  };

  const handleSubmit = async () => {
    setMessages("");
    setDung(true);
    setTouchedFields({
      email: true,
      password: true,
    });
    const dataSend = {
      email: email,
      password: password,
    };

    try {
      // Assuming you have a correct endpoint for sending OXXO data
      const response = await axiosusers.post(`/auth/login`, dataSend);

      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.setItem("auth", JSON.stringify(response.data.existingUser));
      history("/manager/reports-all");
      window.location.reload();
      if (validateForm()) {
        setEmail("");
      }
    } catch (error) {
      setMessages(error.response.data.message);

      setDung(false);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ height: "100vh", background: "#fafafa" }}>
        <CssBaseline />
        <ElevationScroll {...props}>
          <AppBar>
            <Toolbar></Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar />

        <form autoComplete="off">
          <Box
            sx={{
              maxWidth: "600px",
              margin: "auto",
              background: "#fff",
              borderRadius: 4,
              marginTop: "100px",
              padding: "20px 20px 60px 20px",
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "10px",
                background:
                  "linear-gradient(271deg, rgb(255, 203, 20) -14.81%, rgb(255, 97, 75) 29.33%, rgb(116, 66, 225) 75.47%, rgb(50, 81, 242) 113.59%)",
              },
            }}
          >
            <Box
              sx={{
                maxWidth: 600,
                margin: `${theme.spacing(5)}px auto`,
                padding: theme.spacing(1),
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
              }}
            >
              <img
                src="/logo_kan.png"
                alt="logo"
                style={{ width: "100px", margin: "auto", display: "block" }}
              />

              <TextField
                id="email"
                label="Email Address"
                placeholder="Email…"
                variant="outlined"
                type="text"
                fullWidth
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  handleFieldChange("email", e.target.value);
                }}
                onBlur={() => handleFieldBlur("email")}
                error={touchedFields.email && !!errors.email}
                helperText={touchedFields.email && errors.email}
              />
              <TextField
                id="password"
                label="Password"
                placeholder="Password…"
                variant="outlined"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  handleFieldChange("password", e.target.value);
                }}
                onBlur={() => handleFieldBlur("password")}
                error={touchedFields.password && !!errors.password}
                helperText={touchedFields.password && errors.password}
                fullWidth
              />
              {/* <NavLink to="/send-mail" variant="body2">
                Quên mật khẩu?
              </NavLink> */}
              {messages && <FilledAlerts message={messages} success="error" />}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  autoFocus
                  type="submit"
                  variant="contained"
                  fullWidth
                  onClick={handleSubmit}
                  disabled={dung}
                >
                  SIGN IN NOW
                </Button>
              </Box>
              {/* <Box sx={{ display: "flex", textAlign: "center", marginTop: 5 }}>
                <Typography>Bạn chưa có tài khoản?</Typography>
                <NavLink
                  to="/register"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "rgb(254, 106, 61)",
                      fontWeight: 600,
                    }}
                  >
                    Đăng ký
                  </Typography>
                </NavLink>
              </Box> */}
            </Box>
          </Box>
        </form>
      </Box>
    </React.Fragment>
  );
}
