import Images from "components/Avatar/Images";
import {
  Avatar,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const GeneralReport = (props) => {
  const {
    data,
    selectUser,
    selectProduct,
    fromDate,
    toDate,
    orderTotal,
    salePriceTotal,
    adfeeAmountTotal,
    bonusAmountTotal,
    usersstaffAmountTotal,
    xuatkhobudgetAmountTotal,
    nhapchiphiAmountTotal,
    trahangchinhAmountTotal,
    trahangAmountTotal,
  } = props;
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });

  return (
    <div>
      <Paper variant="outlined">
        <Grid container spacing={0}>
          {data?.role === "Staff" && (
            <Grid item xs={3}>
              <ListItem>
                <ListItemAvatar>
                  {data?.avatarUrl == "" ? (
                    <Avatar alt="Remy Sharp" src="" />
                  ) : (
                    <Images nameImage={data?.avatarUrl} />
                  )}
                </ListItemAvatar>

                <ListItemText
                  primary={<Typography>{data.name}</Typography>}
                  secondary={data.roles}
                />
              </ListItem>
            </Grid>
          )}
          {data?.role !== "Staff" && selectUser && (
            <Grid item xs={3}>
              <ListItem>
                <ListItemAvatar>
                  {selectUser?.avatarUrl == "" ? (
                    <Avatar alt="Remy Sharp" src="" />
                  ) : (
                    <Images nameImage={selectUser?.avatarUrl} />
                  )}
                </ListItemAvatar>

                <ListItemText
                  primary={<Typography>{selectUser?.name}</Typography>}
                  secondary={selectUser?.roles}
                />
              </ListItem>
            </Grid>
          )}

          {selectProduct && (
            <Grid item xs={3}>
              <ListItem>
                <ListItemAvatar>
                  {selectProduct?.image == "" ? (
                    <Avatar alt="Remy Sharp" src="" />
                  ) : (
                    <Images nameImage={selectProduct?.image} />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography>{selectProduct?.name}</Typography>}
                  secondary={selectProduct?.description}
                />
              </ListItem>
            </Grid>
          )}
        </Grid>
        {!(selectUser || selectProduct) && data?.role != "Staff" && (
          <Typography
            color="primary"
            variant="subtitle2"
            sx={{
              fontSize: "16px",
              margin: (theme) => theme.spacing(2, 0, 1, 2),
            }}
          >
            Báo cáo chung cho tất cả người dùng và tất cả sản phẩm
          </Typography>
        )}

        <Divider sx={{ margin: (theme) => theme.spacing(0, 2, 2, 2) }} />
        <TableContainer sx={{ width: { md: "100%", xs: "88vw" } }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Phạm vi ngày
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Tổng số đơn hàng
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Tổng Doanh Số Bán Hàng
                  </Typography>
                </TableCell>
                {data?.roles === "Admin" && (
                  <TableCell
                    sx={{
                      border: 0,
                      padding: (theme) => theme.spacing(1, 2),
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#546e7a",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      Tổng chi phí đầu ra
                    </Typography>
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{
                      color: "#546e7a",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    Tổng trả hàng
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Tổng tiền thưởng
                  </Typography>
                </TableCell>

                {data?.roles === "Admin" && (
                  <TableCell
                    sx={{
                      border: 0,
                      padding: (theme) => theme.spacing(1, 2),
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#546e7a",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      Tổng lợi nhuận
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >{`${fromDate} - ${toDate}`}</TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {orderTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(salePriceTotal)} ₫
                </TableCell>
                {data?.role == "Admin" && (
                  <TableCell
                    sx={{
                      border: 0,
                      padding: (theme) => theme.spacing(1, 2),
                    }}
                  >
                    {number.format(
                      adfeeAmountTotal +
                        usersstaffAmountTotal +
                        xuatkhobudgetAmountTotal +
                        nhapchiphiAmountTotal
                    )}{" "}
                    ₫
                  </TableCell>
                )}

                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(trahangchinhAmountTotal + trahangAmountTotal)}{" "}
                  ₫
                </TableCell>

                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(bonusAmountTotal)} ₫
                </TableCell>
                {data?.role == "Admin" && (
                  <TableCell
                    sx={{
                      border: 0,
                      padding: (theme) => theme.spacing(1, 2),
                    }}
                  >
                    {number.format(
                      salePriceTotal -
                        adfeeAmountTotal -
                        bonusAmountTotal -
                        usersstaffAmountTotal -
                        xuatkhobudgetAmountTotal -
                        nhapchiphiAmountTotal +
                        trahangchinhAmountTotal +
                        trahangAmountTotal
                    )}{" "}
                    ₫
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default GeneralReport;
