import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://0b9c773a43d0923dddafc7f683ca51d2@o4507735385964544.ingest.us.sentry.io/4507735388323840",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const themecolor = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#d32f2f",
      main: "#d32f2f",
      dark: "#d32f2f",
      contrastText: "#000",
    },
  },
  typography: {
    // fontFamily: poppins.style.fontFamily,
    h1: {
      fontWeight: "600",
      lineHeight: 1.5,
    },
    h2: {
      fontWeight: "600",
      lineHeight: 1.5,
    },
    h3: {
      fontWeight: "600",
      lineHeight: 1.5,
    },
    h4: {
      fontWeight: "600",
      lineHeight: 1.5,
    },
    h5: {
      fontWeight: "600",
      lineHeight: 1.5,
    },
    h6: {
      fontWeight: "400",
      lineHeight: 1.5,
    },
    h7: {
      fontWeight: "600",
      lineHeight: 1.5,
    },
    subtitle2: {
      fontWeight: "600",
      lineHeight: 1.5,
      fontSize: "1rem",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "h2",
          h4: "h2",
          h5: "h2",
          h6: "h2",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "span",
          body2: "span",
        },
      },
    },
  },
});
const globalStyles = `
  .css-ahj2mt-MuiTypography-root {
    margin: 0 !important;
    font-family: initial !important;
    font-weight: initial !important;
    font-size: initial !important;
    line-height: initial !important;
    letter-spacing: initial !important;
  }
`;
root.render(
  <React.StrictMode>
    <ThemeProvider theme={themecolor} globalStyles={globalStyles}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
