import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormControlProduct from "components/Mui/FormControlProduct/FormControlProduct";
const EditBudgets = ({ product }) => {
  const navigate = useNavigate();
  const [input, setInput] = useState(product);
  const [status, setStatus] = React.useState(product.status);
  const [errorMessage, seterrorMessage] = useState("");
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeStatus = (e) => {
    setStatus(e);
  };
  const onSubmit = async () => {
    let errorMessage = "";
    if (input.name === "") {
      errorMessage = "Tên sản phẩm là bắt buộc";
    }

    if (errorMessage !== "") {
      return seterrorMessage(errorMessage);
    }
    const data = {
      id: product.id,
      status,
      name: input.name,
      notes: input.notes,
      price: input.price,
      quantity: input.quantity,
      unit: input.unit,
    };

    try {
      const response = await axiosusers.post(
        `/ngansachkho/update-ngansachkho`,
        data
      );
      console.log(response);

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/thucdonkho-chinh");
        }, 500);
      }
    } catch (error) {
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);

  return (
    <div>
      <FormControlProduct value={status} onChangeStatus={onChangeStatus} />
      <TextField
        sx={{ marginTop: `0.5rem` }}
        onChange={handleinput}
        type="text"
        value={input.name}
        name="name"
        error={Boolean(input.name === "")}
        helperText={input.name === "" ? "Tên là bắt buộc" : ""}
        label="Tên "
        size="small"
        variant="outlined"
        fullWidth
      />
      <Box
        component="form"
        autoComplete="off"
        sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
      >
        <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
          Đơn vị tính
        </Typography>

        <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
          Giá mua
        </Typography>

        <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
          Nhập số lượng
        </Typography>
      </Box>
      <Box
        component="form"
        autoComplete="off"
        sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
      >
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.unit}
          name="unit"
          label="Đơn vị tính"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="number"
          value={input.price}
          name="price"
          label="Giá mua"
          size="small"
          variant="outlined"
          fullWidth
        />

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="number"
          value={input.quantity}
          name="quantity"
          label="Số lượng"
          size="small"
          variant="outlined"
          fullWidth
        />
      </Box>

      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        multiline
        minRows={4}
        value={input.notes}
        name="notes"
        label="Ghi chú"
        size="small"
        variant="outlined"
        fullWidth
      />

      {errorMessage && <FilledAlerts message={errorMessage} success="error" />}
      <Button
        type="submit"
        sx={{ margin: (theme) => theme.spacing(5, 0) }}
        variant="contained"
        size="large"
        fullWidth
        color="primary"
        onClick={onSubmit}
      >
        Update
      </Button>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default EditBudgets;
