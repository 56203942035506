import React from "react";
import MenuItems from "./MenuItems";

const index = () => {
  const data = JSON.parse(localStorage.getItem("auth"));

  return (
    <div>
      <MenuItems data={data} />
    </div>
  );
};

export default index;
