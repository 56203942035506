import NavLinkAdd from "components/NavLinks/NavLinkAdd";
import React from "react";
import SearchTienLuong from "./Main/SearchTienLuong";
import { Tabtitle } from "components/title/Title";
import TableTienLuong from "./Main/TableTienLuong";
import axiosusers from "utils/api/axiosusers";
import { Box, CircularProgress } from "@mui/material";
import BasicPagination from "hooks/usePagination";
import useSWR from "swr";

const MAX_RETRIES = 3;

const index = () => {
  Tabtitle("Tiền lương");
  const [searchUser, setSearchUser] = React.useState("");

  const pageSize = 10;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [retryCount, setRetryCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);

  const fetchDataAll = async () => {
    try {
      const response = await axiosusers.get("/tienluong/tienluong", {
        params: {
          searchUserId: searchUser?.id,
          pageSize,
          currentPage: currentPage === 0 ? 1 : currentPage,
        },
      });
      const res = response.data;
      setProduct(res.tienluong);
      setPageCount(Math.ceil(res.pages / pageSize));
      setLoading(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("401 Unauthorized. Retrying...");
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          fetchDataAll();
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error(error);
      }
    }
  };

  React.useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [searchUser, pageSize, currentPage]);
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: usersData } = useSWR(`/users/users-all`, fetchDataSwr);
  return (
    <div>
      <NavLinkAdd href="/manager/tienluong/add" name="Tạo tiền lương" />
      <SearchTienLuong
        setSearchUser={(e) => setSearchUser(e)}
        usersData={usersData === undefined ? [] : usersData}
      />
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {loading && (
        <TableTienLuong product={product} handleCurrentDelete={fetchDataAll} />
      )}
      <Box sx={{ marginTop: "20px" }}>
        <BasicPagination
          onCheckedChange={(e) => setCurrentPage(e)}
          pageCount={pageCount}
          currentPage={currentPage}
        />
      </Box>
    </div>
  );
};

export default index;
