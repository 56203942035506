import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";

const AddClient = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);

  const initialValues = {
    name: "",
    email: "",
    password: "",
    salary: "0",
    bonus: "0",
    bankName: "",
    bankNumber: "",
    bankAccount: "",
    zaloId: "",
    phoneNumber: "",
    maxAbsent: "",
  };
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().min(0, "It's too short").required("Tên bắt buộc"),
  });

  const onSubmit = async (values) => {
    setDung(true);

    const data = {
      fullName: values.fullName,
      email: values.email,
      password: "",
      faceBookName: values.faceBookName,
      instagramUser: values.instagramUser,
      telegramUser: values.telegramUser,
      lineUser: values.lineUser,
      phoneNumber: values.phoneNumber,
      address: values.address,
      activateCode: 0,
      active: false,
      image: "",
      role: "client",
    };
    try {
      const response = await axiosusers.post(
        `/suppliers/create-suppliers`,
        data
      );
      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/nhacungcap");
        }, 500);
      }
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={dung ? null : onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="fullName"
              label="Tên khách hàng"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.fullName && props.touched.fullName}
              helperText={<ErrorMessage name="fullName" />}
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="email"
              label="Email Address"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.email && props.touched.email}
              helperText={<ErrorMessage name="email" />}
            />

            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="instagramUser"
              label="Instagram"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="faceBookName"
              label="FaceBook"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="telegramUser"
              label="Telegram"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="lineUser"
              label="Line"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="phoneNumber"
              label="Phone Number"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              name="address"
              label="Address"
              variant="outlined"
              size="small"
              fullWidth
            />
            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddClient;
