import { Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
const EditPayment = ({ product }) => {
  const navigate = useNavigate();
  const [input, setInput] = useState(product);
  const [errorMessage, seterrorMessage] = useState("");

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async () => {
    if (input.fullName === "") {
      return seterrorMessage("Name is required");
    }
    const data = {
      id: product.id,
      orderNumber: input.orderNumber,
      owner: input.owner,
      bank: input.bank,
      type: input.type,
      status: input.status,
      currency: input.currency,
      balance: input.balance,
      onHoldBalance: input.onHoldBalance,
      phoneNumber: input.phoneNumber,
      notes: input.notes,
      CLIENTID: input.CLIENTID,
      APIKEY: input.APIKEY,
      CHECKSUMKEY: input.CHECKSUMKEY,
      devil: input.devil,
    };

    try {
      const response = await axiosusers.post(`/payments/update-payments`, data);
      console.log(response);

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/thanhtoan");
        }, 500);
      }
    } catch (error) {
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);
  return (
    <div>
      <TextField
        sx={{ marginTop: `0.5rem` }}
        onChange={handleinput}
        type="text"
        value={input.orderNumber}
        name="orderNumber"
        error={Boolean(input.orderNumber === "")}
        helperText={input.orderNumber === "" ? "Số thứ tự là bắt buộc" : ""}
        label="Số thứ tự"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.owner}
        name="owner"
        label="Chủ sở hữu"
        error={Boolean(input.owner === "")}
        helperText={input.owner === "" ? "Chủ sở hữu là bắt buộc" : ""}
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.bank}
        name="bank"
        label="Tên ngân hàng"
        error={Boolean(input.bank === "")}
        helperText={input.bank === "" ? "Tên ngân hàng là bắt buộc" : ""}
        size="small"
        variant="outlined"
        fullWidth
      />
      <FormControl
        style={{ marginTop: "0.5rem" }}
        fullWidth
        variant="outlined"
        size="small"
      >
        <InputLabel>Chọn đối tượng</InputLabel>
        <Select
          value={input.type}
          label="Chọn đối tượng"
          name="type"
          onChange={handleinput}
        >
          <MenuItem value="Paypal">Paypal</MenuItem>
          <MenuItem value="Stripe">Stripe</MenuItem>
          <MenuItem value="Momo">Momo</MenuItem>
          <MenuItem value="Airpay">Airpay</MenuItem>
          <MenuItem value="Cash">Cash</MenuItem>
          <MenuItem value="BTC">BTC</MenuItem>
          <MenuItem value="BankTransfer">BankTransfer</MenuItem>
          <MenuItem value="Skrill">Skrill</MenuItem>
          <MenuItem value="TransferWise">TransferWise</MenuItem>
          <MenuItem value="WebMoney">WebMoney</MenuItem>
          <MenuItem value="PayOneer">PayOneer</MenuItem>
          <MenuItem value="PingPong">PingPong</MenuItem>
          <MenuItem value="USDT">USDT</MenuItem>
          <MenuItem value="ETH">ETH</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        style={{ marginTop: "0.5rem" }}
        fullWidth
        variant="outlined"
        size="small"
      >
        <InputLabel>Chọn trạng thái</InputLabel>
        <Select
          label="Chọn trạng thái"
          name="status"
          value={input.status}
          onChange={handleinput}
        >
          <MenuItem value="trustSeller">Trust seller</MenuItem>
          <MenuItem value="limit">Limit</MenuItem>
          <MenuItem value="disable">Disable</MenuItem>
          <MenuItem value="strong">Strong</MenuItem>
          <MenuItem value="weak">Weak</MenuItem>
          <MenuItem value="openCase">Open case</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        style={{ marginTop: "0.5rem" }}
        fullWidth
        variant="outlined"
        size="small"
      >
        <InputLabel>Chọn loại tiền tệ</InputLabel>
        <Select
          label="Chọn loại tiền tệ"
          value={input.currency}
          name="currency"
          onChange={handleinput}
        >
          <MenuItem value="VND">VND</MenuItem>
          <MenuItem value="USD">USD</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        style={{ marginTop: "0.5rem" }}
        fullWidth
        variant="outlined"
        size="small"
      >
        <InputLabel>Chọn tính năng</InputLabel>
        <Select
          label="Chọn tính năng"
          value={input.devil}
          name="devil"
          onChange={handleinput}
        >
          <MenuItem value="Quỹ">Quỹ</MenuItem>
          <MenuItem value="Phương thức thanh toán">
            Phương thức thanh toán
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="number"
        value={input.balance}
        name="balance"
        label="Số dư"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="number"
        value={input.onHoldBalance}
        name="onHoldBalance"
        label="Số dư đang giữ"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.phoneNumber}
        name="phoneNumber"
        label="phoneNumber"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.CLIENTID}
        name="CLIENTID"
        label="CLIENTID"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.APIKEY}
        name="APIKEY"
        label="APIKEY"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.CHECKSUMKEY}
        name="CHECKSUMKEY"
        label="CHECKSUM_KEY"
        size="small"
        variant="outlined"
        fullWidth
      />
      <TextField
        onChange={handleinput}
        sx={{ marginTop: `0.5rem` }}
        type="text"
        value={input.notes}
        multiline
        minRows="4"
        name="notes"
        label="Ghi chú"
        size="small"
        variant="outlined"
        fullWidth
      />
      {errorMessage && <FilledAlerts message={errorMessage} success="error" />}
      <Button
        type="submit"
        sx={{ margin: (theme) => theme.spacing(5, 0) }}
        variant="contained"
        size="large"
        fullWidth
        color="primary"
        onClick={onSubmit}
      >
        Update
      </Button>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default EditPayment;
