import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import BasicPagination from "hooks/usePagination";
import DeleteUsers from "./DeleteUsers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const UsersTable = (props) => {
  const {
    handleCurrentPage,
    product,
    currentPage,
    pageCount,
    handleCurrentDelete,
  } = props;
  const showToast = ({ message, status }) => {
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Lương cố định</TableCell>
              <TableCell>Lương nhân viên</TableCell>
              <TableCell>Telegrame ID</TableCell>
              <TableCell>Create Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              var nullCreate = row.createdAt.split("T");
              var dateCreate = nullCreate[0].split("-");
              var dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.roles}</TableCell>
                  <TableCell>{number.format(row.fixedSalary)} ₫</TableCell>
                  <TableCell>{number.format(row.amount)} ₫</TableCell>
                  <TableCell>{row.telegramId}</TableCell>
                  <TableCell>{dateCreateAt}</TableCell>
                  <TableCell size="small" align="center">
                    <NavLink to={`/manager/taikhoan/edit/${row.id}`}>
                      <IconButton color="primary">
                        <EditIcon />
                      </IconButton>
                    </NavLink>
                    <DeleteUsers
                      id={row.id}
                      currentPage={currentPage}
                      onToast={showToast}
                      onCheckedChange={(e) => handleCurrentDelete(e)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <BasicPagination
          onCheckedChange={(e) => handleCurrentPage(e)}
          pageCount={pageCount}
          currentPage={currentPage}
        />
      </TableContainer>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default UsersTable;
