import { Tabtitle } from "components/title/Title";
import React from "react";
import axiosusers from "utils/api/axiosusers";
import { Box, CircularProgress } from "@mui/material";
import BasicPagination from "hooks/usePagination";
import TableXuatkhochinh from "./TableXuatkhochinh";
import SreachXuatkhochinh from "./SreachXuatkhochinh";

const index = () => {
  Tabtitle("Xuất kho chính");
  const [searchCode, setSearchCode] = React.useState("");
  const MAX_RETRIES = 3;
  const pageSize = 10;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [retryCount, setRetryCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);

  const fetchDataAll = async () => {
    try {
      const response = await axiosusers.get("/nhapkhochinh/nhapkhochinh", {
        params: {
          searchId: searchCode,
          pageSize,
          currentPage: currentPage === 0 ? 1 : currentPage,
        },
      });
      const res = response.data;
      setProduct(res.thucdonchinh);
      setPageCount(Math.ceil(res.pages / pageSize));
      setLoading(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("401 Unauthorized. Retrying...");
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          fetchDataAll();
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error(error);
      }
    }
  };

  React.useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [searchCode, pageSize, currentPage]);

  return (
    <div>
      <SreachXuatkhochinh searchCode={(e) => setSearchCode(e)} />
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {loading && (
        <TableXuatkhochinh
          product={product}
          handleCurrentDelete={fetchDataAll}
        />
      )}
      <Box sx={{ marginTop: "20px" }}>
        <BasicPagination
          onCheckedChange={(e) => setCurrentPage(e)}
          pageCount={pageCount}
          currentPage={currentPage}
        />
      </Box>
    </div>
  );
};

export default index;
