import {
  AvatarGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteTransaction from "./DeleteXuatkho";
import NavLinkButton from "components/NavLinks/NavLinkButton";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import Images from "components/Avatar/Images";

const TableTransaction = (props) => {
  const { product, handleCurrentDelete } = props;
  const showToast = ({ message, status }) => {
    handleCurrentDelete();
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Thực đơn</TableCell>
              <TableCell align="right">Sản phẩm</TableCell>
              <TableCell>Tổng tiền</TableCell>
              <TableCell>Ngày tạo ra</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              const nullCreate = row.createdAt.split("T");
              const dateCreate = nullCreate[0].split("-");
              const dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];

              return (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>
                    {(() => {
                      const combinedNames = row.xuatkhothucdon
                        .map((item) => item.name)
                        .join(", ");

                      const truncatedNames =
                        combinedNames.length > 100
                          ? `${combinedNames.slice(0, 100)}...`
                          : combinedNames;

                      return truncatedNames;
                    })()}
                  </TableCell>

                  <TableCell>
                    <AvatarGroup max={4}>
                      {row.xuatkhoproducts.map((item) => {
                        return (
                          <Images
                            key={row.id}
                            nameImage={item.products.image}
                          />
                        );
                      })}
                    </AvatarGroup>
                  </TableCell>

                  <TableCell> {number.format(Number(row.price))} ₫</TableCell>
                  <TableCell>{dateCreateAt}</TableCell>
                  <TableCell size="small" align="center">
                    <NavLinkButton href={`/manager/xuatkho/${row.id}`}>
                      <IconButton color="primary">
                        <AddToQueueIcon />
                      </IconButton>
                    </NavLinkButton>
                    <DeleteTransaction
                      id={row.id}
                      onToast={showToast}
                      onCheckedChange={(e) => handleCurrentDelete(e)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default TableTransaction;
