import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

const TransactionsDetail = ({ transactionsorders }) => {
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <Grid container spacing={1} sx={{ margin: "4px 0 " }}>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            variant="h5"
            color="primary"
            sx={{ color: "#3f51b5", fontWeight: 500, fontSize: "1rem" }}
          >
            ID giao dịch:
          </Typography>
        </Grid>
        <Grid item lg={10} md={10} sm={10} xs={10}>
          <Box sx={{ display: "flex", marginBottom: "0.5rem" }}>
            <Typography variant="body1" sx={{ flex: 1 }}>
              ID
            </Typography>
            <Typography variant="body1" sx={{ flex: 1 }}>
              Mã giao dịch
            </Typography>
            <Typography variant="body1" sx={{ flex: 1 }}>
              Tiền
            </Typography>
            <Typography variant="body1" sx={{ flex: 1 }}>
              Chủ sở hữu
            </Typography>
          </Box>
          {transactionsorders
            .filter((item) => item.status !== "pending")
            .map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{ display: "flex", marginBottom: "0.5rem" }}
                >
                  <Typography variant="body1" sx={{ flex: 1 }}>
                    {item.transactionId}
                  </Typography>
                  <Typography variant="body1" sx={{ flex: 1 }}>
                    {item.transaction.code}
                  </Typography>
                  <Typography variant="body1" sx={{ flex: 1 }}>
                    {number.format(item.amount)} ₫
                  </Typography>
                  <Typography variant="body1" sx={{ flex: 1 }}>
                    {item.payment.owner}
                  </Typography>
                </Box>
              );
            })}
        </Grid>
      </Grid>
      <Divider style={{ backgroundColor: "#000", margin: "5px 0 0 4px" }} />
    </div>
  );
};

export default TransactionsDetail;
