import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";

const AutocompleteId = (props) => {
  const {
    data,
    label,
    size,
    name,
    onCheckedChange,
    openError,
    done,
    optionLabel,
    multiple,
    placeholder,
  } = props;

  const [errorMessage, setErrorMessage] = React.useState("");
  const [error, setError] = React.useState(false);

  const setChange = (event, value) => {
    if (openError) {
      setError(true);
      setErrorMessage(!value ? `${name} là bắt buộc` : "");
    }
    onCheckedChange(value);
  };

  useEffect(() => {
    if (done && openError) {
      setError(true);
      setErrorMessage(`${name} là bắt buộc`);
    }
  }, [done, openError, name]);

  const handleFieldBlur = (field) => {
    if (!error && openError) {
      setError(true);
      setErrorMessage(`${field} là bắt buộc`);
    }
  };

  return (
    <Autocomplete
      style={{ marginTop: "0.5rem" }}
      fullWidth
      multiple={multiple}
      filterSelectedOptions={multiple}
      size={size}
      id="combo-box-demo"
      name="id"
      onChange={setChange}
      onBlur={() => handleFieldBlur(name)}
      options={data}
      getOptionLabel={optionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={Boolean(errorMessage && error)}
          helperText={error ? errorMessage : ""}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default AutocompleteId;
