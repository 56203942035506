import NavLinkAdd from "components/NavLinks/NavLinkAdd";
import React from "react";

import { Tabtitle } from "components/title/Title";
import TablePayments from "./Main/TablePayment";
import axiosusers from "utils/api/axiosusers";
import { Box, CircularProgress } from "@mui/material";
import TotalPayment from "./Main/TotalPayment";

const MAX_RETRIES = 3;

const index = () => {
  Tabtitle("Thanh toán");
  const userId = JSON.parse(localStorage.getItem("auth"));
  const pageSize = 100000;

  const [retryCount, setRetryCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);

  const fetchDataAll = async () => {
    try {
      const response = await axiosusers.get("/payments/payments", {
        params: {
          pageSize,
          currentPage: 1,
        },
      });
      const res = response.data;
      setProduct(res.payments);
      setLoading(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("401 Unauthorized. Retrying...");
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          fetchDataAll();
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error(error);
      }
    }
  };

  React.useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [pageSize]);
  return (
    <div>
      {userId.role === "Admin" && (
        <NavLinkAdd href="/manager/thanhtoan/add" name="Tạo Thanh toán" />
      )}
      {loading && <TotalPayment product={product} />}
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {loading && (
        <TablePayments
          product={product}
          handleCurrentDelete={fetchDataAll}
          userId={userId}
        />
      )}
    </div>
  );
};

export default index;
