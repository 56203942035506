import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../style.module.css";
import CloseIcon from "@mui/icons-material/Close";
const GetVietQR = (props) => {
  const { maQR, dataVietQR, orders } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      Promise.all([
        axiosusers.get(
          `/vietqr/payment-requests?orderCode=${dataVietQR.orderCode}&paymentLinkId=${dataVietQR.paymentLinkId}&bin=${dataVietQR.bin}`
        ),
      ])
        .then(async (results) => {
          const [res] = results.map((res) => res.data);
          if (res.data.status === "PAID") {
            const dataSend = {
              id: orders.id,
              row: orders,
              paymentStatus: "paid",
              budgetsmayorders: orders.budgetsmayorders,
            };
            await axiosusers.post(`/orders/complete-orders`, dataSend);
            toast.success("Thanh toán thành công", {
              position: "top-right",
            });
            setTimeout(function () {
              navigate("/manager/donhang");
            }, 1000);
          }
          if (res.data.status === "CANCELLED") {
            toast.error("Thanh toán thất bại", {
              position: "top-right",
            });
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(fetchData, 5000);
    return () => clearInterval(intervalId);
  }, [dataVietQR, orders]);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <img
          style={{ width: 300, height: 300, cursor: "pointer" }}
          src={maQR}
          onClick={() => handleOpen(maQR)}
        />
      </Box>
      {isOpen && (
        <>
          <div className={styles.lightbox}>
            <div className={styles.lightboxOverlay} onClick={handleClose} />
            <div className={styles.headers}>
              <Tooltip title="Close">
                <div className={styles.closeButton} onClick={handleClose}>
                  <IconButton>
                    <CloseIcon className={styles.icon} />
                  </IconButton>
                </div>
              </Tooltip>
            </div>

            <div className={styles.lightboxContent}>
              <img className={styles.lightboxImg} src={maQR} />
            </div>
          </div>
        </>
      )}
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default GetVietQR;
