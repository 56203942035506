import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Typography } from "@material-ui/core";
import Collapse from "@mui/material/Collapse";
import React, { useState } from "react";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import NavLinkButton from "components/NavLinks/NavLinkButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import MoneyIcon from "@mui/icons-material/Money";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import TableBarIcon from "@mui/icons-material/TableBar";
import CoffeeMakerIcon from "@mui/icons-material/CoffeeMaker";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
// import RestaurantIcon from "@mui/icons-material/Restaurant";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import FireTruckIcon from "@mui/icons-material/FireTruck";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
const MenuItems = (props) => {
  const { data } = props;
  const classes = useStyles();
  const [open, setOpen] = useState({});

  const handleClick = (id) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [id]: !prevOpen[id],
    }));
  };

  const menuItems = [
    {
      id: 1,
      path: "",
      icon: <AssessmentIcon />,
      label: "Báo Cáo",
      data: ["Manager", "Staff", "Admin"],
      nestedList: [
        {
          path: "/manager/reports-all/",
          icon: <AssessmentIcon />,
          label: "Báo Cáo Tất Cả",
          data: ["Manager", "Admin"],
        },
        {
          path: "/manager/reports-staff/",
          icon: <AssessmentIcon />,
          label: "Báo Cáo Nhân Viên",
        },

        {
          path: "/manager/reports-admin/",
          icon: <AssessmentIcon />,
          label: "Báo Cáo % Lợi Nhuận",
          data: ["Manager", "Admin"],
        },
        // {
        //   path: "/manager/danhsachban/fastfood",
        //   label: "Fastfood",
        //   icon: <RestaurantIcon fontSize="small" />,
        //   data: ["Manager", "Admin"],
        // },
      ],
    },

    {
      id: 2,
      path: "/manager/checkin-checkout",
      icon: <AssignmentIndIcon />,
      label: "Check in và Check out",
    },
    {
      id: 2,
      path: "/manager/sanpham",
      icon: <AllInboxIcon />,
      label: "Sản Phẩm",
    },
    {
      id: 3,
      path: "/manager/thucdonsanpham",
      icon: <RestaurantMenuIcon />,
      label: "Thực Đơn",
    },
    {
      id: 4,
      path: "",
      icon: <TableBarIcon />,
      label: "Danh Sách Bàn",
      data: ["Manager", "Staff", "Admin"],
      nestedList: [
        {
          path: "/manager/danhsachban/taoban",
          label: "Tạo Bàn",
          icon: <ControlPointIcon fontSize="small" />,
          data: ["Manager", "Staff", "Admin"],
        },
        {
          path: "/manager/danhsachban/coffee",
          label: "Coffee & Fastfood",
          icon: <CoffeeMakerIcon fontSize="small" />,
          data: ["Manager", "Staff", "Admin"],
        },

        {
          path: "/manager/danhsachban/playstation",
          label: "Playstation",
          icon: <SportsEsportsIcon fontSize="small" />,
          data: ["Manager", "Staff", "Admin"],
        },
        // {
        //   path: "/manager/danhsachban/fastfood",
        //   label: "Fastfood",
        //   icon: <RestaurantIcon fontSize="small" />,
        //   data: ["Manager", "Admin"],
        // },
      ],
    },
    {
      id: 5,
      path: "/manager/khachhang",
      icon: <SupervisedUserCircleIcon />,
      label: "Khách Hàng",
    },
    {
      id: 6,
      path: "/manager/thanhtoan",
      icon: <AccountBalanceIcon />,
      label: "Thanh Toán",
    },
    {
      id: 7,
      path: "/manager/id-giaodich",
      icon: <MoneyIcon />,
      label: "ID Giao Dịch",
    },
    {
      id: 8,
      path: "/manager/donhang",
      icon: <ShoppingCartIcon />,
      label: "Đơn Hàng",
    },
    {
      id: 12,
      path: "/manager/nhacungcap",
      icon: <SupervisorAccountIcon />,
      label: "Nhà Cung Cấp",
      data: ["Manager", "Admin"],
    },
    {
      id: 9,
      path: "",
      icon: <WarehouseIcon />,
      label: "Kho Phụ",
      data: ["Manager", "Admin"],
      nestedList: [
        {
          path: "/manager/thucdon",
          label: "Thực Đơn Kho",
          icon: <MenuBookIcon fontSize="small" />,
        },
        {
          path: "/manager/nhapkho",
          label: "Nhập Kho",
          icon: <FireTruckIcon fontSize="small" />,
        },
        {
          path: "/manager/xuatkho",
          label: "Xuất Kho",
          icon: <FileUploadIcon fontSize="small" />,
        },
        {
          path: "/manager/trahang",
          label: "Trả Hàng",
          icon: <UnarchiveIcon fontSize="small" />,
        },
      ],
    },
    {
      id: 10,
      path: "",
      icon: <OtherHousesIcon />,
      label: "Kho Chính",
      data: ["Manager", "Admin"],
      nestedList: [
        {
          path: "/manager/thucdonkho-chinh",
          label: "Thực Đơn Chính",
          icon: <MenuBookIcon fontSize="small" />,
        },

        {
          path: "/manager/nhapkho-chinh",
          label: "Nhập Kho Chính",
          icon: <FireTruckIcon fontSize="small" />,
        },
        {
          path: "/manager/trahang-chinh",
          label: "Trả Hàng Chính",
          icon: <UnarchiveIcon fontSize="small" />,
        },
      ],
    },
    {
      id: 11,
      path: "",
      icon: <MonetizationOnIcon />,
      label: "Chi Phí Đầu Ra",
      data: ["Manager", "Admin"],
      nestedList: [
        {
          path: "/manager/chi-phi",
          label: "Tạo Chi Phí",
          icon: <ControlPointIcon fontSize="small" />,
        },
        {
          path: "/manager/nhap-chi-phi",
          label: "Nhập Chi Phí",
          icon: <RequestQuoteIcon fontSize="small" />,
        },
      ],
    },
    {
      id: 12,
      path: "",
      icon: <CardGiftcardIcon />,
      label: "Giảm giá",
      data: ["Admin"],
      nestedList: [
        {
          path: "/manager/vouchers",
          label: "Mã Giảm Giá",
          icon: <CardGiftcardIcon fontSize="small" />,
        },
        {
          path: "/manager/discount",
          label: "Giảm giá",
          icon: <LocalOfferIcon fontSize="small" />,
        },
      ],
    },

    {
      id: 12,
      path: "/manager/tienluong",
      icon: <AssuredWorkloadIcon />,
      label: "Tiền Lương",
      data: ["Admin"],
    },
    {
      id: 12,
      path: "/manager/taikhoan",
      icon: <PersonIcon />,
      label: "Tài Khoản",
      data: ["Admin"],
    },

    {
      id: 14,
      path: "/manager/telegram-api",
      icon: <VpnKeyIcon />,
      label: "Telegram Api",
      data: ["Manager", "Admin"],
    },
  ];

  return (
    <div className={classes.roott}>
      <List>
        {menuItems.flatMap(
          (menuItem) =>
            (!menuItem.data || menuItem.data.includes(data?.role)) && (
              <NavLinkButton key={menuItem.path} href={menuItem.path}>
                <ListItem
                  button
                  className={classes.listitem}
                  onClick={
                    menuItem.nestedList ? () => handleClick(menuItem.id) : null
                  }
                >
                  <ListItemIcon className={classes.item}>
                    {menuItem.icon}
                  </ListItemIcon>
                  <Typography className={classes.text}>
                    {menuItem.label}
                  </Typography>
                  {menuItem.nestedList && (
                    <>{open[menuItem.id] ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItem>
                {menuItem.nestedList && (
                  <Collapse in={open[menuItem.id]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {menuItem.nestedList.map(
                        (nestedItem) =>
                          (!nestedItem.data ||
                            nestedItem.data.includes(data?.role)) && (
                            <NavLinkButton
                              key={nestedItem.path}
                              href={nestedItem.path}
                              underline="none"
                            >
                              <ListItem button>
                                <ListItemIcon className={classes.item}>
                                  {nestedItem.icon}
                                </ListItemIcon>
                                <Typography className={classes.text2}>
                                  {nestedItem.label}
                                </Typography>
                              </ListItem>
                            </NavLinkButton>
                          )
                      )}
                    </List>
                  </Collapse>
                )}
              </NavLinkButton>
            )
        )}
      </List>
    </div>
  );
};

export default MenuItems;
const useStyles = makeStyles((theme) => ({
  textname: {
    fontWeight: 500,
    color: "#546e7a",
    fontSize: "16px",
    padding: theme.spacing(0, 0, 2, 0),
  },
  textname2: {
    fontWeight: 500,
    fontSize: "20px",
  },
  avtconter: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2, 0, 1, 0),
    alignItems: "center",
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  textconter: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  imgavatar: {
    cursor: "pointer",
  },
  colorToolbar: {
    backgroundColor: "#3f50b5",
    borderRight: `1px solid #3f50b5`,
  },
  roott: {
    width: "95%",
    color: "#546e7a",
    padding: theme.spacing(0, 0, 0, 2),
    maxWidth: 360,
    backgroundColor: theme.palette.background.hover,
  },
  item: {
    color: "inherit",
    borderRadius: "20px",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    "&.active > div": {
      color: theme.palette.primary.main,
    },
  },
  text: {
    fontWeight: 500,
    marginLeft: "-25px",
    padding: "20xp",
    fontSize: "15px",
    flex: 1,
  },
  text2: {
    fontWeight: 500,
    marginLeft: "-25px",
    padding: "20xp",
    fontSize: "13px",
    flex: 1,
  },
  listitem: {
    padding: "10px 8px",
    borderRadius: "4px",
    fontSize: "16px",
  },
}));
