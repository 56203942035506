import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const AddDiscount = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [status, setStatus] = React.useState("default");
  const initialValues = {
    percent: "",
  };
  const validationSchema = Yup.object().shape({
    percent: Yup.string()
      .min(0, "It's too short")
      .required("Phần trăm bắt buộc"),
  });

  const onSubmit = async (values) => {
    setDung(true);

    const data = {
      percent: values.percent,
      status,
    };
    try {
      const response = await axiosusers.post(`/discount/create-discount`, data);
      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/discount");
        }, 500);
      }
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={dung ? null : onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              type="number"
              name="percent"
              label="Phần trăm"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.percent && props.touched.percent}
              helperText={<ErrorMessage name="percent" />}
            />
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              style={{ marginTop: `0.5rem` }}
            >
              <InputLabel>Chọn trạng thái</InputLabel>
              <Select
                label="Chọn trạng thái"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="default">Mặc định</MenuItem>
                <MenuItem value="stop">Dừng lại</MenuItem>
              </Select>
            </FormControl>
            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddDiscount;
