import { Box, CircularProgress } from "@mui/material";

import { Tabtitle } from "components/title/Title";
import React from "react";
import axiosusers from "utils/api/axiosusers";
import DetailOrders from "./Main/DetailOrders";
import { useParams } from "react-router-dom";
import useUserId from "hooks/useUserId";
import TypographyOrders from "./Main/TypographyOrders";
import useSWR from "swr";
import DialogOrders from "./Update/DialogOrders";

const index = () => {
  Tabtitle("Chi tiết đơn hàng");
  const [loading, setLoading] = React.useState(false);
  const [orders, setOrders] = React.useState([]);
  const { id } = useParams();
  const { userId } = useUserId();
  const fetchDataAll = async () => {
    Promise.all([axiosusers.get(`/orders/orders/${id}`)])
      .then(async (results) => {
        const [data] = results.map((res) => res.data);
        setOrders(data);
      })
      .finally(() => setLoading(true))
      .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    fetchDataAll();
  }, [id]);
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: paymentData } = useSWR(`/payments/payments-all`, fetchDataSwr);
  const { data: gamesData } = useSWR(`/products/products-all`, fetchDataSwr);
  const { data: walletsData } = useSWR(`/wallets/wallets-all`, fetchDataSwr);
  const { data: clientsData } = useSWR(`/clients/clients-all`, fetchDataSwr);
  const { data: danhsachbanData } = useSWR(
    `/danhsachban/danhsachban-all`,
    fetchDataSwr
  );
  const { data: ordersInprogress } = useSWR(
    `/orders/orders-inprogress`,
    fetchDataSwr
  );
  let danhsachbanDatas = [];
  let danhsachbanDatas2 = [];
  if (danhsachbanData !== undefined) {
    danhsachbanDatas = danhsachbanData.filter((item) => item.status === false);
    danhsachbanDatas2 = danhsachbanData.filter((item) => item.status === true);
  }
  return (
    <div>
      <TypographyOrders print={false} />
      {loading && orders.status === "inProgress" && (
        <DialogOrders
          order={false}
          gamesData={gamesData === undefined ? [] : gamesData}
          walletsData={walletsData === undefined ? [] : walletsData}
          clientsData={clientsData === undefined ? [] : clientsData}
          danhsachbanData={
            danhsachbanDatas === undefined ? [] : danhsachbanDatas
          }
          id={id}
        />
      )}
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {loading && (
        <DetailOrders
          orders={orders}
          userId={userId}
          deletePhotoOrders={fetchDataAll}
          paymentData={paymentData === undefined ? [] : paymentData}
          danhsachbanData={
            danhsachbanDatas === undefined ? [] : danhsachbanDatas
          }
          danhsachbanDatas2={
            danhsachbanDatas2 === undefined ? [] : danhsachbanDatas2
          }
          ordersInprogress={
            ordersInprogress === undefined ? [] : ordersInprogress
          }
        />
      )}
    </div>
  );
};

export default index;
