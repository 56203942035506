import { Box, IconButton, TextField, Typography } from "@mui/material";
import React from "react";
// import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AutocompleteId from "hooks/useAutocomplete";
import NganSachKhoBudgets from "./NganSachKhoBudgets";
const BudgetsWallets = (props) => {
  const { data, onCheckedChange, ngansachkhoData, input } = props;
  let ngansachkhoDatas = ngansachkhoData;
  if (input?.id !== undefined) {
    ngansachkhoDatas = ngansachkhoData.filter(
      (item) => item.status === input.product.status
    );
  }
  // const auth = JSON.parse(localStorage.getItem("auth"));
  const [inputFields, setInputFields] = React.useState(
    data !== undefined
      ? data
      : [{ id: uuidv4(), name: "", quantity: "", price: "" }]
  );
  const [ngansachkho, setNgansachkho] = React.useState([]);
  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    onCheckedChange(newInputFields);
    setInputFields(newInputFields);
  };
  const handleChangeClick = (id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i["click"] = true;
      }
      return i;
    });
    onCheckedChange(newInputFields);
    setInputFields(newInputFields);
  };

  // const handleAddFields = () => {
  //   const newInputFields = [
  //     ...inputFields,
  //     {
  //       id: uuidv4(),
  //       name: "",
  //       quantity: "",
  //       price: "",
  //     },
  //   ];
  //   setInputFields(newInputFields);
  //   onCheckedChange(newInputFields);
  // };

  const handleRemoveFields = (id) => {
    const newInputFields = inputFields.filter((i) => i.id !== id);
    setInputFields(newInputFields);
    onCheckedChange(newInputFields);
  };
  const handleChangeNgansachkho = (value) => {
    setNgansachkho(value);
  };
  const handleChangeInputNganSach = (id, idInput, event) => {
    const newInputFields = inputFields.map((i) => {
      if (idInput === i.id) {
        i["ngansachkho"] = ngansachkho.map((item) => {
          if (id === item.id) {
            item["byQuantity"] = event.target.value;
          }
          return item;
        });
      }
      return i;
    });
    onCheckedChange(newInputFields);
    setInputFields(newInputFields);
  };
  return (
    <div>
      {inputFields.map((inputField) => (
        <>
          <Box
            key={inputField.id}
            component="form"
            autoComplete="off"
            sx={{ display: "flex", gap: 1, marginTop: "0.5rem" }}
          >
            <TextField
              fullWidth
              label="Tên"
              name="name"
              value={inputField.name}
              variant="outlined"
              onChange={(event) => handleChangeInput(inputField.id, event)}
              size="small"
            />
            {/* <TextField
            fullWidth
            label="Số lượng"
            name="quantity"
            type="number"
            value={inputField.quantity}
            variant="outlined"
            onChange={(event) => handleChangeInput(inputField.id, event)}
            size="small"
          /> */}
            <TextField
              fullWidth
              type="number"
              name="price"
              value={inputField.price}
              onChange={(event) => handleChangeInput(inputField.id, event)}
              label="Giá tiền"
              variant="outlined"
              size="small"
              // InputProps={{
              //   endAdornment: (
              //     <div>
              //       {inputFields.length === Number(id + 1) && (
              //         <Fab
              //           color="primary"
              //           aria-label="add"
              //           size="small"
              //           onClick={
              //             auth?.roles === "Admin" ? handleAddFields : null
              //           }
              //         >
              //           <AddIcon />
              //         </Fab>
              //       )}
              //     </div>
              //   ),
              // }}
            />

            {inputField.updatedAt && (
              <IconButton color="primary" disabled>
                <DeleteIcon />
              </IconButton>
            )}
            <IconButton
              color="primary"
              name="Add"
              onClick={(event) => handleChangeClick(inputField.id, event)}
            >
              <AddCircleIcon />
            </IconButton>

            {inputFields.length !== 1 && !inputField.updatedAt && (
              <IconButton
                color="primary"
                onClick={() => handleRemoveFields(inputField.id)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
          {inputField.updatedAt && inputField.budgetsngansachkho.length > 0 && (
            <NganSachKhoBudgets inputField={inputField} />
          )}
          {inputField.click && (
            <>
              <AutocompleteId
                data={ngansachkhoDatas}
                multiple={true}
                placeholder="Chọn một hoặc nhiều"
                label="Thực đơn chính"
                id="Thực đơn chính"
                name="Thực đơn chính"
                size="small"
                openError={false}
                done={false}
                onCheckedChange={handleChangeNgansachkho}
                optionLabel={(label) => label.name}
              />
              {ngansachkho.length > 0 && (
                <>
                  <Box
                    component="form"
                    autoComplete="off"
                    sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
                  >
                    <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                      Tên thực đơn chính
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                      Đơn vị tính
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                      Số lượng
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                      Tính số lượng
                    </Typography>
                  </Box>
                  {ngansachkho.map((row) => {
                    return (
                      <Box
                        key={row.id}
                        component="form"
                        autoComplete="off"
                        sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ fontSize: "1rem", flex: 1 }}
                        >
                          {row.name}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontSize: "1rem", flex: 1 }}
                        >
                          {row.unit}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontSize: "1rem", flex: 1 }}
                        >
                          {row.quantity}
                        </Typography>
                        <TextField
                          fullWidth
                          type="number"
                          name="byQuantity"
                          label="Tính số lượng"
                          onChange={(event) =>
                            handleChangeInputNganSach(
                              row.id,
                              inputField.id,
                              event
                            )
                          }
                          variant="outlined"
                          size="small"
                          sx={{ flex: 1 }}
                        />
                      </Box>
                    );
                  })}
                </>
              )}
            </>
          )}
        </>
      ))}
    </div>
  );
};

export default BudgetsWallets;
