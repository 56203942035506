import {
  Box,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import axiosusers from "utils/api/axiosusers";
import _ from "lodash";
import BasicPagination from "hooks/usePagination";
const TablePayment = (props) => {
  const { product, onToast } = props;
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const handleClose = ({ message, status }) => {
    onToast({ message, status });
  };
  const pageSize = 5;
  const [pagination, setPagination] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);

  const getWithdraw = async () => {
    setPagination(
      _(product.withdrawTransactions).slice(0).take(pageSize).value()
    );
  };

  const pageCount = product.withdrawTransactions
    ? Math.ceil(product.withdrawTransactions.length / pageSize)
    : 0;

  useEffect(() => {
    getWithdraw();
  }, [product]);
  const SubmitWindraw = async (id) => {
    const data = {
      id,
      status: "completed",
    };

    try {
      const response = await axiosusers.post(
        `/payments/update-withdraws`,
        data
      );
      console.log(response);

      if (response.status === 200) {
        handleClose({ message: response.data.message, status: true });
      }
    } catch (error) {
      console.log(error?.response);
      handleClose({ message: error.response.data.message, status: false });
    }
  };
  const Pagechane = (value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(product.withdrawTransactions)
      .slice(startIndex)
      .take(pageSize)
      .value();
    setPagination(pagination);
    setCurrentPage(value);
  };
  return (
    <Box sx={{ marginTop: "3rem" }}>
      <Divider style={{ backgroundColor: "#000" }} />
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Withdrawn By</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagination.map((item) => {
              var nullCreate = item.createdAt.split("T");
              var dateCreate = nullCreate[0].split("-");
              var dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              return (
                <TableRow hover key={item.id}>
                  <TableCell component="th" scope="row">
                    {dateCreateAt}
                  </TableCell>
                  <TableCell>{number.format(item.amount)} ₫</TableCell>
                  <TableCell>{item.user.name}</TableCell>
                  <TableCell>In progress</TableCell>
                  <TableCell align="center" size="small">
                    <IconButton
                      color="primary"
                      onClick={() => SubmitWindraw(item.id)}
                    >
                      <DoneIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box sx={{ marginTop: "20px" }}>
          <BasicPagination
            onCheckedChange={(e) => Pagechane(e)}
            pageCount={pageCount}
            page={currentPage}
          />
        </Box>
      </TableContainer>
    </Box>
  );
};

export default TablePayment;
