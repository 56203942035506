import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Grid, Paper } from "@mui/material";

import SearchInput from "hooks/useSearchInput";
import React from "react";

const SearchTaoban = (props) => {
  const { searchOrderNumber, setSearchStatus } = props;
  return (
    <div>
      <Grid
        container
        sx={{ width: "100%", margin: "40px auto", height: "auto" }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Chọn sản phẩm</InputLabel>
                <Select
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                  }}
                  label="Chọn sản phẩm"
                  name="status"
                >
                  <MenuItem value="Coffe & Fastfood">Coffe & Fastfood</MenuItem>
                  <MenuItem value="Playstation">Playstation</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <SearchInput
                onCheckedChange={(e) => searchOrderNumber(e)}
                placeholder="Tìm kiếm số thứ tự"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default SearchTaoban;
