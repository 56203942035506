import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosusers from "utils/api/axiosusers";
import UndoIcon from "@mui/icons-material/Undo";
const DeleteClients = (props) => {
  const { id, onToast, row } = props;
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const handleClickOpen = (name) => {
    setStatus(name);
    setOpen(true);
  };

  const handleClose = ({ message, status }) => {
    onToast({ message, status });
  };

  const handleDelete = async () => {
    setOpen(false);

    const dataSend = { id };
    try {
      const response = await axiosusers.post(
        `/vouchers/${status}-vouchers`,
        dataSend
      );
      if (response.status === 200) {
        handleClose({ message: response.data.message, status: true });
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.message || "An error occurred";
      handleClose({ message: errorMessage, status: false });
    }
  };

  return (
    <>
      {row.copy && (
        <IconButton
          color="primary"
          onClick={() => handleClickOpen("undo-copy")}
        >
          <UndoIcon />
        </IconButton>
      )}

      <IconButton color="primary" onClick={() => handleClickOpen("delete")}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {status} this voucher?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteClients;
