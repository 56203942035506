import { Box } from "@mui/material";
import { Tabtitle } from "components/title/Title";
import React from "react";
import AddOrders from "./AddOrders";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";
import { useParams } from "react-router-dom";

const index = () => {
  Tabtitle("Tạo đơn hàng");
  const { id } = useParams();
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const { data: gamesData } = useSWR(`/products/products-all`, fetchDataSwr);
  const { data: walletsData } = useSWR(`/wallets/wallets-all`, fetchDataSwr);
  const { data: clientsData } = useSWR(`/clients/clients-all`, fetchDataSwr);
  const { data: danhsachbanData } = useSWR(
    `/danhsachban/danhsachban-all`,
    fetchDataSwr
  );
  let danhsachbanDatas = [];
  if (danhsachbanData !== undefined) {
    danhsachbanDatas = danhsachbanData.filter((item) => item.status === false);
  }
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <TypographyAddEdit name="Tạo đơn hàng" />
      {id && danhsachbanData !== undefined && (
        <AddOrders
          id={id}
          gamesData={gamesData === undefined ? [] : gamesData}
          walletsData={walletsData === undefined ? [] : walletsData}
          clientsData={clientsData === undefined ? [] : clientsData}
          danhsachbanData={
            danhsachbanDatas === undefined ? [] : danhsachbanDatas
          }
        />
      )}
    </Box>
  );
};

export default index;
