import React from "react";
import AlarmAddIcon from "@mui/icons-material/AlarmAdd";
import TimerIcon from "@mui/icons-material/Timer";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axiosusers from "utils/api/axiosusers";
const ButtonCheckinCheckout = (props) => {
  const { onToast, data, product } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = ({ message, status }) => {
    onToast({ message, status });
  };

  const handleDelete = async () => {
    setOpen(false);

    const dataSend = { data };
    try {
      const response = await axiosusers.post(`/users/ckeck-users`, dataSend);
      if (response.status === 200) {
        handleClose({ message: response.data.message, status: true });
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.message || "An error occurred";
      handleClose({ message: errorMessage, status: false });
    }
  };

  const statusCheck = product.filter(
    (item) => item.userId === data.id && item.status === "inProgress"
  );

  return (
    <div>
      <Box
        style={{
          color: "inherit",
          textDecoration: "none",
          float: "right",
          margin: "1rem 0",
        }}
      >
        {statusCheck.length === 0 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickOpen("checkin")}
            startIcon={<AlarmAddIcon />}
          >
            giờ vào
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => handleClickOpen("checkout")}
            color="primary"
            startIcon={<TimerIcon />}
          >
            giờ về
          </Button>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bạn có chắc chắn muốn thực hiện thao tác này không?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ButtonCheckinCheckout;
