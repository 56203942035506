import useLinks from "hooks/useLinks";
import { Button } from "@mui/material";
import React from "react";

const CopyVouchers = (props) => {
  const { row } = props;
  const linkImage = useLinks();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const canvasRef = React.useRef(null);

  const handleClose = () => {
    setIsOpen(false);
    setCurrentIndex(0);
  };

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (isOpen) {
        if (event.key === "Escape") {
          handleClose();
        } else if (event.key === "Enter") {
          const element = document.documentElement;
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
          } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, currentIndex]);

  React.useEffect(() => {
    const drawImageWithText = async () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = `${linkImage + props.nameImage}`;

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        ctx.font = "48px Arial, sans-serif";
        ctx.crossOrigin = "Anonymous";
        if (window.innerWidth <= 600) {
          ctx.font = "5px Arial, sans-serif";
        }
        ctx.fillStyle = "#fff";
        ctx.letterSpacing = "3px";
        ctx.fontWeight = 600;
        const divCode = {
          top: "89%",
          left: "20%",
          fontWeight: 600,
        };
        const text = row.code;
        const textWidth = ctx.measureText(text).width;
        const textX = (canvas.width - textWidth) / 4.2;

        const textY = (canvas.height * parseFloat(divCode.top)) / 99.2;
        ctx.fillStyle = "#fff";
        ctx.fillText(text, textX, textY);
      };
    };
    drawImageWithText();
  }, [linkImage, row]);

  const handleCopy = async () => {
    const canvas = canvasRef.current;
    if (canvas) {
      try {
        const dataUrl = canvas.toDataURL("image/png");
        await navigator.clipboard.write([
          new ClipboardItem({
            "image/png": await fetch(dataUrl).then((res) => res.blob()),
          }),
        ]);
      } catch (err) {
        console.error("Failed to copy image: ", err);
      }
    }
  };

  return (
    <div>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      <Button variant="contained" color="primary" onClick={handleCopy}>
        Sao chép ảnh
      </Button>
    </div>
  );
};

export default CopyVouchers;
