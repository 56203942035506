import React from "react";
import AddUsers from "./AddUsers";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Create User");
  return (
    <div>
      <AddUsers />
    </div>
  );
};

export default index;
