import { Box } from "@mui/material";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";
import { Tabtitle } from "components/title/Title";
import React from "react";
import AddXuatkho from "./AddXuatkho";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";

const index = () => {
  Tabtitle("Tạo xuất kho");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: thucdonData } = useSWR(`/thucdon/thucdon-all`, fetchDataSwr);
  const { data: productsData } = useSWR(`/products/products-all`, fetchDataSwr);
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <TypographyAddEdit name="Tạo xuất kho" />
      <Box sx={{ marginTop: "1.5rem" }}>
        <AddXuatkho
          thucdonData={thucdonData === undefined ? [] : thucdonData}
          productsData={productsData === undefined ? [] : productsData}
        />
      </Box>
    </Box>
  );
};

export default index;
