import { Box } from "@mui/material";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";
import { Tabtitle } from "components/title/Title";
import React from "react";
import AddVouchers from "./AddVouchers";

const index = () => {
  Tabtitle("Tạo Mã Giảm Giá");
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <TypographyAddEdit name="Tạo Mã Giảm Giá" />
      <Box sx={{ marginTop: "1.5rem" }}>
        <AddVouchers />
      </Box>
    </Box>
  );
};

export default index;
