import { FormControl, InputLabel, Select } from "@material-ui/core";
import { Grid, MenuItem, Paper } from "@mui/material";
import SearchInput from "hooks/useSearchInput";
import React from "react";

const SearchClients = (props) => {
  const { searchName, setSearchStatus } = props;
  return (
    <div>
      <Grid
        container
        sx={{ width: "100%", margin: "40px auto", height: "auto" }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Trạng Thái</InputLabel>
                <Select
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                  }}
                  label="Trạng Thái"
                  name="status"
                >
                  <MenuItem value="true">Đã sao chép</MenuItem>
                  <MenuItem value="false">Chưa sao chép</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <SearchInput
                onCheckedChange={(e) => searchName(e)}
                placeholder="Tìm kiếm theo mã"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default SearchClients;
