import { Alert, Box, Button } from "@mui/material";
import { Form, Formik } from "formik";
import AutocompleteId from "hooks/useAutocomplete";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosusers from "utils/api/axiosusers";
const FromGhepDon = ({ ordersInprogress, orders, danhsachbanDatas2 }) => {
  const danhsachban = danhsachbanDatas2.filter((item) => {
    const ordersSome = orders.budgetsmayorders.some(
      (row) => row.danhsachbanId === item.id
    );
    return !ordersSome;
  });

  const [status, setStatus] = React.useState("");
  const handleClickOpen = (name) => {
    setStatus(name);
  };
  const [inputOrders, setInputOrders] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const handleChangeOrder = (value) => {
    setInputOrders(value);
  };
  const initialValues = {
    price: "",
    notes: "",
    nameGame: "",
    bonusAmount: "",
  };
  const validationSchema = "";
  const [done, setDone] = React.useState(false);

  const onSubmit = async () => {
    setDone(true);
    setErrorMessage("");
    let errorMessage = "";
    if (inputOrders?.id === undefined && status === "order") {
      errorMessage = "Chọn đơn hàng";
    }
    if (inputOrders?.id === undefined && status === "ban") {
      errorMessage = "Chọn bàn";
    }
    if (inputOrders?.id === undefined && status === "may") {
      errorMessage = "Chọn máy";
    }
    if (errorMessage) {
      setDone(false);
      return setErrorMessage(errorMessage);
    }

    const data = {
      id: orders.id,
      inputOrders,
    };

    try {
      const response = await axiosusers.post(`/orders/ghep-${status}`, data);

      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          window.location.reload();
        }, 100);
      }
    } catch (error) {
      setDone(false);
      console.log(error);
      toast.error("Update error", {
        position: "top-right",
      });
    }
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          gap: 1.5,
          margin: "1rem auto",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <Button
          onClick={() => handleClickOpen("order")}
          variant="contained"
          size="small"
          color="primary"
        >
          Ghép theo đơn hàng
        </Button>

        <Button
          onClick={() => handleClickOpen("ban")}
          variant="contained"
          size="small"
          color="primary"
        >
          Ghép theo bàn
        </Button>
        <Button
          onClick={() => handleClickOpen("may")}
          variant="contained"
          size="small"
          color="primary"
        >
          Ghép theo máy
        </Button>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={done ? null : onSubmit}
      >
        {() => (
          <Form autoComplete="off">
            {status === "order" && (
              <AutocompleteId
                data={ordersInprogress.filter((item) => item.id !== orders.id)}
                label="Chọn đơn hàng"
                id="Chọn đơn hàng"
                name="Chọn đơn hàng"
                size="small"
                openError={true}
                done={errorMessage && inputOrders?.id === undefined}
                onCheckedChange={handleChangeOrder}
                optionLabel={(label) => "Đơn hàng " + label.id}
              />
            )}
            {status === "ban" && (
              <AutocompleteId
                data={danhsachban.filter(
                  (item) => item.selectProduct !== "Playstation"
                )}
                label="Chọn bàn"
                id="Chọn bàn"
                name="Chọn bàn"
                size="small"
                openError={true}
                done={errorMessage && inputOrders?.id === undefined}
                onCheckedChange={handleChangeOrder}
                optionLabel={(label) => "Bàn số " + label.orderNumber}
              />
            )}
            {status === "may" && (
              <AutocompleteId
                data={danhsachban.filter(
                  (item) => item.selectProduct === "Playstation"
                )}
                label="Chọn máy"
                id="Chọn máy"
                name="Chọn máy"
                size="small"
                openError={true}
                done={errorMessage && inputOrders?.id === undefined}
                onCheckedChange={handleChangeOrder}
                optionLabel={(label) => "Máy số " + label.orderNumber}
              />
            )}
            {errorMessage && (
              <Alert sx={{ marginTop: `0.5rem` }} severity="error">
                {errorMessage}
              </Alert>
            )}
            {status && (
              <Button
                type="submit"
                sx={{ marginTop: `2rem` }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={done}
              >
                Ghép đơn
              </Button>
            )}
          </Form>
        )}
      </Formik>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default FromGhepDon;
