import { Typography } from "@mui/material";
import React from "react";

const TypographyAddEdit = ({ name, primary }) => {
  return (
    <Typography
      sx={{
        fontWeight: 500,
      }}
      color={primary !== undefined ? "primary" : ""}
      variant="h4"
    >
      {name}
    </Typography>
  );
};

export default TypographyAddEdit;
