import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, MenuItem, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, Select } from "@material-ui/core";

const AddTaoban = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);

  const initialValues = {
    orderNumber: "",
  };
  const validationSchema = Yup.object().shape({
    orderNumber: Yup.string()
      .min(0, "It's too short")
      .required("Số bàn là bắt buộc"),
  });

  const [status, setStatus] = React.useState("");
  const onSubmit = async (values) => {
    setDung(true);
    let errorMessage = "";

    if (status === "") {
      errorMessage = "Chọn sản phẩm không được trống";
    }
    if (errorMessage !== "") {
      setDung(false);
      seterrorMessage(errorMessage);
      toast.error(errorMessage, {
        position: "top-right",
      });
      return;
    }
    const data = {
      orderNumber: values.orderNumber,
      selectProduct: status,
      usedPrice: 0,
    };
    try {
      const response = await axiosusers.post(
        `/danhsachban/create-danhsachban`,
        data
      );
      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/danhsachban/taoban/");
        }, 500);
      }
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={dung ? null : onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <FormControl
              style={{ marginTop: "0.5rem" }}
              fullWidth
              variant="outlined"
            >
              <InputLabel>Chọn sản phẩm</InputLabel>
              <Select
                label="Chọn sản phẩm"
                name="status"
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="Coffe & Fastfood">Coffe & Fastfood</MenuItem>
                <MenuItem value="Playstation">Playstation</MenuItem>
              </Select>
            </FormControl>
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              type="number"
              name="orderNumber"
              label="Số bàn"
              variant="outlined"
              fullWidth
              error={props.errors.orderNumber && props.touched.orderNumber}
              helperText={<ErrorMessage name="orderNumber" />}
            />

            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddTaoban;
