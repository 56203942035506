import { Tabtitle } from "components/title/Title";
import React from "react";
import Wallets from "./Main/Wallets";
import NavLinkAdd from "components/NavLinks/NavLinkAdd";
import WalletsProducts from "./Main/WalletsProducts";
import axiosusers from "utils/api/axiosusers";
import { Box, CircularProgress } from "@mui/material";
import useSWR from "swr";
const MAX_RETRIES = 3;
const index = () => {
  Tabtitle("Thực Đơn");
  const data = JSON.parse(localStorage.getItem("auth"));
  const handleGamesData = (data) => {
    setSearchName(data.name);
  };
  const [wallets, setWallets] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const pageSize = 27;
  const [retryCount, setRetryCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [searchName, setSearchName] = React.useState("");
  const [pageCount, setPageCount] = React.useState(0);
  const [searchGames, setSearchGames] = React.useState([]);

  const fetchDataAll = async () => {
    try {
      const response = await axiosusers.get("/wallets/wallets", {
        params: {
          searchName,
          pageSize,
          searchGames: searchGames?.id,
          currentPage: currentPage === 0 ? 1 : currentPage,
        },
      });
      const res = response.data;
      setWallets(res.wallets);
      setPageCount(Math.ceil(res.pages / pageSize));
      setLoading(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("401 Unauthorized. Retrying...");
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          fetchDataAll();
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error(error);
      }
    }
  };

  React.useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [searchName, searchGames, pageSize, currentPage]);

  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const { data: gamesData } = useSWR(`/products/products-all`, fetchDataSwr);

  const handleCurrentPage = (newChecked) => {
    setCurrentPage(newChecked);
  };
  const handleSearchName = (value) => {
    setSearchName(value);
  };
  const onCheckedChange = (value) => {
    setSearchGames(value);
  };
  return (
    <div>
      {data?.role === "Admin" && (
        <NavLinkAdd href="/manager/thucdonsanpham/add" name="Tạo Thực Đơn" />
      )}
      <Wallets
        handleGames={handleGamesData}
        gamesData={gamesData}
        handleSearchName={handleSearchName}
        onCheckedChange={onCheckedChange}
      />
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {loading && (
        <WalletsProducts
          handleCurrentPage={handleCurrentPage}
          gamesData={gamesData === undefined ? [] : gamesData}
          handleCurrentDelete={fetchDataAll}
          walletsData={wallets}
          pageCount={pageCount}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default index;
