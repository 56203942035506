import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteClients from "./DeleteClients";
import ImageVouchers from "./ImageVouchers";
import CopyVouchers from "./CopyVouchers";
import CopyMaVouchers from "./CopyMaVouchers";

const TableVouchers = (props) => {
  const { product, handleCurrentDelete } = props;

  const showToast = ({ message, status }) => {
    handleCurrentDelete();
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell>Mã giảm giá</TableCell>
              <TableCell>Trạng thái</TableCell>
              <TableCell>Ảnh</TableCell>
              <TableCell>Ngày tạo</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product.map((row) => {
              var nullCreate = row.createdAt.split("T");
              var dateCreate = nullCreate[0].split("-");
              var dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.orderId}
                  </TableCell>
                  <TableCell>
                    <CopyMaVouchers
                      id={row.id}
                      row={row}
                      onToast={showToast}
                      onCheckedChange={(e) => handleCurrentDelete(e)}
                    />
                  </TableCell>
                  <TableCell style={{ color: row.status ? "red" : "" }}>
                    {row.status ? "Đã sử dụng" : "Chưa sử dụng"}
                  </TableCell>
                  <TableCell>
                    <ImageVouchers nameImage={row.image} row={row} />
                  </TableCell>
                  <TableCell>{dateCreateAt}</TableCell>
                  <TableCell>
                    <CopyVouchers nameImage={row.image} row={row} />
                  </TableCell>
                  <TableCell size="small" align="center">
                    <DeleteClients
                      id={row.id}
                      row={row}
                      onToast={showToast}
                      onCheckedChange={(e) => handleCurrentDelete(e)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default TableVouchers;
