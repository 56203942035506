import { Button } from "@mui/material";
import useLinks from "hooks/useLinks";
import React from "react";

const EditVouchers = () => {
  const canvasRef = React.useRef(null);
  const linkImage = useLinks();

  React.useEffect(() => {
    const drawImageWithText = async () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = `${linkImage}/voucher.jpg`;

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);

        ctx.font = "80px Arial, sans-serif";
        if (window.innerWidth <= 600) {
          ctx.font = "40px Arial, sans-serif";
        }
        const text = "32131";
        const textWidth = ctx.measureText(text).width;
        const textX = (canvas.width - textWidth) / 4.2;
        const textY = canvas.height * 0.89;

        ctx.fillStyle = "#fff";

        ctx.fillText(text, textX, textY);
      };
    };
    drawImageWithText();
  }, [linkImage]);

  const handleCopy = async () => {
    const canvas = canvasRef.current;
    if (canvas) {
      try {
        const dataUrl = canvas.toDataURL("image/png");
        await navigator.clipboard.write([
          new ClipboardItem({
            "image/png": await fetch(dataUrl).then((res) => res.blob()),
          }),
        ]);
      } catch (err) {
        console.error("Failed to copy image: ", err);
      }
    }
  };

  return (
    <div>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      <Button variant="contained" color="primary" onClick={handleCopy}>
        Copy
      </Button>
    </div>
  );
};

export default EditVouchers;
