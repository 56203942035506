import React from "react";
import EditUsers from "./EditTelegramApi";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Update Telegram API");
  return (
    <div>
      <EditUsers />
    </div>
  );
};

export default index;
