import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import NumberVn from "hooks/NumberVn";
import AutocompleteId from "hooks/useAutocomplete";

const AddTienLuong = ({ usersData }) => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [inputUser, setInputUser] = React.useState([]);
  const initialValues = {
    price: "",
  };
  const validationSchema = Yup.object().shape({
    price: Yup.string().min(0, "It's too short").required("Giá bắt buộc"),
  });
  const handleChangeUser = (value) => {
    setInputUser(value);
  };
  const onSubmit = async (values) => {
    setDung(true);
    if (inputUser?.id === undefined) {
      setDung(false);
      toast.error("Please select user", {
        position: "top-right",
      });
      return seterrorMessage("Please select user");
    }
    const data = {
      price: values.price,
      userId: inputUser.id,
    };
    try {
      const response = await axiosusers.post(
        `/tienluong/create-tienluong`,
        data
      );
      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/tienluong");
        }, 500);
      }
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={dung ? null : onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <AutocompleteId
              data={usersData}
              label="Chọn người dùng"
              id="Chọn người dùng"
              name="Chọn người dùng"
              size="small"
              openError={true}
              done={errorMessage && inputUser?.id === undefined}
              onCheckedChange={handleChangeUser}
              optionLabel={(label) => label.name}
            />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              type="number"
              name="price"
              label="Giá"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.price && props.touched.price}
              helperText={<ErrorMessage name="price" />}
            />
            <NumberVn price={props.values.price} fontWeight={500} />
            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              multiline
              rows={4}
              name="notes"
              label="Ghi chú"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.fullName && props.touched.fullName}
              helperText={<ErrorMessage name="fullName" />}
            />
            {errorMessage && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ margin: (theme) => theme.spacing(5, 0) }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={dung}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>

      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default AddTienLuong;
