import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import axiosusers from "utils/api/axiosusers";
import FilledAlerts from "components/Alert/FilledAlerts";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuCheckBox from "../AddUsers/MenuCheckBox";
import DeleteUsers from "./DeleteUsers";
const EditUsers = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [usersworkplace, setUsersworkplace] = React.useState([]);
  const [input, setInput] = useState({
    name: true,
    email: true,
    code: true,
    salary: "",
    bonus: true,
    bankName: true,
    bankNumber: true,
    bankAccount: true,
    zaloId: true,
    phoneNumber: true,
    maxAbsent: true,
    password: true,
    telegramId: true,
    amount: true,
  });

  const [age, setAge] = React.useState("");
  const [menu, setMenu] = React.useState("");
  const [errorMessage, seterrorMessage] = React.useState(false);
  const getProduct = async () => {
    try {
      const data = await axiosusers.get(`/users/profile/${id}`);
      setInput(data.data);
      const updatedUsersWorkplace = data.data.usersworkplace.map((user) => ({
        ...user,
        name: user.status,
      }));

      setUsersworkplace(updatedUsersWorkplace);
      setAge(data.data.roles);
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, [id]);
  const [state, setState] = React.useState({
    allowZalo: false,
    allowSms: false,
  });

  const handleChange = (e) => {
    setAge(e.target.value);
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  const [error, setError] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleinput = (e) => {
    if (!isValidEmail(input.email)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }

    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setState({ ...state, [e.target.name]: e.target.checked });
  };

  const onSubmit = async () => {
    if (input.role === "1") {
      input.role = "";
    }
    if (input.role === "") {
      return seterrorMessage("The role not found.");
    }
    if (input.password !== undefined && input.password.length < 6) {
      return seterrorMessage("password must be at least 6 characters");
    }
    const data = {
      id,
      name: input.name,
      email: input.email,
      roles: age,
      password: input.password,
      telegramId: input.telegramId,
      amount: input.amount,
      fixedSalary: input.fixedSalary,
      menu,
      usersworkplace,
    };
    try {
      const res = await axiosusers.post(`/users/update-users`, data);
      console.log(res);
      setTimeout(function () {
        navigate("/manager/taikhoan");
      }, 500);
      toast.success(res.data.message, {
        position: "top-right",
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  const dataProduct = [
    { id: 1, name: "Coffee", price: 0 },
    { id: 2, name: "Playstation", price: 0 },
    { id: 3, name: "Fastfood", price: 0 },
    { id: 4, name: "Cake", price: 0 },
  ];
  const handleChangeMenu = (value) => {
    setMenu(value);
  };
  const handleChangeInput = (id, event) => {
    const newInputFields = menu.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setMenu(newInputFields);
  };
  const showToast = ({ message, status }) => {
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  const handleChangeUsers = (value) => {
    setUsersworkplace(value);
  };
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      {loading && (
        <div>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "30px",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
          >
            Update users
          </Typography>
          <TextField
            sx={{ marginTop: `0.5rem` }}
            onChange={handleinput}
            type="text"
            value={input.name}
            name="name"
            error={Boolean(input.name == "")}
            helperText={input.name == "" ? "Name is required" : ""}
            label="Name"
            size="small"
            variant="outlined"
            fullWidth
          />
          <TextField
            sx={{ marginTop: `0.5rem` }}
            onChange={handleinput}
            type="text"
            value={input.email}
            name="email"
            error={Boolean(error)}
            helperText={error}
            label="Email Address"
            size="small"
            variant="outlined"
            fullWidth
          />
          <FormControl
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginTop: `0.5rem` }}
          >
            <InputLabel>Select Role</InputLabel>
            <Select
              value={age}
              onChange={handleChange}
              label="Select Role"
              name="code"
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Staff">Staff</MenuItem>
              <MenuItem value="Manager">Manager</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            sx={{ marginTop: "0.5rem" }}
            options={dataProduct.filter((item) => {
              const usersworkplaces = usersworkplace.some((row) => {
                return row.name === item.name;
              });
              return !usersworkplaces;
            })}
            fullWidth
            multiple
            value={usersworkplace}
            filterSelectedOptions
            onChange={(event, value) => handleChangeUsers(value)}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(jsonResuls) => jsonResuls.name}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Chọn nơi làm việc"
                placeholder="Chọn một hoặc nhiều"
                variant="outlined"
              />
            )}
          />

          <MenuCheckBox
            data={dataProduct}
            name="Chọn sản phẩm"
            handleChangeMenu={handleChangeMenu}
          />
          {menu && (
            <>
              <Box sx={{ display: "flex", gap: 1, marginTop: "0.5rem" }}>
                <Typography variant="h6" sx={{ fontWeight: 500, flex: 1 }}>
                  Tên
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                  phần trăm lợi nhuận
                </Typography>
              </Box>
              {menu.map((item) => (
                <Box key={item} sx={{ display: "flex" }}>
                  <Typography variant="h6" sx={{ flex: 1 }}>
                    {item.name}
                  </Typography>
                  <TextField
                    sx={{ marginTop: "0.5rem" }}
                    label="Phần trăm"
                    name="price"
                    value={item.price}
                    variant="outlined"
                    onChange={(event) => handleChangeInput(item.id, event)}
                    size="small"
                  />
                </Box>
              ))}
            </>
          )}
          {input.usersProfit && (
            <>
              {input.usersProfit.map((item) => (
                <Box key={item} sx={{ display: "flex" }}>
                  <Typography
                    variant="h6"
                    sx={{ flex: 1, fontSize: "15px", marginTop: "0.5rem" }}
                  >
                    {item.status}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "15px", marginTop: "0.5rem" }}
                  >
                    {item.price} %
                  </Typography>
                  <DeleteUsers
                    id={item.id}
                    onToast={showToast}
                    onCheckedChange={getProduct}
                  />
                </Box>
              ))}
            </>
          )}
          <TextField
            sx={{ marginTop: `0.5rem` }}
            onChange={handleinput}
            type="text"
            name="password"
            label="Password"
            size="small"
            variant="outlined"
            fullWidth
          />

          <TextField
            sx={{ marginTop: `0.5rem` }}
            onChange={handleinput}
            type="text"
            value={input.telegramId}
            name="telegramId"
            label="Telegram ID"
            size="small"
            variant="outlined"
            fullWidth
          />
          <TextField
            sx={{ marginTop: `0.5rem` }}
            onChange={handleinput}
            type="number"
            value={input.fixedSalary}
            name="fixedSalary"
            label="Lương cố định"
            size="small"
            variant="outlined"
            fullWidth
          />
          <TextField
            sx={{ marginTop: `0.5rem` }}
            onChange={handleinput}
            type="number"
            value={input.amount}
            name="amount"
            label="Lương nhân viên"
            size="small"
            variant="outlined"
            fullWidth
          />

          {errorMessage && (
            <FilledAlerts message={errorMessage} success="error" />
          )}
          <Button
            type="submit"
            sx={{ margin: (theme) => theme.spacing(5, 0) }}
            variant="contained"
            size="large"
            fullWidth
            color="primary"
            onClick={onSubmit}
          >
            Update
          </Button>
        </div>
      )}
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </Box>
  );
};

export default EditUsers;
