import React from "react";
import styles from "./style.module.css";
import {
  Box,
  Card,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import NavLinkButton from "components/NavLinks/NavLinkButton";
import EditIcon from "@mui/icons-material/Edit";

import useLinks from "hooks/useLinks";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteProduct from "./DeleteProduct";
import CompletedProduct from "./CompletedProduct";
const TableProducts = (props) => {
  const { product, handleCurrentDelete } = props;
  const linkImage = useLinks();
  const showToast = ({ message, status }) => {
    handleCurrentDelete();
    if (status) {
      toast.success(message, {
        position: "top-right",
      });
    } else {
      toast.error(message, {
        position: "top-right",
      });
    }
  };
  return (
    <div>
      <Grid container spacing={3}>
        {product.map((item) => (
          <Grid item key={item.id} lg={3} md={6} sm={12} xs={12}>
            <Card>
              <CardMedia
                className={styles.img}
                component="img"
                height="194"
                image={`${linkImage + item.image}`}
                crossOrigin="anonymous"
              />
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  fontSize: (theme) => theme.typography.pxToRem(20),
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(2),
                }}
              >
                {item.name}
              </Typography>
              <Divider />
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: (theme) => theme.typography.pxToRem(16),
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(1),
                }}
              >
                {item.status}
              </Typography>
              <Divider />
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: (theme) => theme.typography.pxToRem(16),
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(1),
                }}
              >
                {item.description}
              </Typography>
              <Divider />
              <Box
                sx={{
                  padding: (theme) => theme.spacing(1),
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <NavLinkButton href={`/manager/sanpham/edit/${item.id}`}>
                  <IconButton color="primary">
                    <EditIcon />
                  </IconButton>
                </NavLinkButton>
                <DeleteProduct id={item.id} onToast={showToast} />
                <CompletedProduct item={item} onToast={showToast} />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default TableProducts;
