import { Button, Divider, TextField } from "@mui/material";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosusers from "utils/api/axiosusers";

const VouchersDetail = ({ total, orders }) => {
  const [code, setCode] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const onSubmit = async () => {
    setOpen(true);
    if (total < 150000) {
      setOpen(false);
      toast.error("Tổng tiền phải lớn hơn 150.000 đ", {
        position: "top-right",
      });
      return;
    }
    if (Number(orders.bonusAmount) !== 0) {
      setOpen(false);
      toast.error("Đơn hàng này đã ap dụng giảm giá", {
        position: "top-right",
      });
      return;
    }

    const dataSend = {
      orders,
      code,
    };
    try {
      const response = await axiosusers.post(
        `/vouchers/create-orders-vouchers`,
        dataSend
      );
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      setOpen(false);
      console.error(error);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  return (
    <div>
      <Divider style={{ backgroundColor: "#000", margin: "10px 0 0 4px" }} />
      <TextField
        label="Nhập mã"
        onChange={(e) => setCode(e.target.value)}
        sx={{ margin: "10px" }}
      />
      <br />
      <Button
        variant="contained"
        color="primary"
        sx={{ margin: "10px" }}
        onClick={open ? null : onSubmit}
        disabled={open}
      >
        Áp dụng
      </Button>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default VouchersDetail;
