import { InputAdornment, OutlinedInput } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
const SearchInput = (props) => {
  const { placeholder, onCheckedChange } = props;

  const SearchText = (e) => {
    const { value } = e.target;
    onCheckedChange(value);
  };
  return (
    <div>
      <OutlinedInput
        fullWidth
        onChange={SearchText}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        }
      ></OutlinedInput>
    </div>
  );
};

export default SearchInput;
