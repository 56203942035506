import { Box } from "@mui/material";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";
import { Tabtitle } from "components/title/Title";
import React from "react";
import AddTienLuong from "./AddTienLuong";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";

const index = () => {
  Tabtitle("Tạo tiền lương");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: usersData } = useSWR(`/users/users-all`, fetchDataSwr);
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <TypographyAddEdit name="Tạo tiền lương" />
      <Box sx={{ marginTop: "1.5rem" }}>
        <AddTienLuong usersData={usersData === undefined ? [] : usersData} />
      </Box>
    </Box>
  );
};

export default index;
