import { Tooltip } from "@mui/material";
import React from "react";

import axiosusers from "utils/api/axiosusers";
const CopyMaVouchers = (props) => {
  const { id, onToast, row } = props;

  const handleClose = ({ message, status }) => {
    onToast({ message, status });
  };

  const handleDelete = async (code) => {
    navigator.clipboard.writeText(`${code}`);
    const dataSend = { id };
    try {
      const response = await axiosusers.post(
        `/vouchers/copy-vouchers`,
        dataSend
      );
      if (response.status === 200) {
        handleClose({ message: response.data.message, status: true });
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.message || "An error occurred";
      handleClose({ message: errorMessage, status: false });
    }
  };

  return (
    <>
      <Tooltip title="Copy" placement="top" arrow>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleDelete(row.code)}
        >
          {row.code}
        </span>
      </Tooltip>
    </>
  );
};

export default CopyMaVouchers;
