import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import axiosusers from "utils/api/axiosusers";
import UndoIcon from "@mui/icons-material/Undo";
import DoneAllIcon from "@mui/icons-material/DoneAll";
const CompleteCheckinCheckout = ({ id, row, onCheckedChange, onToast }) => {
  const [open, setOpen] = React.useState(false);
  const idProductRef = React.useRef();
  const handleClickOpen = (id) => {
    idProductRef.current = id;
    setOpen(true);
  };
  const handleClose = ({ message, status }) => {
    onToast({ message, status });
  };
  const handleDelete = async () => {
    setOpen(false);
    const dataSend = { id: idProductRef.current, row };
    try {
      const response = await axiosusers.post(
        `/users/complete-usersstaff`,
        dataSend
      );
      if (response.status === 200) {
        handleClose({ message: response.data.message, status: true });
        onCheckedChange();
        setOpen(false);
      }
    } catch (error) {
      console.error(error);
      handleClose({ message: error.response.data.message, status: false });
    }
  };
  return (
    <>
      {row.status == "inProgress" && (
        <Tooltip title={"Làm xong"} placement="top" arrow>
          <IconButton color="primary" onClick={() => handleClickOpen(id)}>
            <DoneIcon />
          </IconButton>
        </Tooltip>
      )}
      {row.status == "completed" && (
        <Tooltip title={"Chốt"} placement="top" arrow>
          <IconButton color="primary" onClick={() => handleClickOpen(id)}>
            <DoneAllIcon />
          </IconButton>
        </Tooltip>
      )}
      {row.status == "done" && (
        <Tooltip title={"Trả lại"} placement="top" arrow>
          <IconButton color="primary" onClick={() => handleClickOpen(id)}>
            <UndoIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bạn có chắc chắn muốn thực hiện thao tác này không?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompleteCheckinCheckout;
