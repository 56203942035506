import { Button, MenuItem, Switch, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormControl, InputLabel, Select } from "@material-ui/core";
const EditTaoban = ({ product }) => {
  const navigate = useNavigate();
  const [input, setInput] = useState(product);
  const [errorMessage, seterrorMessage] = useState("");
  const handleinput = (e) => {
    const { name, checked, type } = e.target;
    setInput({
      ...input,
      [name]: type === "checkbox" ? checked : e.target.value,
    });
  };
  const onSubmit = async () => {
    if (input.orderNumber === "") {
      toast.error("Số bàn là bắt buộc", {
        position: "top-right",
      });
      return seterrorMessage("Số bàn là bắt buộc");
    }
    if (input.usedPrice === "") {
      toast.error("Giá tiền sử dụng là bắt buộc", {
        position: "top-right",
      });
      return seterrorMessage("Giá tiền sử dụng là bắt buộc");
    }
    const data = {
      id: product.id,
      orderNumber: input.orderNumber,
      selectProduct: input.selectProduct,
      status: input.status,
      usedPrice: input.usedPrice,
    };

    try {
      const response = await axiosusers.post(
        `/danhsachban/update-danhsachban`,
        data
      );
      console.log(response);

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/danhsachban/taoban/");
        }, 500);
      }
    } catch (error) {
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    }
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);
  return (
    <div>
      <FormControl style={{ marginTop: "0.5rem" }} fullWidth variant="outlined">
        <InputLabel>Chọn sản phẩm</InputLabel>
        <Select
          label="Chọn sản phẩm"
          value={input.selectProduct}
          name="selectProduct"
          onChange={handleinput}
        >
          <MenuItem value="Coffe & Fastfood">Coffe & Fastfood</MenuItem>
          <MenuItem value="Playstation">Playstation</MenuItem>
        </Select>
      </FormControl>
      <TextField
        sx={{ marginTop: `0.5rem` }}
        onChange={handleinput}
        type="text"
        value={input.orderNumber}
        name="orderNumber"
        error={Boolean(input.orderNumber === "")}
        helperText={input.orderNumber === "" ? "Số bàn là bắt buộc" : ""}
        label="Số bàn"
        variant="outlined"
        fullWidth
      />
      <TextField
        sx={{ marginTop: `0.5rem` }}
        onChange={handleinput}
        type="text"
        value={input.usedPrice}
        name="usedPrice"
        error={Boolean(input.usedPrice === "")}
        helperText={
          input.usedPrice === "" ? "Giá tiền sử dụng là bắt buộc" : ""
        }
        label="Giá tiền sử dụng"
        variant="outlined"
        fullWidth
      />
      <Switch
        checked={input.status}
        color="primary"
        onChange={handleinput}
        name="status"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      {errorMessage && <FilledAlerts message={errorMessage} success="error" />}
      <Button
        type="submit"
        sx={{ margin: (theme) => theme.spacing(5, 0) }}
        variant="contained"
        size="large"
        fullWidth
        color="primary"
        onClick={onSubmit}
      >
        Update
      </Button>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </div>
  );
};

export default EditTaoban;
