import { Box, CircularProgress } from "@mui/material";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";
import { Tabtitle } from "components/title/Title";
import React, { useEffect, useState } from "react";
import axiosusers from "utils/api/axiosusers";
import { useParams } from "react-router-dom";
import EditWarehouse from "./EditWarehouse";
import useSWR from "swr";

const MAX_RETRIES = 3;
const index = () => {
  Tabtitle("Cập nhật Thực Đơn");
  const { id } = useParams();
  const [retryCount, setRetryCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const fetchDataAll = async () => {
    Promise.all([axiosusers.get(`/thucdon/thucdon/${id}`)])
      .then(async (results) => {
        const [response] = results.map((res) => res.data);
        setProduct(response);
      })
      .finally(() => setLoading(true))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("401 Unauthorized. Retrying...");
          if (retryCount < MAX_RETRIES) {
            setRetryCount(retryCount + 1);
            fetchDataAll();
          } else {
            console.log("Max retries reached.");
          }
        } else {
          console.error(error);
        }
      });
  };

  useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchDataAll();
    }, 100);
    return () => clearTimeout(delayFetch);
  }, [id]);
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data: gameData } = useSWR(`/products/products-all`, fetchDataSwr);
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <TypographyAddEdit name="Cập nhật Thực Đơn" />
      {!loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      <Box sx={{ marginTop: "1.5rem" }}>
        {loading && (
          <EditWarehouse
            product={product}
            gameData={gameData === undefined ? [] : gameData}
          />
        )}
      </Box>
    </Box>
  );
};

export default index;
