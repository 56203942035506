import { Box, TextField, Typography } from "@mui/material";
import Images from "components/Avatar/Images";
import React, { useState } from "react";

const BudgetDelivered = ({ orders, onChangeInputFields, nameStatus }) => {
  const namestatus = nameStatus === "coffee" ? "Coffee" : "Fastfood";
  const budgetsorders = orders.budgetsorders
    .filter((item) => item.delivered == "" && item.status === namestatus)
    .map((item) => ({
      ...item,
      usedQuantity: item.quantity,
    }));

  const [inputFields, setInputFields] = useState(budgetsorders);
  const handleChangeInput = (id, event) => {
    const { value } = event.target;
    const newValue = value;

    const newInputFields = inputFields.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          usedQuantity: newValue,
          error: Number(newValue) > item.quantity || Number(newValue) < 0,
          helperText:
            Number(newValue) > item.quantity
              ? "Số lượng không được vượt quá số lượng hiện có"
              : Number(newValue) < 0
              ? "Số lượng không được nhỏ hơn 0"
              : "",
        };
      }
      return item;
    });
    onChangeInputFields(newInputFields);
    setInputFields(newInputFields);
  };

  return (
    <Box sx={{ marginTop: "1rem" }}>
      <Box
        component="form"
        autoComplete="off"
        sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0 }}
      >
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          Tên ngân sách
        </Typography>
        <Typography variant="subtitle1" sx={{ flex: 0.5, padding: "0px 10px" }}>
          Ảnh
        </Typography>
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          Số lượng Hiện có
        </Typography>
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          Nhập số lượng
        </Typography>
      </Box>
      {inputFields.map((budget) => (
        <Box key={budget.id}>
          <Box
            component="form"
            autoComplete="off"
            sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
          >
            <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
              {budget.budgets.name}
            </Typography>
            <Box sx={{ flex: 0.5 }}>
              <Images nameImage={budget.product.image} />
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {budget.quantity}
            </Typography>
            <TextField
              fullWidth
              type="number"
              name="usedQuantity"
              value={budget.usedQuantity}
              onChange={(event) => handleChangeInput(budget.id, event)}
              error={budget.error}
              helperText={budget.helperText}
              label="Nhập số lượng"
              variant="outlined"
              size="small"
              sx={{ flex: 1 }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default BudgetDelivered;
