import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const AutocompleteSearch = (props) => {
  const { data, label, size, onCheckedChange, optionLabel } = props;

  const setChange = (event, value) => {
    onCheckedChange(value);
  };
  return (
    <Autocomplete
      fullWidth
      size={size}
      id="combo-box-demo"
      name="id"
      onChange={setChange}
      options={data}
      getOptionLabel={optionLabel}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default AutocompleteSearch;
