import { Box } from "@mui/material";
import TypographyAddEdit from "components/Mui/Typographys/TypographyAddEdit";
import { Tabtitle } from "components/title/Title";
import React from "react";
import AddPayment from "./AddPayment";

const index = () => {
  Tabtitle("Tạo Thanh toán");
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <TypographyAddEdit name="Tạo thanh toán" />
      <Box sx={{ marginTop: "1.5rem" }}>
        <AddPayment />
      </Box>
    </Box>
  );
};

export default index;
