import { Box, IconButton, TextField } from "@mui/material";
import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
const BudgetsWallets = (props) => {
  const { data, onCheckedChange } = props;

  const [inputFields, setInputFields] = React.useState(
    data !== undefined
      ? data
      : [{ id: uuidv4(), name: "", quantity: "", price: "" }]
  );
  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    onCheckedChange(newInputFields);
    setInputFields(newInputFields);
  };

  // const handleAddFields = () => {
  //   const newInputFields = [
  //     ...inputFields,
  //     {
  //       id: uuidv4(),
  //       name: "",
  //       quantity: "",
  //       price: "",
  //     },
  //   ];
  //   setInputFields(newInputFields);
  //   onCheckedChange(newInputFields);
  // };

  const handleRemoveFields = (id) => {
    const newInputFields = inputFields.filter((i) => i.id !== id);
    setInputFields(newInputFields);
    onCheckedChange(newInputFields);
  };
  return (
    <div>
      {inputFields.map((inputField) => (
        <Box
          key={inputField.id}
          component="form"
          autoComplete="off"
          sx={{ display: "flex", gap: 1, marginTop: "0.5rem" }}
        >
          <TextField
            fullWidth
            label="Tên"
            name="name"
            value={inputField.name}
            variant="outlined"
            onChange={(event) => handleChangeInput(inputField.id, event)}
            size="small"
          />
          <TextField
            fullWidth
            label="Đơn vị tính"
            name="unit"
            value={inputField.unit}
            variant="outlined"
            onChange={(event) => handleChangeInput(inputField.id, event)}
            size="small"
          />
          <TextField
            fullWidth
            label="Số lượng"
            type="number"
            name="quantity"
            value={inputField.quantity}
            variant="outlined"
            onChange={(event) => handleChangeInput(inputField.id, event)}
            size="small"
          />
          <TextField
            fullWidth
            type="number"
            name="price"
            value={inputField.price}
            onChange={(event) => handleChangeInput(inputField.id, event)}
            label="Giá tiền"
            variant="outlined"
            size="small"
            // InputProps={{
            //   endAdornment: (
            //     <div>
            //       {inputFields.length === Number(id + 1) && (
            //         <Fab
            //           color="primary"
            //           aria-label="add"
            //           size="small"
            //           onClick={auth?.roles === "Admin" ? handleAddFields : null}
            //         >
            //           <AddIcon />
            //         </Fab>
            //       )}
            //     </div>
            //   ),
            // }}
          />

          {inputField.updatedAt && (
            <IconButton color="primary" disabled>
              <DeleteIcon />
            </IconButton>
          )}
          {inputFields.length !== 1 && !inputField.updatedAt && (
            <IconButton
              color="primary"
              onClick={() => handleRemoveFields(inputField.id)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
    </div>
  );
};

export default BudgetsWallets;
