import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosusers from "utils/api/axiosusers";
const NganSachKhoBudgets = ({ inputField }) => {
  const [inputFields, setInputFields] = React.useState(
    inputField.budgetsngansachkho
  );
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(0);
  const onSubmit = (e) => {
    setOpen(true);
    setId(e);
  };
  const handleDelete = async () => {
    setOpen(false);
    const dataSend = { id };
    try {
      const response = await axiosusers.post(
        `/budgets/delete-budgetsngansachkho`,
        dataSend
      );
      if (response.status === 200) {
        setOpen(false);
      }
      setInputFields(inputFields.filter((i) => i.id !== id));
    } catch (error) {
      setOpen(true);
    }
  };
  return (
    <Box>
      <Box
        component="form"
        autoComplete="off"
        sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
      >
        <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
          Tên thực đơn chính
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
          Đơn vị tính
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
          Số lượng
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
          Tính số lượng
        </Typography>
      </Box>
      {inputFields.map((row) => {
        return (
          <Box key={row.id} sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{ fontSize: "1rem", flex: 1 }}>
              {row.ngansachkho?.name}
            </Typography>
            <Typography sx={{ fontSize: "1rem", flex: 1 }}>
              {row.ngansachkho?.unit}
            </Typography>
            <Typography sx={{ fontSize: "1rem", flex: 1 }}>
              {row.ngansachkho?.quantity}
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "1rem" }}>
                {row.byQuantity}
              </Typography>

              <IconButton
                color="primary"
                sx={{ float: "right", marginTop: "-10px" }}
                onClick={() => onSubmit(row.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        );
      })}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this wallet?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NganSachKhoBudgets;
